<template>
  <div id="documents">
    <div v-if="post.logo" class="logoEntreprise">
      <img :src="'/api/logoClient?v='+post.id" alt=""/>
    </div>
    <div v-if="isDocuments">
      <div id='recherche' class='document'>
        <b-row>
          <b-col class="my-1">
            <h4>Filtrer par documents</h4>
            <b-form-group>
              <b-input-group>
                <b-form-select v-model="selected" :options="typeDoc" @change="changeType()"></b-form-select>
                <b-form-input v-model="filter" placeholder="Recherche" />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">Effacer</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>         
      </div>

      <!--Contrats-->
      <div id="contrat" class="typeDocument">
        <div v-if="contracts.length">
          <h3 style="color:#3C4694;">Contrats</h3>
          <b-table class="text-center" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredContracts" responsive :striped="true" :fields="fieldsContrats" :items="contracts" :current-page="currentPageContrats" :per-page="perPage">
            <template v-slot:cell(date-signature)="data">
              <span style='font-size:14px;'>{{ data.item.date_creation }}</span>
            </template>
            <!--<template v-slot:cell(date-service)="data">
              <span style='width:30%; font-size:14px; text-transform: uppercase;'>{{ data.item.name }}</span>
            </template>
            <template v-slot:cell(date-fin-contrat)="data">
              <span style='font-size:14px; text-transform: uppercase;'>{{ data.item.address }}</span>
            </template>-->
            <template v-slot:cell(type)="data">
              <span style='font-size:14px; text-transform: uppercase;'>{{ data.item.ref_customer }}</span>
            </template>
            <template v-slot:cell(prix-ht)="data">
              <span style='font-size:14px; text-transform: uppercase;'>{{ data.item.total_ht }}</span>
            </template>
            <template v-slot:cell(prix-ttc)="data">
              <span style='font-size:14px; text-transform: uppercase;'>{{ data.item.total_ttc }}</span>
            </template>
            <template v-slot:cell(document)="data">
              <span style='font-size:14px; text-transform: uppercase;'>{{ data.item.ref+'.pdf' }}</span>
            </template>
           <template v-slot:cell(download)="data">
              <a v-bind:href="'/api/downloadDoc/contracts/'+data.item.ref+'/'+data.item.ref+'.pdf'" download><span style="cursor:pointer;" class='fas fa-cloud-download-alt' v-b-tooltip.hover title='Télécharger'></span></a>
            </template>
            <template v-slot:cell(visual)="data">
              <a v-on:click.stop="visualDoc(data.item)"><span style="cursor:pointer;" class='far fa-eye' v-b-tooltip.hover title='Visualiser'></span></a>
            </template>
            <!--<template v-slot:cell(comment)="data">
              <a v-on:click.stop="commentDoc(data.item.rowid)"><span style="cursor:pointer;" class='fas fa-comment-alt' v-b-tooltip.hover title='Commentaire'></span>
            </template>-->
          </b-table>
          <div v-if="totalRows.contracts>perPage">
            <b-pagination size="md" :total-rows="totalRows.contracts" v-model="currentPageContrats" :per-page="perPage" />
          </div>
        </div>
        <div v-else>
          <h3 style="color:#3C4694;">Contrats</h3>
          <h5>Aucuns contrats disponibles</h5>
        </div>
      </div>

      <!--Devis-->
      <div id="devis" class="typeDocument">
        <div v-if="proposals.length">
          <h3 style="color:#6D3D94;">Devis</h3>
          <b-table class="text-center" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredProposals" responsive :striped="true" :fields="fieldsDevis" :items="proposals" :current-page="currentPageDevis" :per-page="perPage">
            <template v-slot:cell(date-creation)="data">
              <span style='font-size:14px;'>{{ data.item.date_creation }}</span>
            </template>
            <template v-slot:cell(reference)="data">
              <span style='width:30%; font-size:14px; text-transform: uppercase;'>{{ data.item.ref_client }}</span>
            </template>
            <template v-slot:cell(prix-ht)="data">
              <span style='font-size:14px; text-transform: uppercase;'>{{ data.item.total_ht }}</span>
            </template>
            <template v-slot:cell(prix-ttc)="data">
              <span style='font-size:14px; text-transform: uppercase;'>{{ data.item.total_ttc }}</span>
            </template>
            <template v-slot:cell(etat)="data">
              <span style='font-size:14px; text-transform: uppercase;'>{{ data.item.statut_libelle }}</span>
            </template>
            <template v-slot:cell(document)="data">
              <span style='font-size:14px; text-transform: uppercase;'>{{ data.item.ref+'.pdf' }}</span>
            </template>
            <template v-slot:cell(download)="data">
              <a v-bind:href="'/api/downloadDoc/'+data.item.last_main_doc" download><span style="cursor:pointer;" class='fas fa-cloud-download-alt' v-b-tooltip.hover title='Télécharger'></span></a>
            </template>
            <template v-slot:cell(visual)="data">
              <a v-on:click.stop="visualDoc(data.item)"><span style="cursor:pointer;" class='far fa-eye' v-b-tooltip.hover title='Visualiser'></span></a>
            </template>
            <!--<template v-slot:cell(comment)="data">
              <a v-on:click.stop="commentDoc(data.item.last_main_doc)"><span style="cursor:pointer;" class='fas fa-comment-alt' v-b-tooltip.hover title='Commentaire'></span>
            </template>-->
          </b-table>
          <div v-if="totalRows.proposals>perPage">
            <b-pagination size="md" :total-rows="totalRows.proposals" v-model="currentPageDevis" :per-page="perPage" />
          </div>
        </div>
        <div v-else>
          <h3 style="color:#6D3D94;">Devis</h3>
          <h5>Aucuns devis disponibles</h5>
        </div>
      </div>

      <!--Commandes-->
      <div id="commande" class="typeDocument">
        <div v-if="orders.length">
          <h3 style="color:#ED2C74;">Commandes</h3>
          <b-table class="text-center" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredOrders" responsive :striped="true" :fields="fieldsCommandes" :items="orders" :current-page="currentPageCommandes" :per-page="perPage">
            <template v-slot:cell(date-creation)="data">
              <span style='font-size:14px;'>{{ data.item.date_commande }}</span>
            </template>
            <template v-slot:cell(date-livraison)="data">
              <span v-if="data.item.date_livraison" style='font-size:14px;'>{{ data.item.date_livraison }}</span>
              <span v-else style='font-size:14px;'>Non livré</span>
            </template>
            <template v-slot:cell(reference)="data">
              <span style='width:30%; font-size:14px; text-transform: uppercase;'>{{ data.item.ref_client }}</span>
            </template>
            <template v-slot:cell(prix-ht)="data">
              <span style='font-size:14px; text-transform: uppercase;'>{{ data.item.total_ht }}</span>
            </template>
            <template v-slot:cell(prix-ttc)="data">
              <span style='font-size:14px; text-transform: uppercase;'>{{ data.item.total_ttc }}</span>
            </template>
            <template v-slot:cell(document)="data">
              <span style='font-size:14px; text-transform: uppercase;'>{{ data.item.ref+'.pdf' }}</span>
            </template>
            <template v-slot:cell(download)="data">
              <a v-bind:href="'/api/downloadDoc/'+data.item.last_main_doc" download><span style="cursor:pointer;" class='fas fa-cloud-download-alt' v-b-tooltip.hover title='Télécharger'></span></a>
            </template>
            <template v-slot:cell(visual)="data">
              <a v-on:click.stop="visualDoc(data.item)"><span style="cursor:pointer;" class='far fa-eye' v-b-tooltip.hover title='Visualiser'></span></a>
            </template>
            <!--<template v-slot:cell(comment)="data">
              <a v-on:click.stop="commentDoc(data.item.rowid)"><span style="cursor:pointer;" class='fas fa-comment-alt' v-b-tooltip.hover title='Commentaire'></span>
            </template>-->
          </b-table>
          <div v-if="totalRows.orders>perPage">
            <b-pagination size="md" :total-rows="totalRows.orders" v-model="currentPageCommandes" :per-page="perPage" />
          </div>
        </div>
        <div v-else>
          <h3 style="color:#ED2C74;">Commandes</h3>
          <h5>Aucunes commandes disponibles</h5>
        </div>
      </div>

      <!--Factures-->
      <div id="facture" class="typeDocument">
        <div v-if="invoices.length">
          <h3 style="color:#00B7A7;">Factures</h3>
          <b-table class="text-center" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredInvoices" responsive :striped="true" :fields="fieldsFactures" :items="invoices" :current-page="currentPageFactures" :per-page="perPage">
            <template v-slot:cell(statut)="data">
              <span v-if="data.item.paye == 1" v-b-tooltip.hover title='Facture réglée' style="color:green;" class='fas fa-circle'></span>
              <span v-else-if="data.item.date_lim_sup == 0" class='fas fa-circle' style="color:red;" v-b-tooltip.hover title='Facture non réglée'></span>
              <span v-else class='fas fa-circle' style="color:orange;" v-b-tooltip.hover title='Facture non réglée échéance non dépassée'></span>
            </template>
            <template v-slot:cell(date-creation)="data">
              <span style='font-size:14px;'>{{ data.item.date_creation }}</span>
            </template>
            <template v-slot:cell(date-reglement)="data">
              <span style='font-size:14px;'>{{ data.item.date_lim_reglement }}</span>
            </template>
            <template v-slot:cell(reference)="data">
              <span style='width:30%; font-size:14px; text-transform: uppercase;'>{{ data.item.ref_client }}</span>
            </template>
            <template v-slot:cell(prix-ht)="data">
              <span style='font-size:14px; text-transform: uppercase;'>{{ data.item.total_ht }}</span>
            </template>
            <template v-slot:cell(prix-ttc)="data">
              <span style='font-size:14px; text-transform: uppercase;'>{{ data.item.total_ttc }}</span>
            </template>
            <template v-slot:cell(document)="data">
              <span style='font-size:14px; text-transform: uppercase;'>{{ data.item.ref+'.pdf' }}</span>
            </template>
            <template v-slot:cell(download)="data">
              <a v-bind:href="'/api/downloadDoc/'+data.item.last_main_doc" download><span style="cursor:pointer;" class='fas fa-cloud-download-alt' v-b-tooltip.hover title='Télécharger'></span></a>
            </template>
            <template v-slot:cell(visual)="data">
              <a v-on:click.stop="visualDoc(data.item)"><span style="cursor:pointer;" class='far fa-eye' v-b-tooltip.hover title='Visualiser'></span></a>
            </template>
            <!--<template v-slot:cell(comment)="data">
              <a v-on:click.stop="commentDoc(data.item.rowid)"><span style="cursor:pointer;" class='fas fa-comment-alt' v-b-tooltip.hover title='Commentaire'></span>
            </template>-->
          </b-table>

          <div v-if="totalRows.invoices>perPage">
            <b-pagination size="md" :total-rows="totalRows.invoices" v-model="currentPageFactures" :per-page="perPage" />
          </div>
        </div>
        <div v-else>
          <h3 style="color:#00B7A7;">Factures</h3>
          <h5>Aucunes factures disponibles</h5>
        </div>
      </div>

      <!--Bon de livraison-->
      <!--
      <div id="bonLivraison" class="typeDocument">
        <h3>Bon de livraison</h3>
        <b-table class="text-center" :filter="filter" responsive :striped="true" :fields="fieldsBL" :items="bonDeLivraison" :current-page="currentPage" :per-page="perPage" @row-clicked="expandClientInfo">
          <template v-slot:cell(Index)="data">
            <span style='font-size:14px;'>{{ data.item.id }}</span>
          </template>
          <template v-slot:cell(nom-entreprise)="data">
            <span style='width:30%; font-size:14px; text-transform: uppercase;'>{{ data.item.name }}</span>
          </template>
          <template v-slot:cell(Adresse)="data">
            <span style='font-size:14px; text-transform: uppercase;'>{{ data.item.address }}</span>
          </template>
          <template v-slot:cell(Code postal)="data">
            <span style='font-size:14px; text-transform: uppercase;'>{{ data.item.zip }}</span>
          </template>
          <template v-slot:cell(Ville)="data">
            <span style='font-size:14px; text-transform: uppercase;'>{{ data.item.town }}</span>
          </template>
          <template v-slot:cell(Acces)="data">
            <span v-if="data.item.acces" v-b-tooltip.hover title='Accès fournis' class='far fa-check-circle'></span>
            <span v-else class='far fa-circle' v-b-tooltip.hover title='Accès non fournis'></span>
          </template>
          <template v-slot:cell(Logo)="data">
            <span v-if="data.item.acces" class='far fa-image' v-b-tooltip.hover title='Logo disponible' style='color:green;'></span>
            <span v-else class='far fa-image' v-b-tooltip.hover title='Logo indisponible' style='color:red;'></span>
          </template>
        </b-table>
      </div>
      -->
    </div>
    <div v-else>
      <h2 style="text-align:center;">Vous n'avez pas de documents disponibles</h2>
    </div>
    
    <b-modal id="modalVisual" @hide="resetModal" :title="modalVisual.title" hide-footer>
      <template>
        <iframe id="visualDoc" v-bind:src="visualSrc"></iframe>
      </template>
    </b-modal>

  </div>
</template>

<style>
#documents{
  margin: auto;
  width: 95%;
}

#contrat > h3, #contrat .table thead th, #contrat .table td,#contrat .table th{
  border-color: #3C4694;
}

#devis > h3, #devis .table thead th, #devis .table td,#devis .table th{
  border-color: #6D3D94;
}

#commande > h3, #commande .table thead th, #commande .table td,#commande .table th{
  border-color: #ED2C74;
}

#facture > h3, #facture .table thead th, #facture .table td,#facture .table th{
  border-color: #00B7A7;
}

#documents .input-group{
  width: 60%;
}

#documents .input-group>.form-control{
  flex: 1 1 50%;
}

#visualDoc{
  width: 100%;
  height: 100%;
}

#modalVisual > .modal-dialog {
  max-width: 50%;
  height: 95%;
  margin-top: 1%;
}

#modalVisual > .modal-dialog > div{
  height: 100%;
}

</style>

<script>
import script from '../../script/script.js';

export default {
  data() {
    return {
      post: {},
      selected: null,
      typeDoc : [
        {value: null, text: 'Type de document'},
        {value: 'contrat', text: 'Contrat'},
        {value: 'devis', text: 'Devis'},
        {value: 'commande', text: 'Commandes'},
        {value: 'facture', text: 'Factures'}
      ],
      fieldsContrats:[{key:"date-signature", label:"Date de signature"},/*{key:"date-service", label:"Date de mise en service"},{key:"date-fin-contrat", label:"Date de fin de contrat"},*/{key:"type", label:"Type de contrat"},{key:"prix-ht", label:"Total(ht*)"},{key:"prix-ttc", label:"Total(ttc*)"},{key:"document", label:"Document"},{key:"download", label:""},{key:"visual", label:""}/*,{key:"comment", label:""}*/],
      fieldsDevis:[{key:"date-creation", label:"Date de création"},{key:"reference", label:"Référence"},{key:"prix-ht", label:"Total(ht*)"},{key:"prix-ttc", label:"Total(ttc*)"},{key:"etat", label:"Etat"},{key:"document", label:"Document"},{key:"download", label:""},{key:"visual", label:""}/*,{key:"comment", label:""}*/],
      fieldsCommandes:[{key:"date-creation", label:"Date de création"},{key:"date-livraison", label:"Date de livraison"},{key:"reference", label:"Référence"},{key:"prix-ht", label:"Total(ht*)"},{key:"prix-ttc", label:"Total(ttc*)"},{key:"document", label:"Document"},{key:"download", label:""},{key:"visual", label:""}/*,{key:"comment", label:""}*/],
      fieldsFactures:[{key:"statut", label:"Statut"},{key:"date-creation", label:"Date de création"},{key:"date-reglement", label:"Date limite de règlement"},{key:"reference", label:"Référence"},{key:"prix-ht", label:"Total(ht*)"},{key:"prix-ttc", label:"Total(ttc*)"},{key:"document", label:"Document"},{key:"download", label:""},{key:"visual", label:""}/*,{key:"comment", label:""}*/],
      currentPageContrats: 1,
      currentPageDevis: 1,
      currentPageCommandes: 1,
      currentPageFactures: 1,
      perPage: 10,
      filter:null,
      sortBy: null,
      sortDesc: false,
      sortDirection: 'asc',
      contracts:[],
      orders:[],
      proposals:[],
      invoices:[],
      totalRows:{
        contracts:0,
        orders:0,
        proposals:0,
        invoices:0,
      },
      isDocuments: false,
      modalVisual: { title: '', content: '' },
      visualSrc:''
    }
  },
  mounted:function(){
    this.verifyRight();
    this.loadingInfo();
    this.loadDocuments("contracts");
    this.loadDocuments("orders");
    this.loadDocuments("proposals");
    this.loadDocuments("invoices");
  },
  methods: {
    verifyRight: script.verifyRight,
    changeType(){
      var typeDocument = document.getElementsByClassName("typeDocument");
      if(this.selected!=null){
        for(var i=0; i<typeDocument.length;i++){
          typeDocument[i].style.display="none";
        }
        var docAffiche = document.getElementById(this.selected);
        docAffiche.style.display="block";
      }else{
        for(i=0; i<typeDocument.length;i++){
          typeDocument[i].style.display="block";
        }
      }
    },
    loadDocuments: script.loadDocuments,
    visualDoc: script.visualDoc,
    commentDoc: script.commentDoc,
    onFilteredContracts(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows.contracts = filteredItems.length
      this.currentPage = 1
    },
    onFilteredProposals(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows.proposals = filteredItems.length
      this.currentPage = 1
    },
    onFilteredOrders(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows.orders = filteredItems.length
      this.currentPage = 1
    },
    onFilteredInvoices(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows.invoices = filteredItems.length
      this.currentPage = 1
    },
    loadingInfo: script.loadingInfo
  },
  components: {

  }
}
</script>
