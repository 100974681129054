<template>
	<div>
		<div>
			<h5>Date Création: {{dateCreation}}</h5>
			<h5>Date Mise à Jour: {{dateMaj}}</h5>
			<h5>Activité :</h5>
				<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" editField='activite' :dataInit="infosGenerales.activite">{{ infosGenerales.activite }}</div>
			<br>
			<div class="groupeEffectif">
				<div class="effectif">
					<h5>Effectif ETP :</h5>
						<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" editField='effectifETP' :dataInit="infosGenerales.effectifETP">{{ infosGenerales.effectifETP }}</div>
				</div>
				<div class="effectif">
					<h5>Hommes :</h5>
						<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" editField='effectifHomme' :dataInit="infosGenerales.effectifHomme">{{ infosGenerales.effectifHomme }}</div>
				</div>
				<div class="effectif">
					<h5>Femmes :</h5>
						<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" editField='effectifFemme' :dataInit="infosGenerales.effectifFemme">{{ infosGenerales.effectifFemme }}</div>
				</div>
			</div>
			<br>
			<h5>CDD / Intérimaires :</h5>
				<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" editField='cddIntérimaires' :dataInit="infosGenerales.cddIntérimaires">{{ infosGenerales.cddIntérimaires }}</div>
			<br>
			<div class="groupeHoraire">
				<div class="horaire">
					<h5>Horaires de travail :</h5>
						<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" editField='horrairesDeTravail' :dataInit="infosGenerales.horrairesDeTravail">{{ infosGenerales.horrairesDeTravail }}</div>
				</div>
				<div class="horaire">
					<h5>Temps de travail :</h5>
						<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" editField='tempsDeTravail' :dataInit="infosGenerales.tempsDeTravail">{{ infosGenerales.tempsDeTravail }}</div>
				</div>
			</div>
			<br>
			<h5>Convention collective :</h5>
				<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" editField='conventionCollective' :dataInit="infosGenerales.conventionCollective">{{ infosGenerales.conventionCollective }}</div>
		</div>
		<br>
		<div>
			<h5>Instances représentatives du personnel :</h5>
			<h5>CSE :</h5>
				<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" editField='cse' :dataInit="infosGenerales.cse">{{ infosGenerales.cse }}</div>
			<h5>CSSCT :</h5>
				<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" editField='cssct' :dataInit="infosGenerales.cssct">{{ infosGenerales.cssct }}</div>
			<br>
			<h5>Protections individuelles disponibles :</h5>
				<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" editField='protectionIndividuel' :dataInit="infosGenerales.protectionIndividuel">{{ infosGenerales.protectionIndividuel }}</div>
			<br>
			<h5>Caractéristiques des locaux de travail (ateliers, locaux sociaux) :</h5>
				<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" editField='caractLocaux' :dataInit="infosGenerales.caractLocaux">{{ infosGenerales.caractLocaux }}</div>
			<br>
			<h5>Hygiène générale :</h5>
				<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" editField='hygiene' :dataInit="infosGenerales.hygiene">{{ infosGenerales.hygiene }}</div>
			<br>
			<h5>Documents consultés :</h5>
				<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" editField='docConsulte' :dataInit="infosGenerales.docConsulte">{{ infosGenerales.docConsulte }}</div>
			<br>
			<h5>Accidents du travail : </h5>
				<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" editField='accidentDeTravail' :dataInit="infosGenerales.accidentDeTravail">{{ infosGenerales.accidentDeTravail }}</div>
			<br>
			<h5>Maladies professionnelles :</h5>
				<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" editField='maladiePro' :dataInit="infosGenerales.maladiePro">{{ infosGenerales.maladiePro }}</div>
		</div>
	</div>
</template>

<script>
import scriptQse from '../../../script/script-qse.js';
var moment = require('moment');
moment.locale('fr')
export default {
	data() {
		return {

		}
	},
	computed:{
		infosGenerales(){
			return this.$store.state.infosGenerales
		},
		dateCreation(){
			if(this.$store.state.dateCreation){
				return moment(parseInt(this.$store.state.dateCreation,10)).format('L')
			} else {
				return ''
			}
		},
		dateMaj(){
			if(this.$store.state.dateMaj){
				return moment(parseInt(this.$store.state.dateMaj,10)).format('L')
			} else {
				return ''
			}
			
		}
	},
	methods:{
		updateExtrafieldsQse: scriptQse.updateExtrafieldsQse,
		onEdit (evt) {
			var initValue = evt.target.getAttribute('dataInit')
			var newValue = evt.target.innerText
			var editField = evt.target.getAttribute('editField')
			if(editField == 'effectifETP' || editField == 'effectifFemme' || editField == 'effectifHomme'){
					let tempValue =  parseFloat((newValue+"").replace(",","."))
					if (!isNaN(tempValue)){
						newValue = tempValue
					}
			}

			if (newValue !== initValue) {
				this.$store.commit("updateInfosGenerales", {field: editField, value: newValue})
				evt.target.setAttribute('dataInit', newValue)
				this.updateExtrafieldsQse(this.$route.params.id)
			}
		},

		endEdit (evt) {
			evt.target.blur(evt)
		},
	}
}
</script>

<style>
.groupeEffectif, .groupeHoraire{
	display: flex;
}

.effectif, .horaire{
	flex: 1;
}
</style>