<template>
	<div>
		<el-button @click.native.prevent="reloadDefault()" type="primary" size="small" round> Charger la configuration par défaut</el-button>
		<div class="centerCotation">
			<h3>Méthode de cotation des risques professionnels</h3>
			<h4>Cotation = Ps * G * D</h4>
		</div>
		<br>
		<div class="centerCotation">
			<h5>PS : Probabilité de survenance</h5>
			<b-table ref="tablePS" small responsive striped :items="cotation.ps" :fields="fieldsCotation"  show-empty>
                <template slot="empty" slot-scope="">
                  	<div style="text-align:center;">Ce tiers ne possède aucune cotation</div>
                </template>
                <template v-slot:cell(note)="data">
                  	<div contenteditable="true" class="editme" @blur="onEditNote" @keydown.enter="endEditNote" editTable="ps" :editId="data.index" editType="TIERS" editField='note' :dataInit="data.item.note">{{ data.item.note }}</div>
                </template>
                <template v-slot:cell(desc)="data">
                	<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" editTable="ps" :editId="data.index" editType="TIERS" editField='desc' :dataInit="data.item.desc">{{ data.item.desc }}</div>
                </template>
				<template v-slot:cell(actions)="data">
                  	<i class="far fa-trash-alt" @click="deleteRow(data.index, cotation.ps)" style="color:red; cursor:pointer;"></i>
                </template>
			</b-table>
			<el-button @click.native.prevent="addRow(cotation.ps)" type="text" size="small"> Ajouter une ligne</el-button>
		</div>
		<br>
		<div class="centerCotation">
			<h5>G : Gravité</h5>
			<b-table ref="tableG" small responsive striped :items="cotation.g" :fields="fieldsCotation"  show-empty>
                <template slot="empty" slot-scope="">
                  	<div style="text-align:center;">Ce tiers ne possède aucune cotation</div>
                </template>
                <template v-slot:cell(note)="data">
                  	<div contenteditable="true" class="editme" @blur="onEditNote" @keydown.enter="endEditNote" editTable="g" :editId="data.index" editType="TIERS" editField='note' :dataInit="data.item.note">{{ data.item.note }}</div>
                </template>
                <template v-slot:cell(desc)="data">
                  	<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" editTable="g" :editId="data.index" editType="TIERS" editField='desc' :dataInit="data.item.desc">{{ data.item.desc }}</div>
                </template>
				<template v-slot:cell(actions)="data">
					<i class="far fa-trash-alt" @click="deleteRow(data.index, cotation.g)" style="color:red; cursor:pointer;"></i>
                </template>
			</b-table>
			<el-button @click.native.prevent="addRow(cotation.g)" type="text" size="small"> Ajouter une ligne</el-button>
		</div>
		<br>
		<div class="centerCotation">
			<h5>D : Détection</h5>
			<b-table ref="tableD" small responsive striped :items="cotation.d" :fields="fieldsCotation"  show-empty>
                <template slot="empty" slot-scope="">
                  	<div style="text-align:center;">Ce tiers ne possède aucune cotation</div>
                </template>
                <template v-slot:cell(note)="data">
                  	<div contenteditable="true" class="editme" @blur="onEditNote" @keydown.enter="endEditNote" editTable="d" :editId="data.index" editType="TIERS" editField='note' :dataInit="data.item.note">{{ data.item.note }}</div>
                </template>
                <template v-slot:cell(desc)="data">
                  	<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" editTable="d" :editId="data.index" editType="TIERS" editField='desc' :dataInit="data.item.desc">{{ data.item.desc }}</div>
                </template>
				<template v-slot:cell(actions)="data">
                  	<i class="far fa-trash-alt" @click="deleteRow(data.index, cotation.d)" style="color:red; cursor:pointer;"></i>
                </template>
			</b-table>
			<el-button @click.native.prevent="addRow(cotation.d)" type="text" size="small"> Ajouter une ligne</el-button>
		</div>
		<br>
		<div class="centerCotation">
			<h4>Classification du risque Brut</h4>
			<b-table ref="tableBrut" small responsive striped :items="cotation.brut" :fields="fieldsCotationRisque"  show-empty>
                <template slot="empty" slot-scope="">
                  	<div style="text-align:center;">Ce tiers ne possède aucune cotation</div>
                </template>
                <template v-slot:cell(note)="data">
                  	<div contenteditable="true" class="editme" @blur="onEditNoteClass" @keydown.enter="endEditNoteClass" editTable="brut" :editId="data.index" editType="TIERS" editField='note' :dataInit="data.item.note">{{ data.item.note }}</div>
                </template>
                <template v-slot:cell(desc)="data">
                  	<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" editTable="brut" :editId="data.index" editType="TIERS" editField='desc' :dataInit="data.item.desc">{{ data.item.desc }}</div>
                </template>
				<template v-slot:cell(cotationMin)="data">
                  	<div contenteditable="true" class="editme" @blur="onEditNote" @keydown.enter="endEditNote" editTable="brut" :editId="data.index" editType="TIERS" editField='cotationMin' :dataInit="data.item.cotationMin">{{ data.item.cotationMin }}</div>
                </template>
				<template v-slot:cell(cotationMax)="data">
                  	<div contenteditable="true" class="editme" @blur="onEditNote" @keydown.enter="endEditNote" editTable="brut" :editId="data.index" editType="TIERS" editField='cotationMax' :dataInit="data.item.cotationMax">{{ data.item.cotationMax }}</div>
                </template>
				<template v-slot:cell(actions)="data">
                  	<i class="far fa-trash-alt" @click="deleteRow(data.index, cotation.brut)" style="color:red; cursor:pointer;"></i>
                </template>
			</b-table>
			<el-button @click.native.prevent="addRowClassification(cotation.brut)" type="text" size="small"> Ajouter une ligne</el-button>
		</div>
		<br>
		<div class="centerCotation">
			<h4>Classification du risque Résiduel</h4>
			<b-table ref="tableResiduel" small responsive striped :items="cotation.residuel" :fields="fieldsCotationRisque"  show-empty>
                <template slot="empty" slot-scope="">
                  	<div style="text-align:center;">Ce tiers ne possède aucune cotation</div>
                </template>
                <template v-slot:cell(note)="data">
                  	<div contenteditable="true" class="editme" @blur="onEditNoteClass" @keydown.enter="endEditNoteClass" editTable="residuel" :editId="data.index" editType="TIERS" editField='note' :dataInit="data.item.note">{{ data.item.note }}</div>
                </template>
                <template v-slot:cell(desc)="data">
                  	<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" editTable="residuel" :editId="data.index" editType="TIERS" editField='desc' :dataInit="data.item.desc">{{ data.item.desc }}</div>
                </template>
				<template v-slot:cell(cotationMin)="data">
                  	<div contenteditable="true" class="editme" @blur="onEditNote" @keydown.enter="endEditNote" editTable="residuel" :editId="data.index" editType="TIERS" editField='cotationMin' :dataInit="data.item.cotationMin">{{ data.item.cotationMin }}</div>
                </template>
				<template v-slot:cell(cotationMax)="data">
                  	<div contenteditable="true" class="editme" @blur="onEditNote" @keydown.enter="endEditNote" editTable="residuel" :editId="data.index" editType="TIERS" editField='cotationMax' :dataInit="data.item.cotationMax">{{ data.item.cotationMax }}</div>
                </template>
				<template v-slot:cell(actions)="data">
                  	<i class="far fa-trash-alt" @click="deleteRow(data.index, cotation.residuel)" style="color:red; cursor:pointer;"></i>
                </template>
			</b-table>
			<el-button @click.native.prevent="addRowClassification(cotation.residuel)" type="text" size="small"> Ajouter une ligne</el-button>
		</div>
		<br>
	</div>
</template>

<script>
import scriptQse from '../../../script/script-qse.js';
export default {
	data () {
		return {
			fieldsCotation: [
				{ key: 'note', label: 'Note' },
				{ key: 'desc', label: 'Description' },
				{ key: 'actions', label: '' }
			],
			fieldsCotationRisque: [
				{ key: 'note', label: 'Note' },
				{ key: 'desc', label: 'Description' },
				{ key: 'cotationMin', label: 'Cotation Min' },
				{ key: 'cotationMax', label: 'Cotation Max' },
				{ key: 'actions', label: '' }
			],
		}
	},
	methods: {
		updateExtrafieldsQse: scriptQse.updateExtrafieldsQse,
		getExtrafieldsDefault: scriptQse.getExtrafieldsDefault,
		remplacerVirgulePoint(decimal) {
			return parseFloat((decimal+"").replace(",","."))
		},
		reloadDefault(){
			if(confirm("Voulez-vous recharger la cotation par défaut ?")){
				this.getExtrafieldsDefault()
				this.$store.commit('updateCotation', Object.assign({}, this.$store.state.cotationDefault))
				this.updateExtrafieldsQse(this.$store.state.infoSoc.id)
			}
		},
		deleteRow(index, rows) {
			if(confirm("Voulez-vous supprimer cette cotation ?")){
				rows.splice(index, 1);
				this.$store.commit("updateCotation", this.cotation)
				this.updateExtrafieldsQse(this.$store.state.infoSoc.id)
			}
		},
		addRow(rows) {
			rows.push({
				'note':'Saisir une note', 
				'desc':'Saisir une description'
			})
			this.$store.commit("updateCotation", this.cotation)
			this.updateExtrafieldsQse(this.$store.state.infoSoc.id)
		},
		addRowClassification(rows) {
			rows.push({
				'note':'Saisir une note', 
				'desc':'Saisir une description',
				'cotationMin' : 'Saisir une cotation min',
				'cotationMax' : 'Saisir une cotation max'
			})
			this.$store.commit("updateCotation", this.cotation)
			this.updateExtrafieldsQse(this.$store.state.infoSoc.id)
		},
		onEdit (evt) {
			var initValue = evt.target.getAttribute('dataInit')
			var newValue = evt.target.innerText
			var editId = evt.target.getAttribute('editId')
			var editField = evt.target.getAttribute('editField')
			var editTable = evt.target.getAttribute('editTable')

			if (newValue !== initValue) {
				this.cotation[editTable][editId][editField] = newValue 
				this.$store.commit("updateCotation", this.cotation)
				evt.target.setAttribute('dataInit', newValue)
				this.updateExtrafieldsQse(this.$store.state.infoSoc.id)
			}
		},

		endEdit (evt) {
			evt.target.blur(evt)	
		},
		onEditNote (evt) {
			var initValue = evt.target.getAttribute('dataInit')
			var newValue = this.remplacerVirgulePoint(evt.target.innerText)
			var editId = evt.target.getAttribute('editId')
			var editField = evt.target.getAttribute('editField')
			var editTable = evt.target.getAttribute('editTable')

			if (newValue !== initValue) {
				this.cotation[editTable][editId][editField] = newValue 
				this.$store.commit("updateCotation", this.cotation)
				evt.target.setAttribute('dataInit', newValue)
				this.updateExtrafieldsQse(this.$store.state.infoSoc.id)
			}
		},

		endEditNote (evt) {
			evt.target.blur(evt)	
		},

		onEditNoteClass (evt) {
			console.log(evt.target)
			var newValue = evt.target.innerText
			var editId = evt.target.getAttribute('editId')
			var editField = evt.target.getAttribute('editField')
			var editTable = evt.target.getAttribute('editTable')

			this.cotation[editTable][editId][editField] = newValue 
			this.$store.commit("updateCotation", this.cotation)
			this.updateExtrafieldsQse(this.$store.state.infoSoc.id)
		},

		endEditNoteClass (evt) {
			evt.target.blur(evt)	
		}
	},
	computed: {
		cotation() {
			return this.$store.state.cotation
		}
	},
	mounted () {
	}
}
</script>

<style>
.centerCotation{
	text-align: center;
}
</style>