<template>
<div>
	<!-- Barre de menu -->
	<b-navbar toggleable="lg" type="dark" style="background-color:#24AAE1 !important;" variant="dark">
		<b-navbar-brand :to="'/indexClient'" style="padding-top: 5px;"></b-navbar-brand>
		<!-- <b-collapse is-nav id="nav_collapse"> -->
		<b-navbar-nav>
			<b-nav-item style="white-space:nowrap;">{{ time }}</b-nav-item>
			<b-nav-item-dropdown right>
				<template slot="button-content"><span class="fas fa-user"></span> {{ info.prenom+" "+info.nom }}</template>
				<b-dropdown-item :to="'/profilClient'"><span class="far fa-address-card"></span> Mon profil</b-dropdown-item>
				<b-dropdown-item v-on:click="disconnect()"><span class="fas fa-power-off"></span> Déconnexion</b-dropdown-item>
			</b-nav-item-dropdown>
		</b-navbar-nav>
	</b-navbar>
		<!--<b-navbar-toggle target="verticalMenu"><img src="/img/LOGO IDEAL SOLUTIONS-blanc_carre_25px.png" alt="Ideal Solution" data-toggle='tooltipIdeal' title='Cliquez-ici pour ouvrir le menu de navigation' @click="toggleNav()"/></b-navbar-toggle>-->
	
</div>
</template>

<script>
import script from '../../script/script.js';

var moment = require('moment');
export default {
	
  data() {
    return {
		time: moment().format('LLLL'),
		intervalTime:null
    }
	},
	beforeCreate:function(){
		moment.locale('fr');
	},
	mounted:function(){
			this.intervalTime = setInterval(() => this.time = moment().format('LLLL'), 500)
	},
	beforeDestroy:function(){
		clearInterval(this.intervalTime);
	},
  methods: {
    	disconnect: script.disconnect,
	},
	created:function(){

	},
	computed:{
		info(){
			return this.$store.state.info
		}
	}
}
</script>

<style>
</style>
