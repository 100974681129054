<template>
<div style="margin:15px;">
  <div @click="$router.go(-1)" style="cursor:pointer; float:right;"><i class="fas fa-arrow-circle-left fa-2x" style="color:deepskyblue;"></i></div>
  <b-container fluid class="bv-example-row">
    <b-row>
      <b-col><blocInfostiers :infosTiers="infosTiers" /></b-col>
    </b-row>
    <b-row>
      <b-col><blocTabs :infosTiers="infosTiers" /></b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import blocInfostiers from '../modules/BlocInfosTiers'
import blocTabs from '../modules/BlocTabs'
import script from '../../script/script.js'

export default {
  components: {
    blocInfostiers,
    blocTabs
  },

  mounted: function () {
	this.verifyRight();
	this.detailsTiers(this.$route.params.id)
  },

  methods: {
	  detailsTiers: script.detailsTiers,
    	verifyRight: script.verifyRight
  },
  data () {
		return {
		}
  },
  computed:{
    infosTiers(){
      return this.$store.state.infoSoc
    }
  }
}
</script>
<style>
.txtRight {
  text-align:right;
}
</style>
