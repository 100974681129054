<template>
	<div>
		<div>
			<el-button @click.native.prevent="reloadDefault()" type="primary" size="small" round> Charger la configuration par défaut</el-button>
		</div>
		<div class="checklistGenerale">
			<h5>Affichages obligatoires et consignes de sécurité</h5>
			<b-table ref="checklistGeneraleAffichageObligatoire" small responsive striped :items="checklistGenerale.affichageObligatoire" :fields="fieldsChecklistGeneraleAffichageObligatoire" show-empty>
				<template slot="empty" slot-scope="">
					<div style="text-align:center;">Ce tiers ne possède aucun affichage obligatoire et consignes de sécurité</div>
				</template>
				<template v-slot:cell(label)="data">
					<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.index" editTable="affichageObligatoire" editField='label' :dataInit="data.item.label">{{ data.item.label }}</div>
				</template>
				<template v-slot:cell(presence)="data">
					<el-checkbox v-model="data.item.presence" @change="changePresence()"></el-checkbox>
				</template>
				<template v-slot:cell(commentaire)="data">
					<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.index" editTable="affichageObligatoire" editField='commentaire' :dataInit="data.item.commentaire">{{ data.item.commentaire }}</div>
				</template>
				<template v-slot:cell(actions)="data">
					<i class="far fa-trash-alt" @click="deleteRow(data.index, checklistGenerale.affichageObligatoire)" style="color:red; cursor:pointer; padding: 0 0.5em;"></i>
					<i class="fas fa-plus" @click="addProgAction(progAction)" style="color:green; cursor:pointer;"></i>
				</template>
			</b-table>
			<el-button @click.native.prevent="addRow(checklistGenerale.affichageObligatoire)" type="text" size="small"> Ajouter une ligne</el-button>
			<br/>

			<h5>Dispositions applicables à certains travaux, équipements, établissements ou produits particuliers </h5>
			<b-table ref="checklistGeneraleDispositionApplicable" small responsive striped :items="checklistGenerale.dispositionApplicable" :fields="fieldsChecklistGeneraleDispositionApplicable" show-empty>
				<template slot="empty" slot-scope="">
					<div style="text-align:center;">Ce tiers ne possède aucune disposition applicable à certains travaux</div>
				</template>
				<template v-slot:cell(label)="data">
					<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.index" editTable="dispositionApplicable" editField='label' :dataInit="data.item.label">{{ data.item.label }}</div>
				</template>
				<template v-slot:cell(presence)="data">
					<el-checkbox v-model="data.item.presence" @change="changePresence()"></el-checkbox>
				</template>
				<template v-slot:cell(commentaire)="data">
					<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.index" editTable="dispositionApplicable" editField='commentaire' :dataInit="data.item.commentaire">{{ data.item.commentaire }}</div>
				</template>
				<template v-slot:cell(actions)="data">
					<i class="far fa-trash-alt" @click="deleteRow(data.index, checklistGenerale.dispositionApplicable)" style="color:red; cursor:pointer; padding: 0 0.5em;"></i>
					<i class="fas fa-plus" @click="addProgAction(progAction)" style="color:green; cursor:pointer;"></i>
				</template>
			</b-table>
			<el-button @click.native.prevent="addRow(checklistGenerale.dispositionApplicable)" type="text" size="small"> Ajouter une ligne</el-button>
			<br/>

			<h5>Locaux de l'entreprise</h5>
			<b-table ref="checklistGeneraleLocauxEntreprise" small responsive striped :items="checklistGenerale.locauxEntreprise" :fields="fieldsChecklistGeneraleLocauxEntreprise" show-empty>
				<template slot="empty" slot-scope="">
					<div style="text-align:center;">Ce tiers ne possède aucun locaux d'entreprise</div>
				</template>
				<template v-slot:cell(label)="data">
					<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.index" editTable="locauxEntreprise" editField='label' :dataInit="data.item.label">{{ data.item.label }}</div>
				</template>
				<template v-slot:cell(presence)="data">
					<el-checkbox v-model="data.item.presence" @change="changePresence()"></el-checkbox>
				</template>
				<template v-slot:cell(commentaire)="data">
					<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.index" editTable="locauxEntreprise" editField='commentaire' :dataInit="data.item.commentaire">{{ data.item.commentaire }}</div>
				</template>
				<template v-slot:cell(actions)="data">
					<i class="far fa-trash-alt" @click="deleteRow(data.index, checklistGenerale.locauxEntreprise)" style="color:red; cursor:pointer; padding: 0 0.5em;"></i>
					<i class="fas fa-plus" @click="addProgAction(progAction)" style="color:green; cursor:pointer;"></i>
				</template>
			</b-table>
			<el-button @click.native.prevent="addRow(checklistGenerale.locauxEntreprise)" type="text" size="small"> Ajouter une ligne</el-button>
			<br/>

			<h5>Organisation de la gestion de la sécurité</h5>
			<b-table ref="checklistGeneraleOrganisationGestionSecurite" small responsive striped :items="checklistGenerale.organisationGestionSecurite" :fields="fieldsChecklistGeneraleOrganisationSecurite" show-empty>
				<template slot="empty" slot-scope="">
					<div style="text-align:center;">Ce tiers ne possède aucune organisation de gestion de la sécurité</div>
				</template>
				<template v-slot:cell(label)="data">
					<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.index" editTable="organisationGestionSecurite" editField='label' :dataInit="data.item.label">{{ data.item.label }}</div>
				</template>
				<template v-slot:cell(presence)="data">
					<el-checkbox v-model="data.item.presence" @change="changePresence()"></el-checkbox>
				</template>
				<template v-slot:cell(commentaire)="data">
					<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.index" editTable="organisationGestionSecurite" editField='commentaire' :dataInit="data.item.commentaire">{{ data.item.commentaire }}</div>
				</template>
				<template v-slot:cell(actions)="data">
					<i class="far fa-trash-alt" @click="deleteRow(data.index, checklistGenerale.organisationGestionSecurite)" style="color:red; cursor:pointer; padding: 0 0.5em;"></i>
					<i class="fas fa-plus" @click="addProgAction(progAction)" style="color:green; cursor:pointer;"></i>
				</template>
			</b-table>
			<el-button @click.native.prevent="addRow(checklistGenerale.organisationGestionSecurite)" type="text" size="small"> Ajouter une ligne</el-button>
			<br/>

			<h5>Organisation des secours</h5>
			<b-table ref="checklistGeneraleOrganisationSecours" small responsive striped :items="checklistGenerale.organisationSecours" :fields="fieldsChecklistGeneraleOrganisationSecours" show-empty>
				<template slot="empty" slot-scope="">
					<div style="text-align:center;">Ce tiers ne possède aucune organisation des secours</div>
				</template>
				<template v-slot:cell(label)="data">
					<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.index" editTable="organisationSecours" editField='label' :dataInit="data.item.label">{{ data.item.label }}</div>
				</template>
				<template v-slot:cell(presence)="data">
					<el-checkbox v-model="data.item.presence" @change="changePresence()"></el-checkbox>
				</template>
				<template v-slot:cell(commentaire)="data">
					<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.index" editTable="organisationSecours" editField='commentaire' :dataInit="data.item.commentaire">{{ data.item.commentaire }}</div>
				</template>
				<template v-slot:cell(actions)="data">
					<i class="far fa-trash-alt" @click="deleteRow(data.index, checklistGenerale.organisationSecours)" style="color:red; cursor:pointer; padding: 0 0.5em;"></i>
					<i class="fas fa-plus" @click="addProgAction(progAction)" style="color:green; cursor:pointer;"></i>
				</template>
			</b-table>
			<el-button @click.native.prevent="addRow(checklistGenerale.organisationSecours)" type="text" size="small"> Ajouter une ligne</el-button>
			<br/>

			<h5>Accident de travail et maladies professionnelles</h5>
			<b-table ref="checklistGeneraleOrganisationAccidentTravail" small responsive striped :items="checklistGenerale.accidentTravail" :fields="fieldsChecklistGeneraleAccidentTravail" show-empty>
				<template slot="empty" slot-scope="">
					<div style="text-align:center;">Ce tiers ne possède aucun accident de travail ou maladies professionnelles</div>
				</template>
				<template v-slot:cell(label)="data">
					<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.index" editTable="accidentTravail" editField='label' :dataInit="data.item.label">{{ data.item.label }}</div>
				</template>
				<template v-slot:cell(presence)="data">
					<el-checkbox v-model="data.item.presence" @change="changePresence()"></el-checkbox>
				</template>
				<template v-slot:cell(commentaire)="data">
					<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.index" editTable="accidentTravail" editField='commentaire' :dataInit="data.item.commentaire">{{ data.item.commentaire }}</div>
				</template>
				<template v-slot:cell(actions)="data">
					<i class="far fa-trash-alt" @click="deleteRow(data.index, checklistGenerale.accidentTravail)" style="color:red; cursor:pointer; padding: 0 0.5em;"></i>
					<i class="fas fa-plus" @click="addProgAction(progAction)" style="color:green; cursor:pointer;"></i>
				</template>
			</b-table>
			<el-button @click.native.prevent="addRow(checklistGenerale.accidentTravail)" type="text" size="small"> Ajouter une ligne</el-button>
		</div>
	</div>
</template>

<script>
import moment from "moment"
import scriptQse from '../../../script/script-qse.js';
export default {
	data(){
		return {
			fieldsChecklistGeneraleAffichageObligatoire:[
				{key:"label", label:"Titre"},
				{key:"presence", label:"Présence"},
				{key:"commentaire", label:"Commentaire"},
				{key:"actions", label:""},
			],
			fieldsChecklistGeneraleDispositionApplicable:[
				{key:"label", label:"Titre"},
				{key:"presence", label:"Présence"},
				{key:"commentaire", label:"Commentaire"},
				{key:"actions", label:""},
			],
			fieldsChecklistGeneraleLocauxEntreprise:[
				{key:"label", label:"Titre"},
				{key:"presence", label:"Présence"},
				{key:"commentaire", label:"Commentaire"},
				{key:"actions", label:""},
			],
			fieldsChecklistGeneraleOrganisationSecurite:[
				{key:"label", label:"Titre"},
				{key:"presence", label:"Présence"},
				{key:"commentaire", label:"Commentaire"},
				{key:"actions", label:""},
			],
			fieldsChecklistGeneraleOrganisationSecours:[
				{key:"label", label:"Titre"},
				{key:"presence", label:"Présence"},
				{key:"commentaire", label:"Commentaire"},
				{key:"actions", label:""},
			],
			fieldsChecklistGeneraleAccidentTravail:[
				{key:"label", label:"Titre"},
				{key:"presence", label:"Présence"},
				{key:"commentaire", label:"Commentaire"},
				{key:"actions", label:""},
			]
		}
	},
	computed:{
		checklistGenerale(){
			return this.$store.state.checklistGenerale
		},
		progAction(){
			return this.$store.state.progAction
		},
		autoIncrementProgAction(){
			return this.$store.state.autoIncrementProgAction
		}
	},
	methods:{
		updateExtrafieldsQse: scriptQse.updateExtrafieldsQse,
		getExtrafieldsDefault: scriptQse.getExtrafieldsDefault,
		reloadDefault(){
			if(confirm("Voulez-vous recharger la checklist par défaut ?")){
				this.getExtrafieldsDefault()
				this.$store.commit('updateChecklistGenerale', Object.assign({}, this.$store.state.checklistGeneraleDefault))
				this.updateExtrafieldsQse(this.$store.state.infoSoc.id)
			}
		},
		deleteRow(index, rows) {
			if(confirm("Voulez-vous supprimer cette entrée ?")){
				rows.splice(index, 1);
				this.$store.commit("updateChecklistGenerale", this.checklistGenerale)
				this.updateExtrafieldsQse(this.$store.state.infoSoc.id)
			}
		},
		addRow(rows) {
			rows.push({
				'id': Date.now(),
				'label':'Saisir un titre', 
				'presence': false,
				'commentaire':'Saisir un commentaire'
			})
			this.$store.commit("updateChecklistGenerale", this.checklistGenerale)
			this.updateExtrafieldsQse(this.$store.state.infoSoc.id)
		},
		onEdit (evt) {
			var initValue = evt.target.getAttribute('dataInit')
			var newValue = evt.target.innerText
			var editId = evt.target.getAttribute('editId')
			var editField = evt.target.getAttribute('editField')
			var editTable = evt.target.getAttribute('editTable')

			if (newValue !== initValue) {
				this.checklistGenerale[editTable][editId][editField] = newValue 
				this.$store.commit("updateChecklistGenerale", this.checklistGenerale)
				evt.target.setAttribute('dataInit', newValue)
				this.updateExtrafieldsQse(this.$store.state.infoSoc.id)
			}
		},
		endEdit (evt) {
			evt.target.blur(evt)	
		},
		changePresence () {
			this.updateExtrafieldsQse(this.$store.state.infoSoc.id)
		},
		addProgAction(rows) {
			let dateNow = moment().format("YYYY-MM-DD")
			rows.push({
				'numProgAction':this.autoIncrementProgAction,
				'numAction':'Saisir un numéro d\'action', 
				'risque':'', 
				'unite':[], 
				'situation':'Saisir une situation', 
				'typeAction':'Saisir un type d\'action', 
				'effet':'Saisir l\'effet', 
				'pilote':'Saisir un pilote', 
				'delai': dateNow, 
				'avancement':'Saisir un avancement', 
				'budget':'Saisir un budget', 
				'commentaire':'Saisir un commentaire'
			})
			this.$store.commit("updateAutoIncrementProgAction", parseInt(this.autoIncrementProgAction)+1)
			this.$store.commit("updateProgAction", this.progAction)
			this.updateExtrafieldsQse(this.$store.state.infoSoc.id)
		}
	}
}
</script>

<style>
	.checklistGenerale{
		text-align: center;
	}
</style>