<template>
  <div>
    <div style="text-align:center;">
      <img src="https://as2piq.ideal-dev.fr/img/logo-as2piq.png" alt="AS2PIQ" style="width:50%;"/>
    </div>
  </div>
</template>

<script>
import script from '../../script/script.js';

export default {
  data() {
    return {
      
    }
  },
  mounted:function(){
    this.verifyRight();
  },
  computed:{
  },
  methods: {
    verifyRight: script.verifyRight
  }
  
}
</script>

<style>

</style>
