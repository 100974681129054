import Vuex from 'vuex'

import Vue from 'vue'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		render: 0,
		info: [],
		infoSoc:[],
////////////////////////////////////////////////////QSE/////////////////////////////////////////////////////////////////////////////////////////////////////////////////          
		cotation:{
			brut: [],
			residuel: [],
			ps: [],
			g: [],
			d: []
		},
		cotationDefault:{
			brut: [],
			residuel: [],
			ps: [],
			g: [],
			d: []
		},
		unites:[],
		famillesRisques:{
			familles:[],
			risques:[]
		},
		famillesRisquesDefault:{
			familles:[],
			risques:[]
		},
		donnees:{
			unites: []
		},
		autoIncrementUnite:0,
		autoIncrementFamille:0,
		autoIncrementRisque:0,
		autoIncrementFamilleDefault:0,
		autoIncrementRisqueDefault:0,
		autoIncrementSituation:0,
		autoIncrementSituationDefault:0,
		autoIncrementPenibilite:0,
		autoIncrementPenibiliteDefault:0,
		autoIncrementProgAction:0,
		checklistGeneraleDefault:{
			affichageObligatoire:[],
			dispositionApplicable:[],
			locauxEntreprise:[],
			organisationGestionSecurite:[],
			organisationSecours:[],
			accidentTravail:[]
		},
		progAction:[],
		annexeCovid:{
			travailRisque:{
				salarie:{
					bref:{
						unite:"0",
						salarie:"0"
					},
					rapproche:{
						unite:"0",
						salarie:"0"
					}
				},
				public:{
					bref:{
						unite:"0",
						salarie:"0"
					},
					rapproche:{
						unite:"0",
						salarie:"0"
					}
				}
			},
			preventionOrga:{
				mesuresGenerales:"-",
				modification:"-"
			},
			preventionTechnique:{
				protectionCollective:"-",
				protectionIndividuelle:"-",
				modification:"-"
			},
			casContamination:{
				procedure:"-"
			},
			informationInterne:{
				modalite:"-"
			},
			identificationPersonne:{
				presencePersonneRisque:[],
				autrePersonneRisque:[]
			}
		},
		identificationPersonneDefault:{
			presencePersonneRisque:[],
			autrePersonneRisque:[]
		},
		checklistGenerale:{
			affichageObligatoire:[],
			dispositionApplicable:[],
			locauxEntreprise:[],
			organisationGestionSecurite:[],
			organisationSecours:[],
			accidentTravail:[]
		},
		infosGenerales:{
			'activite':'Saisir l\'activité de l\'entreprise',
			'effectifETP':'Non défini',
			'effectifHomme':'Non défini',
			'effectifFemme':'Non défini',
			'cddIntérimaires':'Non défini',
			'horrairesDeTravail':'Non défini',
			'tempsDeTravail':'Non défini',
			'conventionCollective':'Non défini',
			'cse':'Non défini',
			'cssct':'Non défini',
			'protectionIndividuel':'Non défini',
			'caractLocaux':'Non défini',
			'hygiene':'Non défini',
			'docConsulte':'Non défini',
			'accidentDeTravail':'Non défini',
			'maladiePro':'Non défini'
		},
		dateMaj:null,
		dateCreation:null,
		situations:[],
		situationsDefault:[],
	},
	mutations: {
		rendering (state) {
			state.render += 1
		},
		updateInfoSoc(state, info) {
			state.infoSoc = info
		},
		updateInfo(state, info) {
			state.info = info
		},
/////////////////////////////////////////////////////////// QSE ////////////////////////////////////////////////////////////////////////////////////////////////////////
		addUnite (state) {
			var newUnite = {
				'id': state.autoIncrementUnite,
				'nomUnite':'Saisir le nom de l\'unité',
				'pilote': 'Saisir les pilotes', 
				'redacteur': 'Saisir le rédacteur', 
				'descriptionActivite': 'Saisir la description de l\'activité', 
				'descriptionLocaux': 'Saisir la description des locaux', 
				'nbPersonne': 'Saisir le nombre de personnes'
			}

			state.unites.push(newUnite)
			state.donnees.unites.push({
				'id':state.autoIncrementUnite,
				'familles':[],
				'risques':[]
			})
			state.autoIncrementUnite++
		},
		addRisque (state, idFamille) {
			var risque = {
				'id': state.autoIncrementRisque,
				'idFamille': idFamille,
				'nomRisque':'Saisir le risque'
			}
			state.famillesRisques.risques.push(risque)
			state.autoIncrementRisque++
		},
		addFamille (state) {
			var famille = {
				'id': state.autoIncrementFamille,
				'nomFamille':'Saisir la famille de risque'
			}
			state.famillesRisques.familles.push(famille)
			state.autoIncrementFamille++
		},
		addSituation (state) {
			var situation = {
				'id': state.autoIncrementSituation,
				'nomSituation':'Saisir la situation',
				penibilite:[]
			}
			state.situations.push(situation)
			state.autoIncrementSituation++
		},
		addSituationDefault (state) {
			var situation = {
				'id': state.autoIncrementSituationDefault,
				'nomSituation':'Saisir la situation',
				penibilite:[]
			}
			state.situationsDefault.push(situation)
			state.autoIncrementSituationDefault++
		},
		addPenibilite (state, idSituation) {
			var penibilite = {
				'id': state.autoIncrementPenibilite,
				'nomPenibilite':'Saisir la pénibilité',
				'intensite':'Intensité de la pénibilité',
				'duree':'Saisir la durée minimale de la pénibilité',
				'presence':null,
				'justification':'Saisir la justification de la pénibilite',
				'nbSalarie':0,
				'pourcentEffectif':0,
			}
			let situation = state.situations.filter(situation => situation.id == idSituation)
			situation.forEach(element => {
				element.penibilite.push(penibilite)	
			})
			state.autoIncrementPenibilite++
		},
		addPenibiliteDefault (state, idSituation) {
			var penibilite = {
				'id': state.autoIncrementPenibiliteDefault,
				'nomPenibilite':'Saisir la pénibilité',
				'intensite':'Intensité de la pénibilité',
				'duree':'Saisir la durée minimale de la pénibilité',
				'presence':null,
				'justification':'Saisir la justification de la pénibilite',
				'nbSalarie':0,
				'pourcentEffectif':0,
			}
			let situation = state.situationsDefault.filter(situation => situation.id == idSituation)
			situation.forEach(element => {
				element.penibilite.push(penibilite)	
			})
			state.autoIncrementPenibiliteDefault++
		},
		addRisqueDefault (state, idFamille) {
			var risque = {
				'id': state.autoIncrementRisqueDefault,
				'idFamille': idFamille,
				'nomRisque':'Saisir le risque',
			}
			state.famillesRisquesDefault.risques.push(risque)
			state.autoIncrementRisqueDefault++
		},
		addFamilleDefault (state) {
			var famille = {
				'id': state.autoIncrementFamilleDefault,
				'nomFamille':'Saisir la famille de risque',
			}
			state.famillesRisquesDefault.familles.push(famille)
			state.autoIncrementFamilleDefault++
		},
		
		deleteRisqueDefault (state, idRisque) {
			state.famillesRisquesDefault.risques = state.famillesRisquesDefault.risques.filter(risque => risque.id != idRisque)
		},
		deleteFamilleDefault (state, idFamille) {
			state.famillesRisquesDefault.familles = state.famillesRisquesDefault.familles.filter(famille => famille.id != idFamille)
			state.famillesRisquesDefault.risques = state.famillesRisquesDefault.risques.filter(risque => risque.idFamille != idFamille)
		},
		deleteRisque (state, idRisque) {
			state.famillesRisques.risques = state.famillesRisques.risques.filter(risque => risque.id != idRisque)
		},
		deleteSituation (state, idSituation) {
			state.situations = state.situations.filter(situation => situation.id != idSituation)
		},
		deleteSituationDefault (state, idSituation) {
			state.situationsDefault = state.situationsDefault.filter(situation => situation.id != idSituation)
		},
		deleteFamille (state, idFamille) {
			state.famillesRisques.familles = state.famillesRisques.familles.filter(famille => famille.id != idFamille)
			state.famillesRisques.risques = state.famillesRisques.risques.filter(risque => risque.idFamille != idFamille)
		},
		deleteUnite (state, idUnite) {
			state.unites = state.unites.filter(unite => unite.id != idUnite)
			state.donnees.unites = state.donnees.unites.filter(unite => unite.id != idUnite)
		},
		deletePenibilite(state, data){
			let situation = state.situations.filter(situation => situation.id == data.idSituation)
			situation.forEach(element => {
				element.penibilite = element.penibilite.filter(penibilite => penibilite.id != data.idPenibilite) 	
			})
		},
		deletePenibiliteDefault(state, data){
			let situation = state.situationsDefault.filter(situation => situation.id == data.idSituation)
			situation.forEach(element => {
				element.penibilite = element.penibilite.filter(penibilite => penibilite.id != data.idPenibilite) 	
			})
		},
		
		updateFamilles (state, data) {
			state.famillesRisques.familles[data.id][data.field] = data.value
		},
		updateRisques (state, data) {
			var risquesFamille = state.famillesRisques.risques.filter(risque => risque.idFamille == data.idFamille)
			var risques = risquesFamille.filter(risqueFamille => risqueFamille.id == data.id)
			risques.forEach(risque => {
				risque[data.field] = data.value
			})
		},
		updateFamillesDefault (state, data) {
			state.famillesRisquesDefault.familles[data.id][data.field] = data.value
		},
		updateRisquesDefault (state, data) {
			var risquesFamille = state.famillesRisquesDefault.risques.filter(risque => risque.idFamille == data.idFamille)
			var risques = risquesFamille.filter(risqueFamille => risqueFamille.id == data.id)
			risques.forEach(risque => {
				risque[data.field] = data.value
			})
		},
		updateDonnees (state, data) {
			state.donnees[data.table].forEach(element => {
				if (element.id == data.id) {
					element[data.field] = data.value
				}	
			})
		},
		updateUnites (state, data) {
			state.unites.forEach(unite => {
				if(unite.id == data.id){
					unite[data.field] = data.value
				}
			})
		},
		updateSituation (state, data) {
			state.situations.forEach(situation => {
				if(situation.id == data.id){
					situation[data.field] = data.value
				}
			})
		},
		updateSituationDefault (state, data) {
			state.situationsDefault.forEach(situation => {
				if(situation.id == data.id){
					situation[data.field] = data.value
				}
			})
		},
		updatePenibilite (state, data) {
			let situation = state.situations.filter(situation => situation.id == data.idSituation)
			situation.forEach(element => {
				element.penibilite.forEach(penibilite => {
					if(penibilite.id == data.idPenibilite){
						penibilite[data.field] = data.value
						if(data.field == 'nbSalarie' && data.value!=""){
							if(isNaN(state.infosGenerales.effectifETP)){
								penibilite.pourcentEffectif = 'effectif ETP non défini'
							}else if(isNaN(penibilite.nbSalarie)){
								penibilite.pourcentEffectif = 'Nombre de salarié non défini'
							} else {
								penibilite.pourcentEffectif = (parseFloat((data.value+"").replace(",",".")) / parseFloat((state.infosGenerales.effectifETP+"").replace(",","."))) * 100
								penibilite.pourcentEffectif = penibilite.pourcentEffectif.toFixed(2)
							}
						} else {
							penibilite.pourcentEffectif = 0
						}
					}
				})
			})
		},
		updatePenibiliteDefault (state, data) {
			let situation = state.situationsDefault.filter(situation => situation.id == data.idSituation)
			situation.forEach(element => {
				element.penibilite.forEach(penibilite => {
					if(penibilite.id == data.idPenibilite){
						penibilite[data.field] = data.value
						if(data.field == 'nbSalarie' && data.value!=""){
							if(isNaN(state.infosGenerales.effectifETP)){
								penibilite.pourcentEffectif = 'effectif ETP non défini'
							}else if(isNaN(penibilite.nbSalarie)){
								penibilite.pourcentEffectif = 'Nombre de salarié non défini'
							} else {
								penibilite.pourcentEffectif = (parseFloat((data.value+"").replace(",",".")) / parseFloat((state.infosGenerales.effectifETP+"").replace(",","."))) * 100
								penibilite.pourcentEffectif = penibilite.pourcentEffectif.toFixed(2)
							}
						} else {
							penibilite.pourcentEffectif = 0
						}
					}
				})
			})
		},
		updateAutoIncrementUnite (state, data) {
			state.autoIncrementUnite = data
		},
		updateAutoIncrementSituation (state, data) {
			state.autoIncrementSituation = data
		},
		updateAutoIncrementPenibilite (state, data) {
			state.autoIncrementPenibilite = data
		},
		updateAutoIncrementSituationDefault (state, data) {
			state.autoIncrementSituationDefault = data
		},
		updateAutoIncrementPenibiliteDefault (state, data) {
			state.autoIncrementPenibiliteDefault = data
		},
		updateAutoIncrementFamille (state, data) {
			state.autoIncrementFamille = data
		},
		updateAutoIncrementRisque (state, data) {
			state.autoIncrementRisque = data
		},
		updateAutoIncrementFamilleDefault (state, data) {
			state.autoIncrementFamilleDefault = data
		},
		updateAutoIncrementRisqueDefault (state, data) {
			state.autoIncrementRisqueDefault = data
		},
		updateAutoIncrementChecklist (state, data) {
			state.autoIncrementChecklist = data
		},
		updateAutoIncrementProgAction (state, data) {
			state.autoIncrementProgAction = data
		},
		updateCotation (state, cotation) {
			state.cotation = cotation
		},
		updateCotationDefault (state, cotationDefault) {
			state.cotationDefault = cotationDefault
		},
		updateInfosGenerales (state, data) {
			state.infosGenerales[data.field] = data.value
		},
		updateDateMaj (state, data) {
			state.dateMaj = data
		},
		updateDateCreation (state, data) {
			state.dateCreation = data
		},
		updateProgAction (state, data) {
			state.progAction = data
		},
		updateChecklistGeneraleDefault(state, data){
			state.checklistGeneraleDefault = data
		},
		updateChecklistGenerale(state, data){
			state.checklistGenerale = data
		},
		updateAnnexeCovid (state, data) {
			state.annexeCovid = data
		},
		updateIdentificationPersonneRisqueDefault (state, data) {
			state.identificationPersonneDefault = data
		},

		initializeFamillesRisques (state, data) {
			state.famillesRisques = data
		},
		initializeFamillesRisquesDefault (state, data) {
			state.famillesRisquesDefault = data
		},
		initializeUnites (state, data) {
			state.unites = data
		},
		initializeDonnees (state, data) {
			state.donnees = data
		},
		initializeInfosGenerales (state, data) {
			state.infosGenerales = data
		},
		initializeSituations (state, data) {
			state.situations = data
		},
		initializeSituationsDefault (state, data) {
			state.situationsDefault = data
		},
		initializeProgAction (state, data) {
			state.progAction = data
		}
	}
})