<template>
  <div>
    <b-form id="infoSoc">
      <div v-if="post.logo">
        <div style="width:50%;float:left;">
          <b-form-group id="name" label="Nom :" label-for="inputName">
            <b-form-input id="inputCivility" v-model="infoSocClient.name" :disabled="true"></b-form-input>
          </b-form-group>
          <b-form-group id="phoneSoc" label="Téléphone :" label-for="inputPhoneSoc">
            <b-form-input id="inputPhoneSoc" v-model="infoSocClient.phone" :disabled="true"></b-form-input>
          </b-form-group>
        </div>
        <div style="width:50%;float:right;text-align:center;">
          <img style="width:50%;" :src="'/api/logoClient?v='+post.id" alt=""/>
        </div>
      </div>
      <div v-else>
        <div style="width:50%;">
          <b-form-group id="name" label="Nom :" label-for="inputName">
            <b-form-input id="inputCivility" v-model="infoSocClient.name" :disabled="true"></b-form-input>
          </b-form-group>
          <b-form-group id="phoneSoc" label="Téléphone :" label-for="inputPhoneSoc">
            <b-form-input id="inputPhoneSoc" v-model="infoSocClient.phone" :disabled="true"></b-form-input>
          </b-form-group>
        </div>
      </div>
      <b-form-group id="addressSoc" label="Adresse :" label-for="inputAddressSoc">
        <b-form-input id="inputAddressSoc" v-model="infoSocClient.address" :disabled="true"></b-form-input>
      </b-form-group>
      <b-form-group id="zipSoc" label="Code postal :" label-for="inputZipSoc">
        <b-form-input id="inputZipSoc" v-model="infoSocClient.zip" :disabled="true"></b-form-input>
      </b-form-group>
      <b-form-group id="townSoc" label="Ville :" label-for="inputTownSoc">
        <b-form-input id="inputTownSoc" v-model="infoSocClient.town" :disabled="true"></b-form-input>
      </b-form-group>
      <b-form-group id="siren" label="SIREN :" label-for="inputSiren">
        <b-form-input id="inputSiren" v-model="infoSocClient.idprof1" :disabled="true"></b-form-input>
      </b-form-group>
      <b-form-group id="siret" label="SIRET :" label-for="inputSiret">
        <b-form-input id="inputSiret" v-model="infoSocClient.idprof2" :disabled="true"></b-form-input>
      </b-form-group>
      <b-form-group id="ape" label="APE:" label-for="inputApe">
        <b-form-input id="inputApe" v-model="infoSocClient.idprof3" :disabled="true"></b-form-input>
      </b-form-group>
      <b-form-group id="tvaIntra" label="TVA intra:" label-for="inputTvaIntra">
        <b-form-input id="inputTvaIntra" v-model="infoSocClient.tva_intra" :disabled="true"></b-form-input>
      </b-form-group>
    </b-form> 
  </div>
</template>

<style>
#infoSoc{
  width: 50%;
  margin: auto;
  margin-top: 3em;
}

#name, #phoneSoc{
  width: 100%;
}

#zipSoc, #siren, #ape{
  width: 40%;
  float: left;
  margin-right: 20%;
}

#townSoc, #siret, #tvaIntra{
  width: 40%;
  float: left;
}

</style>

<script>
import script from '../../script/script.js';

export default {
  data() {
    return {
      post: {},
      infoSocClient:[],
    }
  },
  mounted:function(){
    this.verifyRight();
    this.loadingInfo();
    this.getInfoSocClient();
    this.activeClassMenuProfil();
  },
  methods: {
    verifyRight: script.verifyRight,
    getInfoSocClient: script.getInfoSocClient,
    activeClassMenuProfil: script.activeClassMenuProfil,
    loadingInfo: script.loadingInfo
  },
  components: {
    
  }
}
</script>
