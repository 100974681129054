<template>
	<div>
		<el-button @click.native.prevent="addSituation()" type="primary" size="small" round> Ajouter une situation</el-button>
		<br>
		<div v-if="tableSituationsDefault.length">
			<div class="centerCotation" v-for="(situation) in tableSituationsDefault" :key="situation.id">
				<div class="family">
					<h4><div contenteditable="true" class="editme" @blur="onEditSituation" @keydown.enter="endSituation" :editId="situation.id" editField='nomSituation' :dataInit="situation.nomSituation">{{ situation.nomSituation }}</div></h4>
					<el-button class="btnDeleteFamily" @click.native.prevent="deleteSituation(situation.id)" type="danger" icon="el-icon-delete" circle></el-button>
				</div>
				<b-table ref="tableFamily" small responsive striped :items="situation.penibilite" :fields="fieldsPenibilite" show-empty>
					<template slot="empty" slot-scope="">
						<div style="text-align:center;">Cette situation ne comporte aucune pénibilité</div>
					</template>
					<template  #cell(penibilite)="data">
						<div contenteditable="true" class="editme" style="text-align: initial;" @blur="onEdit" @keydown.enter="endEdit" :editSituation="situation.id" :editId="data.item.id" editField='nomPenibilite' :dataInit="data.item.nomPenibilite">{{ data.item.nomPenibilite }}</div>
					</template>
					<template  #cell(intensite)="data">
						<div contenteditable="true" class="editme" style="text-align: initial;" @blur="onEdit" @keydown.enter="endEdit" :editSituation="situation.id" :editId="data.item.id" editField='intensite' :dataInit="data.item.intensite">{{ data.item.intensite }}</div>
					</template>
					<template  #cell(duree)="data">
						<div contenteditable="true" class="editme" style="text-align: initial;" @blur="onEdit" @keydown.enter="endEdit" :editSituation="situation.id" :editId="data.item.id" editField='duree' :dataInit="data.item.duree">{{ data.item.duree }}</div>
					</template>
					<template #cell(presence)="data">
						<el-checkbox v-model="data.item.presence" @change="changePresence()"></el-checkbox>
					</template>
					<template  #cell(justification)="data">
						<div contenteditable="true" class="editme" style="text-align: initial;" @blur="onEdit" @keydown.enter="endEdit" :editSituation="situation.id" :editId="data.item.id" editField='justification' :dataInit="data.item.justification">{{ data.item.justification }}</div>
					</template>
					<template  #cell(nbSalarie)="data">
						<div contenteditable="true" class="editme" style="text-align: initial;" @blur="onEdit" @keydown.enter="endEdit" :editSituation="situation.id" :editId="data.item.id" editField='nbSalarie' :dataInit="data.item.nbSalarie">{{ data.item.nbSalarie }}</div>
					</template>
					<template  #cell(pourcentEffectif)="data">
						{{ data.item.pourcentEffectif + " %"}}
					</template>
					<template  #cell(actions)="data">
						<i class="far fa-trash-alt" @click="deleteRow(data.item.id, situation.id)" style="color:red; cursor:pointer;"></i>
					</template>
				</b-table>
				<el-button @click.native.prevent="addRow(situation.id)" type="text" size="small"> Ajouter un facteur de pénibilité</el-button>
			</div>
			<br>
		</div>
		<div v-else class="centerCotation">
			<h4>Aucune situation de pénibilité</h4>
		</div>
	</div>
</template>

<script>
import scriptQse from '../../../script/script-qse.js';
export default {
	data () {
		return {
			fieldsPenibilite: [
				{ key: 'penibilite', label: 'Facteurs de pénibilité' },
				{ key: 'intensite', label: 'Intensité minimale' },
				{ key: 'duree', label: 'Durée minimale' },
				{ key: 'presence', label: 'Présence du facteur de l\'entreprise' },
				{ key: 'justification', label: 'Justification' },
				{ key: 'nbSalarie', label: 'Nombre de salariés exposé à chaque facteur' },
				{ key: 'pourcentEffectif', label: '% / effectif global' },
				{ key: 'actions', label: '' }
			]
		}
	},
	methods: {
		updateExtrafieldsUserQse: scriptQse.updateExtrafieldsUserQse,
		deleteRow(idPenibilite, idSituation) {
			if(confirm("Voulez-vous supprimer cette pénibilité ?")){
				this.$store.commit("deletePenibiliteDefault", {idPenibilite:idPenibilite, idSituation:idSituation})
				this.updateExtrafieldsUserQse(this.$store.state.infoSoc.id)
			}
		},
		addRow(idSituation) {
			this.$store.commit("addPenibiliteDefault", idSituation)
			this.updateExtrafieldsUserQse(this.$store.state.infoSoc.id)
		},
		deleteSituation(idSituation) {
			if(confirm("Voulez-vous supprimer cette situation ?")){
				this.$store.commit("deleteSituationDefault", idSituation)
				this.updateExtrafieldsUserQse(this.$store.state.infoSoc.id)
			}
		},
		addSituation() {
			this.$store.commit("addSituationDefault")
			this.updateExtrafieldsUserQse(this.$store.state.infoSoc.id)
		},
		onEdit (evt) {
			var initValue = evt.target.getAttribute('dataInit')
			var newValue = evt.target.innerText
			var editId = evt.target.getAttribute('editId')
			var editField = evt.target.getAttribute('editField')
			var editSituation = evt.target.getAttribute('editSituation')

			if (newValue !== initValue) {
				this.$store.commit("updatePenibiliteDefault", {idPenibilite: editId, idSituation: editSituation, field: editField, value: newValue})
				evt.target.setAttribute('dataInit', newValue)
				this.updateExtrafieldsUserQse(this.$store.state.infoSoc.id)
			}
		},

		endEdit (evt) {
			evt.target.blur(evt)	
		},
		onEditSituation (evt) {
			var initValue = evt.target.getAttribute('dataInit')
			var newValue = evt.target.innerText
			var editId = evt.target.getAttribute('editId')
			var editField = evt.target.getAttribute('editField')

			if(editField == 'nbSalarie'){
				newValue = parseFloat((newValue+"").replace(",","."))
			}

			if (newValue !== initValue) {
				this.$store.commit("updateSituationDefault", {id: editId, field: editField, value: newValue})
				evt.target.setAttribute('dataInit', newValue)
				this.updateExtrafieldsUserQse(this.$store.state.infoSoc.id)
			}
		},

		endEditSituation (evt) {
			evt.target.blur(evt)	
		},
		changePresence () {
			this.updateExtrafieldsUserQse(this.$store.state.infoSoc.id)
		}
	},
	computed: {
		tableSituationsDefault () {
			return this.$store.state.situationsDefault
		},
		infosGenerales () {
			return this.$store.sate.infosGenerales
		}
	},
	mounted () {
	}
}
</script>

<style>
 	button:focus{
		outline:none;
	}

	.family{
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.btnDeleteFamily{
		margin: 1em;
	}
</style>