<template>
	<div class="progAction">
		<h5>Programme de prévention</h5>
			<b-table ref="tableProgAction" small responsive striped :items="progAction" :fields="fieldsProgAction" show-empty>
                <template slot="empty" slot-scope="">
                  	<div style="text-align:center;">Ce tiers ne possède aucun programme de prévention</div>
                </template>
                <template v-slot:cell(numAction)="data">
                  	<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.index" editField='numAction' :dataInit="data.item.numAction">{{ data.item.numAction }}</div>
                </template>
                <template v-slot:cell(risque)="data">
					<el-select v-model="data.item.risque" @change="onChange()" clearable placeholder="Choisir un risque">
							<el-option v-for="item in famillesOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
                </template>
				<template v-slot:cell(unite)="data">
					<el-select v-model="data.item.unite" @change="onChange()" clearable multiple placeholder="Choisir une unité">
							<el-option v-for="item in unitesOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
                </template>
                <template v-slot:cell(situation)="data">
                  	<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.index" editField='situation' :dataInit="data.item.situation">{{ data.item.situation }}</div>
                </template>
				<template v-slot:cell(typeAction)="data">
                  	<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.index" editField='typeAction' :dataInit="data.item.typeAction">{{ data.item.typeAction }}</div>
                </template>
                <template v-slot:cell(effet)="data">
                  	<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.index" editField='effet' :dataInit="data.item.effet">{{ data.item.effet }}</div>
                </template>
				<template v-slot:cell(pilote)="data">
                  	<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.index" editField='pilote' :dataInit="data.item.pilote">{{ data.item.pilote }}</div>
                </template>
                <template v-slot:cell(delai)="data">
					<el-date-picker type="date" placeholder="Choisissez une date" v-model="data.item.delai" style="width: 100%;"></el-date-picker>
                  	<!-- <div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.index" editField='delai' :dataInit="data.item.delai">{{ data.item.delai }}</div> -->
                </template>
				<template v-slot:cell(avancement)="data">
                  	<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.index" editField='avancement' :dataInit="data.item.avancement">{{ data.item.avancement }}</div>
                </template>
				<template v-slot:cell(budget)="data">
                  	<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.index" editField='budget' :dataInit="data.item.budget">{{ data.item.budget }}</div>
                </template>
                <template v-slot:cell(commentaire)="data">
                  	<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.index" editField='commentaire' :dataInit="data.item.commentaire">{{ data.item.commentaire }}</div>
                </template>
				<template v-slot:cell(actions)="data">
                  	<i class="far fa-trash-alt" @click="deleteRow(data.index, progAction)" style="color:red; cursor:pointer;"></i>
                </template>
			</b-table>
			<el-button @click.native.prevent="addRow(progAction)" type="text" size="small"> Ajouter une ligne</el-button>
	</div>
</template>

<script>
import moment from "moment"
import script from '../../../script/script.js';
import scriptQse from '../../../script/script-qse.js';
export default {
	data(){
		return {
			fieldsProgAction:[
				{key:"numAction", label:"N° Action"},
				{key:"risque", label:"Risque"},
				{key:"unite", label:"Unite"},
				{key:"situation", label:"Situation problématique"},
				{key:"typeAction", label:"Type d'action"},
				{key:"effet", label:"Effet attendu sur le risque concerné"},
				{key:"pilote", label:"Pilote de l'action"},
				{key:"delai", label:"Délai de réalisation"},
				{key:"avancement", label:"% avancement"},
				{key:"budget", label:"Budget"},
				{key:"commentaire", label:"Commentaire"},
				{key:"actions", label:""},
			]
		}
	},
	computed:{
		progAction(){
			return this.$store.state.progAction
		},
		autoIncrementProgAction(){
			return this.$store.state.autoIncrementProgAction
		},
		famillesOptions(){
			let familles = this.$store.state.famillesRisques.familles
			let famillesOptions = []
			familles.forEach(famille => {
				famillesOptions.push({
					value:famille.nomFamille,
					label:famille.nomFamille
				})
			})
			return famillesOptions
		},
		unitesOptions(){
			let unites = this.$store.state.unites
			let unitesOptions = []
			unites.forEach(unite => {
				unitesOptions.push({
					value:unite.nomUnite,
					label:unite.nomUnite
				})
			})
			return unitesOptions
		}
	},
	methods:{
		updateExtrafieldsQse: scriptQse.updateExtrafieldsQse,
		deleteRow(index, rows) {
			if(confirm("Voulez-vous supprimer cette entrée ?")){
				rows.splice(index, 1);
				this.$store.commit("updateProgAction", this.progAction)
				this.updateExtrafieldsQse(this.$store.state.infoSoc.id)
			}
		},
		addRow(rows) {
			let dateNow = moment().format("YYYY-MM-DD")
			rows.push({
				'numProgAction':this.autoIncrementProgAction,
				'numAction':'Saisir un numéro d\'action', 
				'risque':'', 
				'unite':[], 
				'situation':'Saisir une situation', 
				'typeAction':'Saisir un type d\'action', 
				'effet':'Saisir l\'effet', 
				'pilote':'Saisir un pilote', 
				'delai': dateNow, 
				'avancement':'Saisir un avancement', 
				'budget':'Saisir un budget', 
				'commentaire':'Saisir un commentaire'
			})
			this.$store.commit("updateAutoIncrementProgAction", parseInt(this.autoIncrementProgAction)+1)
			this.$store.commit("updateProgAction", this.progAction)
			this.updateExtrafieldsQse(this.$store.state.infoSoc.id)
		},
		onEdit (evt) {
			var initValue = evt.target.getAttribute('dataInit')
			var newValue = evt.target.innerText
			var editId = evt.target.getAttribute('editId')
			var editField = evt.target.getAttribute('editField')

			if (newValue !== initValue) {
				this.progAction[editId][editField] = newValue 
				this.$store.commit("updateProgAction", this.progAction)
				evt.target.setAttribute('dataInit', newValue)
				this.updateExtrafieldsQse(this.$store.state.infoSoc.id)
			}
		},

		endEdit (evt) {
			evt.target.blur(evt)	
		},
		onChange(){
			this.updateExtrafieldsQse(this.$store.state.infoSoc.id)
		}
	}
}
</script>

<style>
.progAction{
	text-align: center;
}
</style>