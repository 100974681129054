<template>
  <b-nav id="navigation" justified tabs>
    <b-nav-item @click="activeClassMenuProfil()" :to="'/profilClient'"><span class="far fa-address-card"></span> informations</b-nav-item>
    <b-nav-item @click="activeClassMenuProfil()" :to="'/infoSoc'"><span class="fas fa-briefcase"></span> Profil de l'entreprise</b-nav-item>
    <b-nav-item @click="activeClassMenuProfil()" :to="'/changePass'"><span class="fas fa-lock"></span> Paramètres de connexion</b-nav-item>
  </b-nav>
</template>

<style>

</style>

<script>
import script from '../../script/script.js';

export default {
  methods: {
    activeClassMenuProfil: script.activeClassMenuProfil
  }
}
</script>
