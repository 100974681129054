<template>
  <div>
    <div style='padding:10px;'>
      <div id='recherche'>
        <b-row>
          <b-col class="my-1">
            <b-form-group>
              <b-input-group>
                <b-form-input v-model="filter" placeholder="Recherche" />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">Effacer</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>         
      </div>

      <div>
        <b-table class="text-center" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered" responsive :striped="true" :fields="fieldsTemplate" :items="templates" :current-page="currentPage" :per-page="perPage">
          <template v-slot:cell(nom)="data">
            <span style='font-size:14px;'>{{ data.item.text }}</span>
          </template>
          <template v-slot:cell(actif)="data">
            <a v-if="data.item.active" v-on:click="activerTemplate(data.item.value,data.item.active)"><span class="far fa-check-square" style="cursor:pointer;"></span></a>
            <a v-else v-on:click="activerTemplate(data.item.value,data.item.active)"><span class="far fa-square" style="cursor:pointer;"></span></a>
          </template>
          <template v-slot:cell(visual)="data">
            <a v-on:click.prevent="visualiserTemplate(data.item.text,data.item.value,$event.target)"><span class='far fa-eye' style="cursor:pointer;"></span></a>
          </template>
        </b-table>
        <div v-if="totalRows>perPage">
          <b-pagination size="md" :total-rows="totalRows" v-model="currentPage" :per-page="perPage" />
        </div>
      </div>

      <b-modal id="modalVisualTemplate" @hide="resetModal" :title="modalVisualTemplate.title" hide-footer>
      <template>
        <div v-html="modalVisualTemplate.content">
        </div>
      </template>
    </b-modal>
    </div>
  </div> 
</template>

<script>
import script from '../../script/script.js';

export default {
  data() {
    return {
      post: {},
      templates:[],
      currentPage: 1,
      perPage: 15,
      filter:null,
      sortBy: null,
      sortDesc: false,
      sortDirection: 'asc',
      totalRows: 1,
      fieldsTemplate:[{key:"nom", label:"Nom du template"},{key:"actif",label:"Actif"},{key:"visual",label:""}],
      modalVisualTemplate: { title: '', content: '' }
    }
  },
  mounted:function(){
    this.verifyRight();
    this.loadingTemplatesMail();
    this.totalRows = this.templates.length;
  },
  methods: {
    verifyRight: script.verifyRight,
    loadingTemplatesMail: script.loadingTemplatesMail,
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    visualiserTemplate: script.visualiserTemplate,
    activerTemplate: script.activerTemplate
  },
  components: {
    
  }
}
</script>

<style>

</style>
