<template>
<div>
  <b-container fluid class="bv-example-row" style="padding: 10px 0px 0px 0px;">
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <b-tabs pills card vertical>
            <b-tab class="outlineLess">
              <template slot="title">
                <i class="far fa-address-book" style="padding-right:5px;"></i> Contacts
              </template>
              <div style="position: relative; float:right; padding-bottom:15px;">
                <b-button variant="info" size="sm" v-b-modal.newContact v-b-popover.hover="'Ajouter un contact'"><i class="fas fa-user-plus"></i></b-button>
                <b-modal size="sm" ref="newContact" id="newContact" title="Ajouter un contact" hide-footer>
                  <b-form ref="formContact" v-if="show">
                    <b-form-text><b>Nom :</b></b-form-text>
                    <b-form-input v-model="formContact.lastname" size="sm" id="lastname" required></b-form-input>
                    <b-form-text style="margin-top:10px;"><b>Prénom :</b></b-form-text>
                    <b-form-input v-model="formContact.firstname" size="sm" required></b-form-input>
                    <b-form-text style="margin-top:10px;">Fonction :</b-form-text>
                    <b-form-input v-model="formContact.poste" size="sm"></b-form-input>
                    <b-form-text style="margin-top:10px;"><b>Téléphone Pro. :</b></b-form-text>
                    <b-form-input v-model="formContact.phone_pro" size="sm" required></b-form-input>
                    <b-form-text style="margin-top:10px;"><b>Téléphone Mobile :</b></b-form-text>
                    <b-form-input v-model="formContact.phone_mobile" size="sm"></b-form-input>
                    <b-form-text style="margin-top:10px;"><b>Email :</b></b-form-text>
                    <b-form-input v-model="formContact.email" size="sm" required></b-form-input>
                    <b-form-text style="margin-top:10px;">Adresse :</b-form-text>
                    <b-form-input v-model="formContact.address" size="sm"></b-form-input>
                    <b-form-text style="margin-top:10px;">CP :</b-form-text>
                    <b-form-input v-model="formContact.zip" size="sm"></b-form-input>
                    <b-form-text style="margin-top:10px;">Ville :</b-form-text>
                    <b-form-input v-model="formContact.town" size="sm"></b-form-input>
                    <b-container fluid>
                      <b-row class="text-center">
                        <b-col>
                          <b-button size="sm" @click="onSubmitContact" variant="success" style="margin-top:10px;">VALIDER</b-button>
                          <b-button size="sm" @click="onResetContact" variant="danger" style="margin-top:10px;">RESET</b-button>
                        </b-col>
                      </b-row>
                    </b-container>
                  </b-form>
                </b-modal>
              </div>
              <b-table ref="tblContacts" small responsive striped :items="infosContacts" :fields="fieldsContacts"  show-empty>
                <template slot="empty" slot-scope="">
                  <div style="text-align:center;">Ce tiers ne possède aucun contact</div>
                </template>
                <template v-slot:cell(lastname)="data">
                  <div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.item.id" editType="CONTACT" editField='lastname' :dataInit="data.item.lastname">{{ data.item.lastname }}</div>
                </template>
                <template v-slot:cell(firstname)="data">
                  <div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.item.id" editType="CONTACT" editField='firstname' :dataInit="data.item.firstname">{{ data.item.firstname }}</div>
                </template>
                <template v-slot:cell(poste)="data">
                  <div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.item.id" editType="CONTACT" editField='poste' :dataInit="data.item.poste">{{ data.item.poste }}</div>
                </template>
                <template v-slot:cell(phone_pro)="data">
                  <div style="display: inline-block;"><b-link :href="'tel:' + data.item.phone_pro"><i class="fas fa-mobile-alt" v-b-popover.hover="'Appeler'"></i></b-link></div> <div style="display: inline-block; width: 85%;margin-left: 4px;" contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.item.id" editType="CONTACT" editField='phone_pro' :dataInit="data.item.phone_pro">{{ data.item.phone_pro }}</div>
                </template>
                <template v-slot:cell(phone_mobile)="data">
                  <div style="display: inline-block;"><b-link :href="'tel:' + data.item.phone_mobile"><i class="fas fa-mobile-alt" v-b-popover.hover="'Appeler'"></i></b-link></div> <div style="display: inline-block; width: 85%;margin-left: 4px;" contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.item.id" editType="CONTACT" editField='phone_mobile' :dataInit="data.item.phone_mobile">{{ data.item.phone_mobile }}</div>
                </template>
                <template v-slot:cell(email)="data">
                  <div style="display: inline-block;"><b-link :href="'mailto:' + data.item.email"><i class="fas fa-at" v-b-popover.hover="'Envoyer un email'"></i></b-link></div> <div style="display: inline-block; width: 85%;margin-left: 4px;" contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.item.id" editType="CONTACT" editField='email' :dataInit="data.item.email">{{ data.item.email }}</div>
                </template>
                <template v-slot:cell(address)="data">
                  <div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.item.id" editType="CONTACT" editField='address' :dataInit="data.item.address">{{ data.item.address }}</div>
                </template>
                <template v-slot:cell(zip)="data">
                  <div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.item.id" editType="CONTACT" editField='zip' :dataInit="data.item.zip">{{ data.item.zip }}</div>
                </template>
                <template v-slot:cell(town)="data">
                  <div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.item.id" editType="CONTACT" editField='town' :dataInit="data.item.town">{{ data.item.town }}</div>
                </template>
                <template v-slot:cell(actions)="data">
                  <i class="far fa-trash-alt" @click="removeContact(data.item.id)" style="color:red; cursor:pointer;" v-b-popover.hover="'Effacer ce contact'"></i>
                </template>
              </b-table>
            </b-tab>
            <b-tab class="outlineLess">
              <template slot="title">
                <i class="fas fa-map-marker-alt" style="padding-right:5px;"></i> Adresses
              </template>
              <b-card no-body>
                <b-tabs small card end align="right">
                  <b-tab active>
                    <template slot="title">
                      Adresse 1
                    </template>
                    <b-container fluid class="formContainer">
                    <b-row>
                      <b-col sm="5">
                        <b-form-input size="sm" class="input-1" v-model="infosTiers.name" type="text" required placeholder="Nom du tiers"></b-form-input>
                      </b-col>
                    </b-row>
                    <b-row class="formPadding">
                      <b-col sm="12">
                        <b-form-input size="sm" class="input-1" v-model="infosTiers.address" type="text" required placeholder="Adresse"></b-form-input>
                      </b-col>
                    </b-row>
                    <b-row class="formPadding">
                      <b-col sm="4">
                        <b-form-input size="sm" class="input-1" v-model="infosTiers.zip" type="text" required placeholder="Code postal"></b-form-input>
                      </b-col>
                      <b-col sm="8">
                        <b-form-input size="sm" class="input-1" v-model="infosTiers.town" type="text" required placeholder="Ville"></b-form-input>
                      </b-col>
                    </b-row>
                    <b-row class="formPadding">
                      <b-col sm="4">
                        <b-form-input size="sm" class="input-1" v-model="infosTiers.phone" type="text" required placeholder="Téléphone"></b-form-input>
                      </b-col>
                      <b-col sm="8">
                        <b-form-input size="sm" class="input-1" v-model="infosTiers.email" type="email" required placeholder="Email"></b-form-input>
                      </b-col>
                    </b-row>
                    </b-container>
                  </b-tab>
                </b-tabs>
              </b-card>
            </b-tab>
            <b-tab class="outlineLess">
              <template slot="title">
                <i class="far fa-building" style="padding-right:5px;"></i> Informations légales
              </template>
              <b-container fluid>
                <b-row>
                  <b-col sm="8">
                    <label for="forme" class="labelStyle">Forme juridique :</label>
                    <b-form-input size="sm" id="formeJuridique" v-model="infosTiers.forme_juridique" type="text"></b-form-input>
                  </b-col>
                </b-row>
                <b-row style="margin-top:8px;">
                  <b-col sm="8">
                    <label for="siren" class="labelStyle">Siren :</label>
                    <b-form-input size="sm" id="sirenTiers" v-model="infosTiers.idprof1" type="text"></b-form-input>
                  </b-col>
                </b-row>
                <b-row style="margin-top:8px;">
                  <b-col sm="8">
                    <label for="siret" class="labelStyle">Siret :</label>
                    <b-form-input size="sm" id="siretTiers" v-model="infosTiers.idprof2" type="text"></b-form-input>
                  </b-col>
                </b-row>
                <b-row style="margin-top:8px;">
                  <b-col sm="4">
                    <label for="tva_intra" class="labelStyle">TVA Intra. :</label>
                    <b-form-input size="sm" id="tva_intra" v-model="infosTiers.tva_intra" type="text"></b-form-input>
                  </b-col>
                </b-row>
                <b-row style="margin-top:8px;">
                  <b-col sm="4">
                    <label for="naf" class="labelStyle">Code NAF :</label>
                    <b-form-input size="sm" id="naf" v-model="infosTiers.idprof3" type="text"></b-form-input>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
			<b-tab class="outlineLess">
              <template slot="title">
                <i class="fas fa-network-wired" style="padding-right:5px;"></i> Informations générales QSE
              </template>
			  <p><tabInfosGenerales /></p>
			</b-tab>
            <b-tab class="outlineLess">
              <template slot="title">
                <i class="fas fa-university" style="padding-right:5px;"></i> Banque
              </template>
            </b-tab>
            <b-tab class="outlineLess">
              <template slot="title">
                <i class="fas fa-network-wired" style="padding-right:5px;"></i> Extranet
              </template>
              <b-table ref="tblContacts" small responsive striped :items="infosContacts" :fields="fieldsContactsExtranet"  show-empty>
                <template v-slot:cell(empty)>
                  <div style="text-align:center;">Ce tiers ne possède aucun contact</div>
                </template>
                <template v-slot:cell(lastname)="data">
                  <div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.item.id" editType="CONTACT" editField='lastname' :dataInit="data.item.lastname">{{ data.item.lastname }}</div>
                </template>
                <template v-slot:cell(firstname)="data">
                  <div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.item.id" editType="CONTACT" editField='firstname' :dataInit="data.item.firstname">{{ data.item.firstname }}</div>
                </template>
                <template v-slot:cell(email)="data">
                  <div style="display: inline-block;"><b-link :href="'mailto:' + data.item.email"><i class="fas fa-at" v-b-popover.hover="'Envoyer un email'"></i></b-link></div> <div style="display: inline-block; width: 85%;margin-left: 4px;" contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.item.id" editType="CONTACT" editField='email' :dataInit="data.item.email">{{ data.item.email }}</div>
                </template>
                <template v-slot:cell(date)="data">
                  <span v-if="data.item.acces" style='font-size:14px; text-transform: uppercase;'>{{ data.item.datec }}</span>
                  <span v-else style='font-size:14px; text-transform: uppercase;'>Non envoyé</span>
                </template>
                <template v-slot:cell(acces)="data">
                  <a v-if="data.item.acces" v-on:click.stop="connectOnClient(data.item.id, data.item.socid)" style='text-align:center;'><span style="cursor:pointer;" class='fas fa-sign-in-alt' v-b-popover.hover="'Se connecter sur ce compte'"></span></a>
                  <a v-else-if="!data.item.mail" data-toggle='modal' href='#modalContact<?php echo $idEntreprise;?>' onClick='' style='color:red;'><span style="cursor:pointer;" class='fas fa-user-plus' v-b-popover.hover="'Ajouter un mail à ce contact'"></span></a>
                  <a v-else data-toggle='modal' v-on:click.stop='info(data.item, $event.target)'><span class='fas fa-user-plus' style="cursor:pointer;" v-b-popover.hover="'Fournir accès'"></span></a>
                </template>
                <template v-slot:cell(supprimer)="data">
                  <a v-if="data.item.acces" v-on:click.stop="deleteAccess(data.item.id,data.item.socid)" style='text-align:center;'><span style="cursor:pointer; color:red;" class='fas fa-times' v-b-popover.hover="'Supprimer accès'"></span></a>
                </template>
              </b-table>
              <b-modal id="modalInfo" @hide="resetModal" :title="modalInfo.title">
                  <div>
                    <b-form id="formCreateAccess" @submit='onSubmit'>
                      <b-form-group id='mailContactGroup' label='Mail du contact:' label-for='mailContact'>
                        <span style="cursor:pointer; color:red;" class='fas fa-exclamation-triangle' v-b-popover.hover="'Pour changer l\'adresse mail, modifier l\'adresse mail du contact'"></span>
                        <b-form-input id='mailContact' type='email' v-model='form.email' readonly required placeholder='Email du contact' style='width:100%;'/>
                      </b-form-group>
                      <b-form-group id='templateMailGroup' label='Template:' label-for='template'>
                        <b-form-select id='templateMail' v-model='templateSelected' :options='templates' @change="changeTemplate()" style='width:100%;'></b-form-select>
                      </b-form-group>
                      <span style="cursor:pointer; color:red;" class='fas fa-exclamation-triangle' v-b-popover.hover="'Ne pas modifier l\'adresse mail du contact dans le message et ni les liens déjà prérempli'"></span>
                      <b-form-group id='contenuMailGroup' label='Contenu du message:' label-for='contenuMail'>
                        <wysiwyg id='contenuMail' v-model='form.contenuMail' required placeholder='Votre message' style='resize: none; width:100%; height:100%;'></wysiwyg>
                      </b-form-group>
                    </b-form>
                  </div>
                  <div slot="modal-footer" class="w-100">
                    <b-button type="submit" form="formCreateAccess" variant="primary" size="sm" class="float-right">Envoyer</b-button>
                  </div>
              </b-modal>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
  </b-row>
  </b-container>
</div>
</template>

<script>
import script from '../../script/script.js';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import tabInfosGenerales from './moduleQse/TabInfosGenerales'

export default {
	components:{
		tabInfosGenerales
	},
	props:[
		'infosTiers'
	],
  data () {
    return {
      formContact: {
        socid: this.$route.params.id,
        lastname: '',
        firstname: '',
        poste: '',
        phone_pro: '',
        email: '',
        address: '',
        zip: '',
        town: ''
      },
      infosContacts: [],
      fieldsContacts: [
        { key: 'lastname', label: 'Nom' },
        { key: 'firstname', label: 'Prénom' },
        { key: 'poste', label: 'Fonction' },
        { key: 'phone_pro', label: 'Téléphone Pro.' },
        { key: 'phone_mobile', label: 'Téléphone Mobile' },
        { key: 'email', label: 'Email' },
        { key: 'address', label: 'Adresse' },
        { key: 'zip', label: 'CP' },
        { key: 'town', label: 'Ville' },
        { key: 'actions', label: '' }
      ],
      show: true,
      fieldsContactsExtranet: [
        { key: 'lastname', label: 'Nom' },
        { key: 'firstname', label: 'Prénom' },
        { key: 'email', label: 'Email' },
        { key: 'date', label: "Date d'envoi" },
        { key: 'acces', label: '' },
        { key: 'supprimer', label: '' }
      ],
      infosContactsExtranet:[],
      clientsExtranet:{},
      modalInfo: { title: '', content: '' }, 
      pwd:null,
      tokenReinit:null,
      templates:[],
      form:{email:'', contenuMail:''},
      templateSelected: null,
      editor: ClassicEditor,
      editorConfig:{
        
      }
    }
  },

  mounted: function () {
    this.expandClientInfo(this.$route.params.id);
    this.loadingTemplatesMailActive();
  },

  methods: {
    expandClientInfo: script.expandClientInfo,
    updateFields: script.updateFields,
    delContact: script.delContact,
    addContact: script.addContact,

    onEdit (evt) {
      var initValue = evt.target.getAttribute('dataInit')
      var newValue = evt.target.innerText
      var editId = evt.target.getAttribute('editId')
      var editField = evt.target.getAttribute('editField')
      var editType = evt.target.getAttribute('editType')

      if (newValue !== initValue) {
        this.updateFields(editType, editId, editField, newValue)
        evt.target.setAttribute('dataInit', newValue)
      }
    },

    endEdit (evt) {
      evt.target.blur()
    },

    onSubmitContact () {
      this.addContact(this.formContact)
      this.$refs.formContact.reset()
      this.$refs.newContact.toggle()
      this.expandClientInfo(this.$route.params.id)
    },

    onResetContact () {
      this.$refs.formContact.reset()
    },

    removeContact (id) {
      if (confirm('Etes-vous sur de vouloir effacer ce contact ?')) {
        this.delContact(id)
      }
      this.expandClientInfo(this.$route.params.id)
    },
    createAccess: script.createAccess,
    deleteAccess: script.deleteAccess,
    info: script.info,
    resetModal: script.resetModal,
    onSubmit(){
      this.createAccess(this.form.email,this.form.contenuMail,this.pwd,this.tokenReinit);
    },
    connectOnClient: script.connectOnClient,
    loadingTemplatesMailActive: script.loadingTemplatesMailActive,
    loadingTemplateMail: script.loadingTemplateMail,
    changeTemplate(){
      this.loadingTemplateMail(this.templateSelected,this.form.email,this.pwd,this.tokenReinit);
    }
  }
}
</script>
<style>
.editme:focus {
  background-color: white;
  font-style:italic;
  outline: dashed 1px black;
}

.editme:hover {
  cursor:pointer;
}

.formPadding {
  padding-top:5px;
}

.formContainertitle {
  background-color:whitesmoke;
  padding:6px;
}

.formContainertitle2 {
  background-color:whitesmoke;
}

.formContainer {
  background-color:white;
  padding:10px;
}

.outlineLess {
  outline: 0;
}

.labelStyle {
  margin-bottom:0px;
  font-size:0.85em;
  font-weight:bold;
}

#contenuMailGroup .ck-content{
  height: 100%;
}

#contenuMailGroup{
  height: 60%;
}

#contenuMailGroup > div{
  height: 100%;
}

.ck-editor__main{
  height: 30vh;
}

#contenuMailGroup > div > .ck.ck-editor{
  height: 100%;
}
</style>
