import { render, staticRenderFns } from "./TabPersonneRisqueConfig.vue?vue&type=template&id=dc38dc26&scoped=true&"
import script from "./TabPersonneRisqueConfig.vue?vue&type=script&lang=js&"
export * from "./TabPersonneRisqueConfig.vue?vue&type=script&lang=js&"
import style0 from "./TabPersonneRisqueConfig.vue?vue&type=style&index=0&id=dc38dc26&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc38dc26",
  null
  
)

export default component.exports