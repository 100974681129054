<template>
  <div>
    <div style='padding:10px;'>
      <b-tabs id="aideAdmin" pills vertical>
        <b-tab title="Accueil">
          <h3 style='margin-left:2%;text-decoration: underline;'>Les différents icônes sur la page d'accueil</h3>
          <div style=''>
            <img src='/img/help/indexAdmin.png' style='width:70%;margin-left:15%;'/>
          </div>
          <div id='acces'>
            <h4>Accès à l'extranet</h4>
            <p>Ces deux icônes permettent de savoir si les codes d'accès ont bien été envoyés au client.</p>
            <span class='far fa-circle'></span> : Les codes d'accès pour l'extranet n'ont pas été fournis au client.
            <br/><br/>
            <span class='far fa-check-circle'></span> : Les codes d'accès pour l'extranet ont été fournis au client.
          </div>
          <div id='logo'>
            <br/>
            <h4>Logo du client</h4>
            <p>Les icônes ci-dessous permettent de savoir si le logo du client est bien disponible afin qu'il soit intégrer dans son extranet.</p>
            <span class='far fa-image' style='color:red;'></span> : Le logo du client n'a pas été ajouté.
            <br/><br/>
            <span class='far fa-image' style='color:green;'></span> : Le logo du client est disponible.
          </div>
          <div id='informationEnvoi'>
            <div id='envoiAcces'>
              <br/>
              <h4>Envoi des code d'accès à l'extranet au client</h4>
              <p>Un bandeau latéral apparait à coté du tableau lors d'un clic sur une ligne. Dans ce bandeau vous pourrez visualiser contacts de la société qui ont un accès à l'extranet, donner un accès à ceux qui en n'ont pas et à quel moment vous lui avez fourni ses accès.</p>
              <div style=''>
              <br/>
                  <img src='/img/help/afficheInfoEnvoi.png' style='width:30%;margin-left:35%;'/>
                  
              </div>
              <br/>
              <span class='fas fa-user-plus'></span> : Une pop-up s'ouvre à l'écran et un formulaire apparait lors d'un clic sur cet icône. Le mot de passe du client et le corp du mail sont générés automatiquement, cependant ils peuvent être modifié.
              
              <div style=''>
              <br/>
                  <img src='/img/help/formulaireEnvoiAccès.png' style='width:30%;margin-left:35%;'/>
              </div>
            </div>
            <br/>
            <h4>Informations de l'envoi des codes d'accès et connexion sur un compte client</h4>
            <span class='fas fa-sign-in-alt' v-b-tooltip.hover title='Se connecter sur ce compte'></span> : En cliquant sur cet icône vous vous connecterez sur le compte du client et vous aurez accès à l'extranet client.
            <br/><br/>
            <span class='fas fa-times' v-b-tooltip.hover title='Supprimer accès'></span> : En cliquant sur cet icône vous pourrez supprimer le compte.
          </div>
        </b-tab>
        <b-tab title="Gestion multi-sites">
          <h3 style='margin-left:2%;text-decoration: underline;'>Gestion des multi-sites</h3>
          <p>Sur cette page vous pourrez choisir les accès aux différents sites d'un contact. En décochant la case le contact n'aura plus accès aux documents de la société.</p>
          <div style=''>
              <img src='/img/help/multiSociete.png' style='width:70%;margin-left:15%;'/>
          </div>
        </b-tab>

        <b-tab title="Gestion des tickets">
          <h3 style='margin-left:2%;text-decoration: underline;'>Gestion des tickets d'interventions</h3>
          <div id='listeTicket'>
            <h4>Liste des tickets et affichage des messages</h4>
            <p>Au chargement de la page tous les tickets sont chargés.</p>
            <div style=''>
                <img src='/img/help/listTicketAdmin.png' style='width:70%;margin-left:15%;'/>
            </div>
            <br/>
            <p>Vous pouvez les filtrer suivant la sévérité, le type et le statut du ticket.</p>
            <p>Au clic sur un ticket la discussion ouverte sur ce ticket s'affiche.</p>
            <div style=''>
                <img src='/img/help/listTicketAdminConvers.png' style='width:30%;margin-left:35%;'/>
            </div>
            <br/>
            <p>Vous pourrez ainsi répondre au client afin de le tenir informer sur l'avancement de l'intervention et ajouter des pièces jointes d'une taille maximale de 6Mo si nécéssaire.</p>
            <div style=''>
                <img src='/img/help/listTicketAdminConversReponse.png' style='width:30%;margin-left:35%;'/>
            </div>
          </div>
          <br/>
          <div id='creationTicket'>
            <h4>Création d'un ticket d'intervention</h4>
            <p>Pour créer un ticket, il vous faut choisir un client, choisir le type de demande, la sévérité, remplir le sujet du message et enfin écrire votre message.</p>
            <p>Il est possible d'ajouter des pièces jointes d'une taille maximale de 6Mo.</p>
            <div style=''>
                <img src='/img/help/creationTicketAdmin.png' style='width:70%;margin-left:15%;'/>
            </div>
          </div>
        </b-tab>

        <b-tab title="Gestion des templates des mails">
        </b-tab>

        <b-tab title="Gestion des images du carrousel client">
        </b-tab>
        
        <b-tab title="Accueil client">
          <h3 style='margin-left:2%;text-decoration: underline;'>Accueil</h3>
          <div style=''>
              <img src='/img/help/accueil.png' style='width:70%;margin-left:15%;'/>
          </div>
          <br/>
          <h3 style='margin-left:2%;text-decoration: underline;'>Dérouler le menu</h3>
          <div style=''>
              <img src='/img/help/menu.png' style='width:30%;margin-left:35%;'/>
          </div>
        </b-tab>

        <b-tab title="Documents client">
          <h3 style='margin-left:2%;text-decoration: underline;'>Icônes sur les documents</h3>
          <div style=''>
              <img src='/img/help/documents.png' style='width:70%;margin-left:15%;'/>
          </div>
          <br/>
          <h4>Icône de téléchargement</h4>
          <span class='fas fa-cloud-download-alt'></span> : Un clic sur cet icône permet de télécharger le document.
          <br/>
          <h4>Icône de visualisation</h4>
          <span class='far fa-eye'></span> : Un clic sur cet icône permet de visualiser le document directement.
          <br/>
          <h4>Icône de commentaire</h4>
          <p>En cliquant sur cet icône vous pourrez éditer le commentaire.</p>
          <span class='fas fa-comment' style='color:#0084ff;'></span> : Cet icône signifie qu'un commentaire à été rédigé pour le document.
          <br/>
          <span class='fas fa-comment' style='color:black;'></span> : Cet icône signifie qu'aucun commentaire n'a été rédigé pour le document.
        </b-tab>

        <b-tab title="Tickets d'interventions client">
          <h3 style='margin-left:2%;text-decoration: underline;'>Suivi des tickets d'interventions</h3>
          <div>
              <h4>Liste des tickets</h4>
              <p>Au chargement de la page tous les tickets sont chargés.</p>
              <div style=''>
                  <img src='/img/help/suiviTicket.png' style='width:70%;margin-left:15%;'/>
              </div>
              <br/>
              <p>Vous pouvez les filtrer suivant la sévérité, le type et le statut du ticket.</p>
              <p>Au clic sur un ticket la discussion ouverte sur ce ticket s'affiche.</p>
              <div style=''>
                  <img src='/img/help/suiviTicketConvers.png' style='width:30%;margin-left:35%;'/>
              </div>
              <br/>
              <p>Vous pourrez ainsi répondre au technicien afin de le tenir informer sur les différents changements de l'intervention et ajouter des pièces jointes d'une taille maximale de 6Mo si nécéssaire.</p>
              <div style=''>
                  <img src='/img/help/suiviTicketReponse.png' style='width:30%;margin-left:35%;'/>
              </div> 
          </div>
          <div>
              <h4>Création d'un ticket d'intervention</h4>
              <p>Pour créer un ticket, il vous faut choisir le type de demande, la sévérité, remplir le sujet du message et enfin écrire votre message.</p>
              <p>Il est possible d'ajouter des pièces jointes d'une taille maximale de 6Mo.</p>
              <div style=''>
                  <img src='/img/help/creationTicket.png' style='width:70%;margin-left:15%;'/>
              </div>
          </div>
        </b-tab>

        <b-tab title="Profil client">
          <h3 style='margin-left:2%;text-decoration: underline;'>Profil du client</h3>
          <div>
            <h4>Profil personnel du client</h4>
            <p>Pour accéder à votre profil client, cliquez sur votre nom en haut à gauche de la page, ensuite cliquez sur mon profil.</p> 
            <div style=''>
                <img src='/img/help/menuProfDeco.png' style='width:30%;margin-left:35%;'/>
            </div>
            <br/>
            <p>Ici vous retrouverez vos informations personnel et vous pourrez effectuer une demande de modifications.</p>
            <div style=''>
                <img src='/img/help/profilPerso.png' style='width:70%;margin-left:15%;'/>
            </div>
            <h4>Modification des informations personnel du client</h4>
            <p>Un mail avec les informations qui ont été modifiés sera envoyé au service client qui traitera votre demande.</p> 
            <div style=''>
                <img src='/img/help/modifInfoPerso.png' style='width:30%;margin-left:35%;'/>
            </div>
          </div>
          <div>
            <h4>Profil de l'entreprise</h4>
            <p>Dans cette page vous retrouverez les informations liées à votre entreprise.</p>
            <p>Pour toutes modifications concernant les informations de votre entreprise veuillez contacter le service client directement.</p>
            <div style=''>
                <img src='/img/help/profilEntreprise.png' style='width:30%;margin-left:35%;'/>
            </div>
          </div>
          <div>
            <h4>Changement de mot de passe</h4>
            <p>Vous pourrez modifier votre mot de passe en cliquant sur 'paramètres de connexion'.</p>
            <p>Votre mot de passe doit être d'une longueur minimale de 8 caractères et doit contenir au moins un majuscule, une minuscule et un chiffre.</p> 
            <div style=''>
                <img src='/img/help/changePass.png' style='width:30%;margin-left:35%;'/>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import script from '../../script/script.js';

export default {
  data() {
    return {
      post: {},
    }
  },
  mounted:function(){
    this.verifyRight();
  },
  methods: {
    verifyRight: script.verifyRight,
  },
  components: {
    
  }
}
</script>

<style>
#aideAdmin{
  margin-bottom:3vh;
}
</style>
