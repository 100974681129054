<template>
  <div>
    <Menu />
    <div id="centre">
      <MenuProfil />
      <component :is="layout">
      </component>
    </div>
  </div>
</template>
<script>
import script from '../../script/script.js';
import Menu from './Menu.vue';
import MenuProfil from './MenuProfil.vue';

export default {
  data() {
    return {
      post: {},
    }
  },
  mounted:function(){
     this.verifyRight();
  },
  methods: {
    disconnect: script.disconnect,
    loadingInfo: script.loadingInfo,
    verifyRight: script.verifyRight
  },
  components: {
    Menu,
    MenuProfil
  },
  computed:{
    layout(){
      return(this.$route.meta.layout)+'-layout';
    }
  }
  
}
</script>
<style>
#centre{
  transition: all .3s;
}
</style>

