<template>
	<div>	
		<div style="width:25%;">
			<h5>Nom de l'unité :</h5>
				<div contenteditable="true" class="editme" @blur="onEditUnite" @keydown.enter="endEditUnite" editField='nomUnite' :dataInit="unite.nomUnite">{{ unite.nomUnite }}</div>
			<h5>Pilote :</h5>
				<div contenteditable="true" class="editme" @blur="onEditUnite" @keydown.enter="endEditUnite" editField='pilote' :dataInit="unite.pilote">{{ unite.pilote }}</div>
			<h5>Rédacteur :</h5>
				<div contenteditable="true" class="editme" @blur="onEditUnite" @keydown.enter="endEditUnite" editField='redacteur' :dataInit="unite.redacteur">{{ unite.redacteur }}</div>
			<h5>Description de l'activité :</h5>
				<div contenteditable="true" class="editme" @blur="onEditUnite" @keydown.enter="endEditUnite" editField='descriptionActivite' :dataInit="unite.descriptionActivite">{{ unite.descriptionActivite }}</div>
			<h5>Description des locaux :</h5>
				<div contenteditable="true" class="editme" @blur="onEditUnite" @keydown.enter="endEditUnite" editField='descriptionLocaux' :dataInit="unite.descriptionLocaux">{{ unite.descriptionLocaux }}</div>
			<h5>Nombre de personnes concernées :</h5>
				<div contenteditable="true" class="editme" @blur="onEditUnite" @keydown.enter="endEditUnite" editField='nbPersonne' :dataInit="unite.nbPersonne">{{ unite.nbPersonne }}</div>
		</div>
		<br>

		<template v-if="familles.length">
			<div v-for="(famille) in getFamillesData()" :key="famille.schema.id">
				<b-table ref="tableFamily" small responsive striped :items="getRisquesData(famille.schema.id)" :fields="fieldsUnite" show-empty foot-clone>
					<template v-slot:thead-top="">
						<b-tr>
							<b-th colspan="10">{{ famille.schema.nomFamille}}</b-th>
						</b-tr>
					</template>
					<template slot="empty" slot-scope="">
						<div style="text-align:center;">Cette famille de risque ne comporte aucun risque</div>
					</template>
					<template #cell(nomRisque)="data">
						<div class="risquedesc">{{ data.item.schema.nomRisque }}</div>
					</template>
					<template #cell(presence)="data">
						<el-checkbox v-model="data.item.data.presence" @change="changePresence($event, data.item.schema.id, famille.schema.id)"></el-checkbox>
					</template>
					<template #cell(ps)="data">
						<el-select v-if="data.item.data.presence" v-model="data.item.data.ps" @change="calculRisqueBrut($event, famille.schema.id, data.item.schema.id, data.item.data.g, data.item.data.d)" clearable placeholder="Choisir une note">
							<el-option v-for="item in optionsCotationPs" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select>
						<el-select v-else disabled v-model="data.item.data.ps" clearable placeholder="Choisir une note">
						</el-select>
					</template>
					<template #cell(g)="data">
						<el-select v-if="data.item.data.presence" v-model="data.item.data.g" @change="calculRisqueBrut($event, famille.schema.id, data.item.schema.id, data.item.data.ps, data.item.data.d)" clearable placeholder="Choisir une note">
							<el-option v-for="item in optionsCotationG" :key="item.value" :label="item.label" :value="item.value" ></el-option>
						</el-select>
						<el-select v-else disabled v-model="data.item.data.g" clearable placeholder="Choisir une note">
						</el-select>
					</template>
					<template #cell(risqueBrut)="data">
						<div>{{ data.item.data.risqueBrut }}</div>
					</template>
					<template #cell(d)="data">
						<el-select v-if="data.item.data.presence" v-model="data.item.data.d" @change="calculRisqueResiduel($event, famille.schema.id, data.item.schema.id, data.item.data.risqueBrut)" clearable placeholder="Choisir une note">
							<el-option v-for="item in optionsCotationD" :key="item.value" :label="item.label" :value="item.value" ></el-option>
						</el-select>
						<el-select v-else disabled v-model="data.item.data.d" clearable placeholder="Choisir une note">
						</el-select>
					</template>
					<template #cell(risqueResiduel)="data">
						<div>{{ data.item.data.risqueResiduel }}</div>
					</template>
					<template #cell(evalBrut)="data">
						{{data.item.data.evalBrut}}
					</template>
					<template #cell(evalResiduel)="data">
						{{data.item.data.evalResiduel}}
					</template>
					<template #cell(observation)="data">
						<div contenteditable="true" class="editme observation" @blur="onEdit" @keydown.enter="endEdit" :idRisque="data.item.schema.id" editField='observation' :dataInit="data.item.data.observation">{{ data.item.data.observation }}</div>
					</template>
					
					<template #foot(nomRisque)="">
						<span>Evaluation finale</span>
					</template>
					<template #foot(presence)="">
						<span></span>
					</template>
					<template #foot(ps)="">
						<span></span>
					</template>
					<template #foot(g)="">
						<span></span>
					</template>
					<template #foot(risqueBrut)="">
						<span></span>
					</template>
					<template #foot(d)="">
						<span></span>
					</template>
					<template #foot(risqueResiduel)="">
						<span></span>
					</template>
					<template #foot(evalBrut)="">
						{{ famille.data.evalBrutFinale }}
					</template>
					<template #foot(evalResiduel)="">
						{{ famille.data.evalResiduelFinale }}
					</template>
					<template #foot(observation)="">
						<span></span>
					</template>
				</b-table>
			</div>
			<br>
			<br>
		</template>
		<template v-else class="centerCotation">
			<h4>Aucune famille de risque</h4>
		</template>
	</div>
</template>

<script>
import scriptQse from '../../../script/script-qse.js';
export default {
	data () {
		return {
			fieldsUnite: [
				{ key: 'nomRisque', label: '', class:"headRisque"},
				{ key: 'presence', label: 'Présence', class:"headRisque"},
				{ key: 'ps', label: 'PS', class:"headRisque"},
				{ key: 'g', label: 'G', class:"headRisque"},
				{ key: 'risqueBrut', label: 'R. Brut', class:"headRisque"},
				{ key: 'd', label: 'D', class:"headRisque"},
				{ key: 'risqueResiduel', label: 'R. Résiduel', class:"headRisque"},
				{ key: 'evalBrut', label: 'Eval. Brute', class:"headRisque"},
				{ key: 'evalResiduel', label: 'Eval. Résid.', class:"headRisque"},
				{ key: 'observation', label: 'Observations', class:"headRisque"}
			]
		}
	},
	props: [
		'unite'
	],
	methods: {
		updateExtrafieldsQse: scriptQse.updateExtrafieldsQse,
		onEdit (evt) {
			var initValue = evt.target.getAttribute('dataInit')
			var newValue = evt.target.innerText
			var editField = evt.target.getAttribute('editField')
			var idRisque = evt.target.getAttribute('idRisque')

			if (newValue !== initValue) {
				var dataUnites = this.donnees.unites.filter(unite => (unite.id == this.unite.id))
				dataUnites.forEach(dataUnite => {
					var risques = dataUnite.risques.filter(r => (r.id == idRisque))
					risques.forEach(risque => {
						risque[editField] = newValue
					})
				})
				evt.target.setAttribute('dataInit', newValue)
				this.updateExtrafieldsQse(this.$store.state.infoSoc.id)
			}
		},

		endEdit (evt) {
			evt.target.blur(evt)
		},
		onEditUnite (evt) {
			var initValue = evt.target.getAttribute('dataInit')
			var newValue = evt.target.innerText
			var editField = evt.target.getAttribute('editField')

			if (newValue !== initValue) {
				this.$store.commit("updateUnites", {id: this.unite.id, field: editField, value: newValue})
				evt.target.setAttribute('dataInit', newValue)
				this.updateExtrafieldsQse(this.$store.state.infoSoc.id)
			}
		},

		endEditUnite (evt) {
			evt.target.blur(evt)
		},
		calculFamilleBrut(idFamille) {
			let risques = this.getRisquesData(idFamille)
			let notes = []
			var dataUnites = this.donnees.unites.filter(unite => (unite.id == this.unite.id))
			risques.forEach(risque => {
				if(((risque.data.g != null) && (risque.data.ps != null)) && ((risque.data.g != '') && (risque.data.ps != '')))
				{
					notes.push(parseInt(risque.data.risqueBrut, 10))
				}
			})
			if (notes.length){
				let noteMax = notes.reduce(function(a,b) {
					return Math.max(a, b)
				})
				dataUnites.forEach(dataUnite => {
					var familles = dataUnite.familles.filter(f => (f.id == idFamille))
					familles.forEach(famille => {
						let i = 0
						let numCoteBrut = this.cotation.brut.length
						this.cotation.brut.forEach(cote => {
							if(++i === numCoteBrut){
								if(noteMax >= cote.cotationMin){
									famille.evalBrutFinale = cote.note
								}
							}else{
								if(noteMax >= cote.cotationMin && noteMax <= cote.cotationMax){
									famille.evalBrutFinale = cote.note
								}
							}
						})
					})
				})
			}else{
				dataUnites.forEach(dataUnite => {
					var familles = dataUnite.familles.filter(f => (f.id == idFamille))
					familles.forEach(famille => {
						famille.evalBrutFinale = '-'
					})
				})
			}
		},
		calculFamilleResiduel(idFamille) {
			let risques = this.getRisquesData(idFamille)
			let notes = []
			var dataUnites = this.donnees.unites.filter(unite => (unite.id == this.unite.id))
			risques.forEach(risque => {
				if(((risque.data.d != null) && (risque.data.g != null) && (risque.data.ps != null)) && ((risque.data.g != '') && (risque.data.ps != '') && (risque.data.d != ''))){
					notes.push(parseInt(risque.data.risqueResiduel,10))
				}
			})

			if (notes.length){
				let noteMax = notes.reduce(function(a,b) {
					return Math.max(a, b)
				})
				dataUnites.forEach(dataUnite => {
					var familles = dataUnite.familles.filter(f => (f.id == idFamille))
					familles.forEach(famille => {
						let i = 0
						let numCoteRes = this.cotation.residuel.length
						this.cotation.residuel.forEach(cote => {
							if(++i === numCoteRes){
								if(noteMax >= cote.cotationMin){
									famille.evalResiduelFinale = cote.note
								}
							}else{
								if(noteMax >= cote.cotationMin && noteMax <= cote.cotationMax){
									famille.evalResiduelFinale = cote.note
								}
							}
						})
					})
				})
			} else {
				dataUnites.forEach(dataUnite => {
					var familles = dataUnite.familles.filter(f => (f.id == idFamille))
					familles.forEach(famille => {
						famille.evalResiduelFinale = '-'
					})
				})
			}
			
		},
		calculRisqueBrut (evt, idFamille, idRisque, note, noteResiduel){	
			var dataUnites = this.donnees.unites.filter(unite => (unite.id == this.unite.id))
			dataUnites.forEach(dataUnite => {
				var risques = dataUnite.risques.filter(r => (r.id == idRisque))
				risques.forEach(risque => {
					risque.risqueBrut = Math.round((evt * note) * 100) / 100
					risque.risqueResiduel = Math.round((noteResiduel * risque.risqueBrut) * 100) / 100
					let i = 0
					let numCoteBrut = this.cotation.brut.length
					let j = 0
					let numCoteRes = this.cotation.residuel.length
					this.cotation.brut.forEach(cote => {
						if (evt && note){
							if(++i === numCoteBrut){
								if(risque.risqueBrut >= cote.cotationMin){
									risque.evalBrut = cote.note
								}
							}else{
								if(risque.risqueBrut >= cote.cotationMin && risque.risqueBrut <= cote.cotationMax){
									risque.evalBrut = cote.note
								}
							}
						} else {
							risque.evalBrut = '-'
						}
					})
					this.cotation.residuel.forEach(cote => {
						if (risque.risqueBrut && noteResiduel){
							if(++j === numCoteRes){
								if(risque.risqueResiduel >= cote.cotationMin){
									risque.evalResiduel = cote.note
								}
							}else{
								if(risque.risqueResiduel >= cote.cotationMin && risque.risqueResiduel <= cote.cotationMax){
									risque.evalResiduel = cote.note
								}
							}
						} else {
							risque.evalResiduel = '-'
						}
					})
				})
			})
			this.calculFamilleBrut(idFamille)
			this.calculFamilleResiduel(idFamille)
			this.updateExtrafieldsQse(this.$store.state.infoSoc.id)
		},
		calculRisqueResiduel (evt, idFamille, idRisque, note){
			var dataUnites = this.donnees.unites.filter(unite => (unite.id == this.unite.id))
			dataUnites.forEach(dataUnite => {
				var risques = dataUnite.risques.filter(r => (r.id == idRisque))
				risques.forEach(risque => {
					risque.risqueResiduel = Math.round((evt * note) * 100) / 100
					let j = 0
					let numCoteRes = this.cotation.residuel.length
					this.cotation.residuel.forEach(cote => {
						if (evt && note){
							if(++j === numCoteRes){
								if(risque.risqueResiduel >= cote.cotationMin){
									risque.evalResiduel = cote.note
								}
							}else{
								if(risque.risqueResiduel >= cote.cotationMin && risque.risqueResiduel <= cote.cotationMax){
									risque.evalResiduel = cote.note
								}
							}
						} else {
							risque.evalResiduel = '-'
						}
					})
				})
			})
			this.calculFamilleResiduel(idFamille)
			this.updateExtrafieldsQse(this.$store.state.infoSoc.id)
		},
		getRisquesByFamille(famille) {
			var risquesByFamilles = this.$store.state.famillesRisques.risques.filter(a => (a.idFamille == famille.id))
			return risquesByFamilles
		},
		getFamillesData() {
			let familles = []

			this.familles.forEach(element => {
				var dataUnites = this.donnees.unites.filter(unite => (unite.id == this.unite.id))
				dataUnites.forEach(dataUnite => {
					let dataFamilles = dataUnite.familles.filter(famille => (famille.id == element.id))
					familles.push({
						schema: element,
						data: dataFamilles[0]
					})
				})
			})

			return familles
		},
		getRisquesData(famille) {
			let risques = []
			this.risques.forEach(element => {
				var dataUnites = this.donnees.unites.filter(unite => (unite.id == this.unite.id))
				dataUnites.forEach(dataUnite => {
					let dataRisques = dataUnite.risques.filter(risque => (risque.id == element.id))
					risques.push({
						schema: element,
						data: dataRisques[0]
					})
				})
			})

			return risques.filter(risque => (risque.schema.idFamille == famille))
		},
		setData () {
			var dataUnites = this.donnees.unites.filter(unite => (unite.id == this.unite.id))
			dataUnites.forEach(dataUnite => {
				this.familles.forEach(famille => {
					var familleData = dataUnite.familles.filter(f => (f.id == famille.id))
					if(!familleData.length){
						var newDonneesFamilles = {
						'id': famille.id,
						'evalBrutFinale':'-', 
						'evalResiduelFinale':'-',
						'numerosAction': 'Non défini'
						}
						dataUnite.familles.push(newDonneesFamilles)
					}
				})

				this.risques.forEach(risque => {
					var risqueData = dataUnite.risques.filter(r => (r.id == risque.id))
					if(!risqueData.length){
						var newDonneesRisques = {
							'id': risque.id,
							'presence':null, 
							'ps':null, 
							'g':null, 
							'd':null, 
							'risqueBrut':0, 
							'risqueResiduel':0, 
							'evalBrut':'-', 
							'evalResiduel':'-',
							'observation':'-'
						}
						dataUnite.risques.push(newDonneesRisques)
					}
				})
			})
			this.updateExtrafieldsQse(this.$store.state.infoSoc.id,false)
		},
		changePresence (evt, idRisque, idFamille) {
			var dataUnites = this.donnees.unites.filter(unite => (unite.id == this.unite.id))
			if(!evt){
				dataUnites.forEach(dataUnite => {
					var risques = dataUnite.risques.filter(r => (r.id == idRisque))
					risques.forEach(risque => {
						risque.evalResiduel='-'
						risque.evalBrut='-'
						risque.risqueResiduel=0
						risque.risqueBrut=0
						risque.d=null
						risque.g=null
						risque.ps=null
						risque.observation='-'
					})
				})
			}
			var allPresence = this.getRisquesData(idFamille)
			var present = allPresence.filter(p => (p.data.presence == true))
			if(!present.length){
				dataUnites.forEach(dataUnite => {
					var familles = dataUnite.familles.filter(f => (f.id == idFamille))
					familles.forEach(famille => {
						famille.evalBrutFinale='-' 
						famille.evalResiduelFinale='-'
						famille.numerosAction='Non défini'
					})
				})
			}
			this.calculFamilleBrut(idFamille)
			this.calculFamilleResiduel(idFamille)
			this.updateExtrafieldsQse(this.$store.state.infoSoc.id)
		}
	},
	mounted: function () {
		this.setData()
	},
	computed: {
		familles () {
			return this.$store.state.famillesRisques.familles
		},
		risques () {
			return this.$store.state.famillesRisques.risques
		},
		cotation () {
			return this.$store.state.cotation
		},
		unites () {
			return this.$store.state.unites
		},
		donnees () {
			return this.$store.state.donnees
		},
		optionsCotationPs () {
			var cotationPs = []
			for(var i = 0; i < this.cotation.ps.length; i++) {
				cotationPs.push({value:this.cotation.ps[i].note, label:this.cotation.ps[i].note})
			}
			return cotationPs
		},
		optionsCotationG () {
			var cotationG = []
			for(var i = 0; i < this.cotation.g.length; i++) {
				cotationG.push({value:this.cotation.g[i].note, label:this.cotation.g[i].note})
			}
			return cotationG
		},
		optionsCotationD () {
			var cotationD = []
			for(var i = 0; i < this.cotation.d.length; i++) {
				cotationD.push({value:this.cotation.d[i].note, label:this.cotation.d[i].note})
			}
			return cotationD
		}
	},
	watch: {
		
	}
}
</script>

<style>
tfoot{
	background: wheat;
}

.risquedesc {
	width:60em;
}

.observation{
	width: 20em;
}

th.headTop {
    border: 1px solid #dee2e6;
}

th.headRisque{
	text-align: center;
}
</style>