<template>
	<div>
		<h4>Identification des personnes présentant des risques de développer une forme grave d'infection à COVID-19</h4>
		<h5>Présence de personne à risques :</h5>
		<b-table ref="presencePersonneRisque" small responsive striped :items="identificationPersonneDefault.presencePersonneRisque" :fields="fieldsPresencePersonneRisque" show-empty>
			<template slot="empty" slot-scope="">
				<div style="text-align:center;">Ce tiers ne possède aucune personne à risque</div>
			</template>
			<template v-slot:cell(label)="data">
				<div contenteditable="true" class="" @blur="onEdit" @keydown.enter="endEdit" :editId="data.index" editType="presencePersonneRisque" editField='label' :dataInit="data.item.label">{{ data.item.label }}</div>
			</template>
			<template v-slot:cell(presence)="data">
				<el-checkbox v-model="data.item.presence" @change="changePresence()"></el-checkbox>
			</template>
			<template v-slot:cell(actions)="data">
				<i class="far fa-trash-alt" @click="deleteRow(data.index, identificationPersonneDefault.presencePersonneRisque)" style="color:red; cursor:pointer;"></i>
			</template>
		</b-table>
		<div class="btnAdd">
			<el-button @click.native.prevent="addRow(identificationPersonneDefault.presencePersonneRisque)" type="text" size="small"> Ajouter une ligne</el-button>
			<br/>
		</div>

		<h5>Sont également considérés comme à risques les personnes :</h5>
		<b-table ref="autrePersonneRisque" small responsive striped :items="identificationPersonneDefault.autrePersonneRisque" :fields="fieldsAutrePersonneRisque" show-empty>
			<template slot="empty" slot-scope="">
				<div style="text-align:center;">Ce tiers ne possède aucune autre personne à risque</div>
			</template>
			<template v-slot:cell(label)="data">
				<div contenteditable="true" class="" @blur="onEdit" @keydown.enter="endEdit" :editId="data.index" editType="autrePersonneRisque" editField='label' :dataInit="data.item.label">{{ data.item.label }}</div>
			</template>
			<template v-slot:cell(presence)="data">
				<el-checkbox v-model="data.item.presence" @change="changePresence()"></el-checkbox>
			</template>
			<template v-slot:cell(actions)="data">
				<i class="far fa-trash-alt" @click="deleteRow(data.index, identificationPersonneDefault.autrePersonneRisque)" style="color:red; cursor:pointer;"></i>
			</template>
		</b-table>
		<div class="btnAdd">
			<el-button @click.native.prevent="addRow(identificationPersonneDefault.autrePersonneRisque)" type="text" size="small"> Ajouter une ligne</el-button>
		</div>
	</div>
</template>

<script>
import scriptQse from '../../../script/script-qse.js';
export default {
	data(){
		return {
			fieldsPresencePersonneRisque:[
				{key:"label", label:"Titre"},
				{key:"presence", label:"Présence"},
				{key:"actions", label:""},
			],
			fieldsAutrePersonneRisque:[
				{key:"label", label:"Titre"},
				{key:"presence", label:"Présence"},
				{key:"actions", label:""},
			]
		}
	},
	computed:{
		identificationPersonneDefault(){
			return this.$store.state.identificationPersonneDefault
		}
	},
	methods:{
		updateExtrafieldsUserQse: scriptQse.updateExtrafieldsUserQse,
		deleteRow(index, rows) {
			if(confirm("Voulez-vous supprimer cette présence ?")){
				rows.splice(index, 1);
				this.$store.commit("updateIdentificationPersonneRisqueDefault", this.identificationPersonneDefault)
				this.updateExtrafieldsUserQse()
			}
		},
		addRow(rows) {
			rows.push({
				'id': Date.now(),
				'label':'Saisir un titre', 
				'presence': false,
			})
			this.$store.commit("updateIdentificationPersonneRisqueDefault", this.identificationPersonneDefault)
			this.updateExtrafieldsUserQse()
		},
		onEdit (evt) {
			var initValue = evt.target.getAttribute('dataInit')
			var newValue = evt.target.innerText
			var editId = evt.target.getAttribute('editId')
			var editField = evt.target.getAttribute('editField')
			var editType = evt.target.getAttribute('editType')

			if (newValue !== initValue) {
				this.identificationPersonneDefault[editType][editId][editField] = newValue 
				this.$store.commit("updateIdentificationPersonneRisqueDefault", this.identificationPersonneDefault)
				evt.target.setAttribute('dataInit', newValue)
				this.updateExtrafieldsUserQse()
			}
		},
		endEdit (evt) {
			evt.target.blur(evt)	
		},
		changePresence () {
			this.updateExtrafieldsUserQse()
		}
	}
}
</script>

<style scoped>
	.btnAdd{
		text-align: center;
	}
</style>