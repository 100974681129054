<template>
  <div id="contenuAdmin">
    <MenuAdmin id="menuTop"/>
	<sidebar-menu v-if="!$isMobile()" :menu="verticalMenu" class="v-sidebar-menu-admin" :collapsed="myCollapse" @toggle-collapse="onCollapse"></sidebar-menu>
    <div id="main">
      <component :is="layout">
      </component>
    </div>
  </div>
</template>
<script>
import script from '../../script/script.js';
import MenuAdmin from './MenuAdmin.vue';
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css';
export default {
	props: {
		menu: {
			type: Array,
			required: true
		},
		collapsed: {
			type: Boolean,
			default: true
		},
		widthCollapsed: {
			type: String,
			default: '2.5vw'
		},
		showChild: {
			type: Boolean,
			default: false
		},
		theme: { // available themes: 'white-theme'
			type: String,
			default: ''
		},
		showOneChild: {
			type: Boolean,
			default: false
		},
		rtl: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			post: {},
			myCollapse: true,
			verticalMenu:[
				{
					href:'/indexAdmin',
					title:'Accueil',
					icon:'fas fa-home'
				},
				{
					href:'/tiers',
					title:'Tiers',
					icon:'fas fa-user'
				},
				{
					href:'/configQse',
					title:'Configuration QSE',
					icon:'fas fa-cogs'
				}
			],
		}
	},
	mounted:function(){
		this.verifyRight();
	},
	methods: {
		verifyRight: script.verifyRight,
		onCollapse(collapsed){
			if(collapsed){
				document.getElementById('main').style.left ='3%';
			}else{
				document.getElementById('main').style.left ='12%';
			}
		}
	},
	components: {
		MenuAdmin
	},
	computed:{
		layout(){
		return(this.$route.meta.layout)+'-layout';
		}
	}
  
}
</script>
<style>
	#main{
		flex: 1 0 95%;
		transition: all .3s;
		position: absolute;
		top: 6%;
		left: 3%;
		right: 0;
		bottom: 0;
		max-height: 94%;
		overflow: auto;
	}

	.v-sidebar-menu-admin .vsm--toggle-btn{
		background-color:transparent;
		outline:none;
	}

	.v-sidebar-menu-admin .vsm--item>.vsm--link>.vsm--icon{
		background-color:transparent;
	}

	.v-sidebar-menu-admin.vsm_expanded{
		max-width: 12vw !important;
		background-color:#808285;
		position: absolute;
		height: 94%;
		top: 6%;
	}
	
	.v-sidebar-menu-admin.vsm_collapsed{
		max-width: 2.5vw !important;
		background-color:#808285;
		position: absolute;
		height: 94%;
		top: 6%;
	}

	.v-sidebar-menu-admin div:nth-child(2){
		max-width: 12vw;
	}

	.v-sidebar-menu-admin .vsm--link{
		font-size:13px;
	}

	#contenuAdmin{
		display: flex;
		height: 100%;
		flex-wrap: wrap;
	}

	#menuTop{
		flex: 1 0 100%;
		height: 6%;
	}
</style>
