<template>
	<div class="checklistGeneraleDefault">
		<h5>Affichages obligatoires et consignes de sécurité</h5>
		<b-table ref="checklistGeneraleDefaultAffichageObligatoire" small responsive striped :items="checklistGeneraleDefault.affichageObligatoire" :fields="fieldsChecklistGeneraleAffichageObligatoire" show-empty>
			<template slot="empty" slot-scope="">
				<div style="text-align:center;">Ce tiers ne possède aucun affichage obligatoire et consignes de sécurité</div>
			</template>
			<template v-slot:cell(label)="data">
				<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.index" editTable="affichageObligatoire" editField='label' :dataInit="data.item.label">{{ data.item.label }}</div>
			</template>
			<template v-slot:cell(presence)="data">
				<el-checkbox v-model="data.item.presence" @change="changePresence()"></el-checkbox>
			</template>
			<template v-slot:cell(commentaire)="data">
				<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.index" editTable="affichageObligatoire" editField='commentaire' :dataInit="data.item.commentaire">{{ data.item.commentaire }}</div>
			</template>
			<template v-slot:cell(actions)="data">
				<i class="far fa-trash-alt" @click="deleteRow(data.index, checklistGeneraleDefault.affichageObligatoire)" style="color:red; cursor:pointer;"></i>
			</template>
		</b-table>
		<el-button @click.native.prevent="addRow(checklistGeneraleDefault.affichageObligatoire)" type="text" size="small"> Ajouter une ligne</el-button>
		<br/>

		<h5>Dispositions applicables à certains travaux, équipements, établissements ou produits particuliers </h5>
		<b-table ref="checklistGeneraleDefaultDispositionApplicable" small responsive striped :items="checklistGeneraleDefault.dispositionApplicable" :fields="fieldsChecklistGeneraleDispositionApplicable" show-empty>
			<template slot="empty" slot-scope="">
				<div style="text-align:center;">Ce tiers ne possède aucune disposition applicable à certains travaux</div>
			</template>
			<template v-slot:cell(label)="data">
				<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.index" editTable="dispositionApplicable" editField='label' :dataInit="data.item.label">{{ data.item.label }}</div>
			</template>
			<template v-slot:cell(presence)="data">
				<el-checkbox v-model="data.item.presence" @change="changePresence()"></el-checkbox>
			</template>
			<template v-slot:cell(commentaire)="data">
				<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.index" editTable="dispositionApplicable" editField='commentaire' :dataInit="data.item.commentaire">{{ data.item.commentaire }}</div>
			</template>
			<template v-slot:cell(actions)="data">
				<i class="far fa-trash-alt" @click="deleteRow(data.index, checklistGeneraleDefault.dispositionApplicable)" style="color:red; cursor:pointer;"></i>
			</template>
		</b-table>
		<el-button @click.native.prevent="addRow(checklistGeneraleDefault.dispositionApplicable)" type="text" size="small"> Ajouter une ligne</el-button>
		<br/>

		<h5>Locaux de l'entreprise</h5>
		<b-table ref="checklistGeneraleDefaultLocauxEntreprise" small responsive striped :items="checklistGeneraleDefault.locauxEntreprise" :fields="fieldsChecklistGeneraleLocauxEntreprise" show-empty>
			<template slot="empty" slot-scope="">
				<div style="text-align:center;">Ce tiers ne possède aucun locaux d'entreprise</div>
			</template>
			<template v-slot:cell(label)="data">
				<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.index" editTable="locauxEntreprise" editField='label' :dataInit="data.item.label">{{ data.item.label }}</div>
			</template>
			<template v-slot:cell(presence)="data">
				<el-checkbox v-model="data.item.presence" @change="changePresence()"></el-checkbox>
			</template>
			<template v-slot:cell(commentaire)="data">
				<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.index" editTable="locauxEntreprise" editField='commentaire' :dataInit="data.item.commentaire">{{ data.item.commentaire }}</div>
			</template>
			<template v-slot:cell(actions)="data">
				<i class="far fa-trash-alt" @click="deleteRow(data.index, checklistGeneraleDefault.locauxEntreprise)" style="color:red; cursor:pointer;"></i>
			</template>
		</b-table>
		<el-button @click.native.prevent="addRow(checklistGeneraleDefault.locauxEntreprise)" type="text" size="small"> Ajouter une ligne</el-button>
		<br/>

		<h5>Organisation de la gestion de la sécurité</h5>
		<b-table ref="checklistGeneraleDefaultOrganisationGestionSecurite" small responsive striped :items="checklistGeneraleDefault.organisationGestionSecurite" :fields="fieldsChecklistGeneraleOrganisationSecurite" show-empty>
			<template slot="empty" slot-scope="">
				<div style="text-align:center;">Ce tiers ne possède aucune organisation de gestion de la sécurité</div>
			</template>
			<template v-slot:cell(label)="data">
				<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.index" editTable="organisationGestionSecurite" editField='label' :dataInit="data.item.label">{{ data.item.label }}</div>
			</template>
			<template v-slot:cell(presence)="data">
				<el-checkbox v-model="data.item.presence" @change="changePresence()"></el-checkbox>
			</template>
			<template v-slot:cell(commentaire)="data">
				<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.index" editTable="organisationGestionSecurite" editField='commentaire' :dataInit="data.item.commentaire">{{ data.item.commentaire }}</div>
			</template>
			<template v-slot:cell(actions)="data">
				<i class="far fa-trash-alt" @click="deleteRow(data.index, checklistGeneraleDefault.organisationGestionSecurite)" style="color:red; cursor:pointer;"></i>
			</template>
		</b-table>
		<el-button @click.native.prevent="addRow(checklistGeneraleDefault.organisationGestionSecurite)" type="text" size="small"> Ajouter une ligne</el-button>
		<br/>

		<h5>Organisation des secours</h5>
		<b-table ref="checklistGeneraleDefaultOrganisationSecours" small responsive striped :items="checklistGeneraleDefault.organisationSecours" :fields="fieldsChecklistGeneraleOrganisationSecours" show-empty>
			<template slot="empty" slot-scope="">
				<div style="text-align:center;">Ce tiers ne possède aucune organisation des secours</div>
			</template>
			<template v-slot:cell(label)="data">
				<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.index" editTable="organisationSecours" editField='label' :dataInit="data.item.label">{{ data.item.label }}</div>
			</template>
			<template v-slot:cell(presence)="data">
				<el-checkbox v-model="data.item.presence" @change="changePresence()"></el-checkbox>
			</template>
			<template v-slot:cell(commentaire)="data">
				<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.index" editTable="organisationSecours" editField='commentaire' :dataInit="data.item.commentaire">{{ data.item.commentaire }}</div>
			</template>
			<template v-slot:cell(actions)="data">
				<i class="far fa-trash-alt" @click="deleteRow(data.index, checklistGeneraleDefault.organisationSecours)" style="color:red; cursor:pointer;"></i>
			</template>
		</b-table>
		<el-button @click.native.prevent="addRow(checklistGeneraleDefault.organisationSecours)" type="text" size="small"> Ajouter une ligne</el-button>
		<br/>

		<h5>Accident de travail et maladies professionnelles</h5>
		<b-table ref="checklistGeneraleDefaultAccidentTravail" small responsive striped :items="checklistGeneraleDefault.accidentTravail" :fields="fieldsChecklistGeneraleAccidentTravail" show-empty>
			<template slot="empty" slot-scope="">
				<div style="text-align:center;">Ce tiers ne possède aucun accident de travail ou maladies professionnelles</div>
			</template>
			<template v-slot:cell(label)="data">
				<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.index" editTable="accidentTravail" editField='label' :dataInit="data.item.label">{{ data.item.label }}</div>
			</template>
			<template v-slot:cell(presence)="data">
				<el-checkbox v-model="data.item.presence" @change="changePresence()"></el-checkbox>
			</template>
			<template v-slot:cell(commentaire)="data">
				<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="data.index" editTable="accidentTravail" editField='commentaire' :dataInit="data.item.commentaire">{{ data.item.commentaire }}</div>
			</template>
			<template v-slot:cell(actions)="data">
				<i class="far fa-trash-alt" @click="deleteRow(data.index, checklistGeneraleDefault.accidentTravail)" style="color:red; cursor:pointer;"></i>
			</template>
		</b-table>
		<el-button @click.native.prevent="addRow(checklistGeneraleDefault.accidentTravail)" type="text" size="small"> Ajouter une ligne</el-button>
		<br/>
	</div>
</template>

<script>
import scriptQse from '../../../script/script-qse.js';
export default {
	data(){
		return {
			fieldsChecklistGeneraleAffichageObligatoire:[
				{key:"label", label:"Titre"},
				{key:"presence", label:"Présence"},
				{key:"commentaire", label:"Commentaire"},
				{key:"actions", label:""},
			],
			fieldsChecklistGeneraleDispositionApplicable:[
				{key:"label", label:"Titre"},
				{key:"presence", label:"Présence"},
				{key:"commentaire", label:"Commentaire"},
				{key:"actions", label:""},
			]
			,fieldsChecklistGeneraleLocauxEntreprise:[
				{key:"label", label:"Titre"},
				{key:"presence", label:"Présence"},
				{key:"commentaire", label:"Commentaire"},
				{key:"actions", label:""},
			]
			,fieldsChecklistGeneraleOrganisationSecurite:[
				{key:"label", label:"Titre"},
				{key:"presence", label:"Présence"},
				{key:"commentaire", label:"Commentaire"},
				{key:"actions", label:""},
			],
			fieldsChecklistGeneraleOrganisationSecours:[
				{key:"label", label:"Titre"},
				{key:"presence", label:"Présence"},
				{key:"commentaire", label:"Commentaire"},
				{key:"actions", label:""},
			],
			fieldsChecklistGeneraleAccidentTravail:[
				{key:"label", label:"Titre"},
				{key:"presence", label:"Présence"},
				{key:"commentaire", label:"Commentaire"},
				{key:"actions", label:""},
			]
		}
	},
	computed:{
		checklistGeneraleDefault(){
			return this.$store.state.checklistGeneraleDefault
		}
	},
	methods:{
		updateExtrafieldsUserQse: scriptQse.updateExtrafieldsUserQse,
		deleteRow(index, rows) {
			if(confirm("Voulez-vous supprimer cette entrée ?")){
				rows.splice(index, 1);
				this.$store.commit("updateChecklistGeneraleDefault", this.checklistGeneraleDefault)
				this.updateExtrafieldsUserQse()
			}
		},
		addRow(rows) {
			rows.push({
				'id': Date.now(),
				'label':'Saisir un titre', 
				'presence': false,
				'commentaire':'Saisir un commentaire'
			})
			this.$store.commit("updateChecklistGeneraleDefault", this.checklistGeneraleDefault)
			this.updateExtrafieldsUserQse()
		},
		onEdit (evt) {
			var initValue = evt.target.getAttribute('dataInit')
			var newValue = evt.target.innerText
			var editId = evt.target.getAttribute('editId')
			var editField = evt.target.getAttribute('editField')
			var editTable = evt.target.getAttribute('editTable')

			if (newValue !== initValue) {
				this.checklistGeneraleDefault[editTable][editId][editField] = newValue 
				this.$store.commit("updateChecklistGeneraleDefault", this.checklistGeneraleDefault)
				evt.target.setAttribute('dataInit', newValue)
				this.updateExtrafieldsUserQse()
			}
		},
		endEdit (evt) {
			evt.target.blur(evt)	
		},
		changePresence () {
			this.updateExtrafieldsUserQse()
		}
	}
}
</script>

<style>
.checklistGeneraleDefault{
	text-align: center;
}
</style>