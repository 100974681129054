<template>
	<div>
		<el-button @click.native.prevent="addUnite()" type="primary" size="small" round> Ajouter une unité</el-button>
		<p>!Attention! Aller dans l'onglet de l'unité ajoutée pour initialiser les valeurs pour le bilan</p>
		<!-- <el-button @click.native.prevent="reloadDefault()" type="primary" size="small" round> Réinitialiser les données</el-button> -->
		<br>
		<template v-if="unites.length">
			<b-tabs content-class="mt-3" active-nav-item-class="font-weight-bold">
				<b-tab class="outlineLess" ref="unite" lazy v-for="(unite) in unites" :key="unite.id" active>
					<template slot="title">
						{{ unite.nomUnite }}
						<el-button size="small" type="text" @click="closeTab(unite.id)">X</el-button>
					</template>
					<p><tabUnite :unite="unite" /></p>
				</b-tab>
			</b-tabs>
		</template>
		<template v-else class="centerCotation">
			<h4>Aucune unité</h4>
		</template>
	</div>
</template>

<script>
import scriptQse from '../../../script/script-qse.js';
import tabUnite from './TabUnite.vue'
export default {
	data () {
		return {
		}
	},
	components: {
		tabUnite
	},
	methods: {
		updateExtrafieldsQse: scriptQse.updateExtrafieldsQse,
		// resetData () {
		// 	var dataUnites = this.donnees.unites.filter(unite => (unite.id == this.unite.id))
		// 	dataUnites.forEach(dataUnite => {
		// 		this.familles.forEach(famille => {
		// 			var familleData = dataUnite.familles.filter(f => (f.id == famille.id))
		// 			if(!familleData.length){
		// 				var newDonneesFamilles = {
		// 				'id': famille.id,
		// 				'evalBrutFinale':'-', 
		// 				'evalResiduelFinale':'-'
		// 				}
		// 				dataUnite.familles.push(newDonneesFamilles)
		// 			}
		// 		})

		// 		this.risques.forEach(risque => {
		// 			var risqueData = dataUnite.risques.filter(r => (r.id == risque.id))
		// 			if(!risqueData.length){
		// 				var newDonneesRisques = {
		// 					'id': risque.id,
		// 					'presence':null, 
		// 					'ps':null, 
		// 					'g':null, 
		// 					'd':null, 
		// 					'risqueBrut':0, 
		// 					'risqueResiduel':0, 
		// 					'evalBrut':'-', 
		// 					'evalResiduel':'-',
		// 					'observation':'-'
		// 				}
		// 				dataUnite.risques.push(newDonneesRisques)
		// 			}
		// 		})
		// 	})
		// },
		reloadDefault(){
			if(confirm("Voulez-vous réinitialiser toute les données ?")){
				this.$store.commit('initializeDonnees', {unites:[]})
				this.updateExtrafieldsQse(this.$store.state.infoSoc.id)
			}
		},
		addUnite() {
			this.$store.commit("addUnite")
			this.updateExtrafieldsQse(this.$store.state.infoSoc.id)
		},
		closeTab(id) {
			if(confirm("Voulez-vous supprimer cette unité ?")){
				this.$store.commit("deleteUnite", id)
				this.updateExtrafieldsQse(this.$store.state.infoSoc.id)
			}
		},
	},
	mounted: function () {
	},
	computed: {
		unites () {
			return this.$store.state.unites
		},
		familles () {
			return this.$store.state.famillesRisques.familles
		},
		risques () {
			return this.$store.state.famillesRisques.risques
		}
	},
	watch: {
		
	}
}
</script>

<style>

</style>