import conf from '../config/api.js';
import Vue from 'vue';
import vuexStorage from '../store/index.js'
//import { object } from 'underscore';

var moment = require('moment');
//var htmlEncode = require('js-htmlencode').htmlEncode;
var htmlDecode = require('js-htmlencode').htmlDecode;

//Fonction génération de toaster erreur
function toasterError(content, title){
  new Vue().$bvToast.toast(content, {
    title: title,
    toaster: 'b-toaster-bottom-right',
    variant: 'danger',
    autoHideDelay: 2500,
    appendToast: true,
    solid: true
  })
}

//Fonction d'activation de template
function activerTemplate(id,active){
  if(id!=null){
    if(active){
      active=0;
    }else{
      active=1;
    }
    this.axios.post(conf.server+"/api/activeTemplate",{id:id, active:active} ,{withCredentials:true})
      .then(() => {
        for(var i=0;i<this.templates.length;i++){
          if(this.templates[i].value==id){
            this.templates[i].active=active;
          }
        }
      })
      .catch((error) => {
        if(error.response != undefined){
          toasterError(`Activation de template impossible`,'Activation de template')
          if(error.response.data.route!=null){
            this.$router.push(error.response.data.route);
          }
        }else{
          toasterError(`Le service est momentanément indisponible`,'Service indisponible')
        }
      })
  }else{
    toasterError(`Le template ne peut être activé`,'Activation de template')
  }
}

//Fonction qui permet d'ajouter la class active au menu profil du client
function activeClassMenuProfil(){
  var url = window.location.pathname;
  var classActive = document.getElementById("navigation");
  for(var i=0;i<classActive.children.length;i++){
    classActive.children[i].children[0].classList.remove("active");
    classActive.children[i].children[0].classList.remove("router-link-exact-active");
    classActive.children[i].children[0].classList.remove("router-link-active");

    var path = classActive.children[i].children[0].getAttribute("href");
    if(path==url){
      classActive.children[i].children[0].classList.add("active");
      classActive.children[i].children[0].classList.add("router-link-exact-active");
      classActive.children[i].children[0].classList.add("router-link-active");
    }
  }
}

//Fonction d'ajout d'un contact
function addContact (formData) {
  this.axios.post(conf.server+'/api/addContact', formData, { withCredentials: true })
    .then(() => {
      this.$bvToast.toast(`Le contact a été ajouté`, {
        title: 'Ajout d\'un contact',
        toaster: 'b-toaster-bottom-right',
        variant: 'success',
        autoHideDelay: 2500,
        appendToast: true,
        solid: true
      })
    })
    .catch((error) => {
      if(error.response != undefined){
        toasterError(`Ajout de contact impossible`,'Ajout de contact')
      }else{
        toasterError(`Le service est momentanément indisponible`,'Service indisponible')
        this.$router.push('login')
      }
    })
}

//Fonction d'ajout en masse de compte
function addMassiveContact(){
  for(var i=0;i<this.selectedContact.length;i++){
    iterateMassiveContact.call(this,this.selectedContact[i],this.templateSelected);
  }
}

//Fonction d'ajout de produit à un devis
function addProduct (item){
  if(this.selectedTypeLine == 2){
    var find = false
    for(var i=0; i< this.products.length; i++){
      if(item.id == this.products[i].id){
        this.products[i].qty = parseInt(item.qty) + parseInt(this.products[i].qty)
        this.products[i].totalHt = Number.parseFloat(this.products[i].qty*item.prixUnitaire).toFixed(2)
        find = true
      }
    }
    if(!find){
      this.products.push({id: item.id, ref: item.ref, label: item.label, description: item.description, tva: Number.parseFloat(item.tva).toFixed(2), prixUnitaire: item.prixUnitaire, qty: item.qty, reduction: item.reduction, totalHt: Number.parseFloat(item.qty*item.prixUnitaire).toFixed(2), productType: item.type, start: this.limitedService.start, finish: this.limitedService.finish})
    }
  }else{
    if(this.selectedProductType == 1){
      if(this.limitedService.start>this.limitedService.finish){
        this.$bvToast.toast(`La date début doit être supérieure à la date de fin du service`, {
          title: 'Service à durée limitée',
          toaster: 'b-toaster-bottom-right',
          variant: 'danger',
          autoHideDelay: 2500,
          appendToast: true,
          solid: true
        })
      }else{
        this.products.push({id: item.id, label: item.label, tva: Number.parseFloat(item.tva).toFixed(2), prixUnitaire: item.prixUnitaire, qty: item.qty, reduction: item.reduction, totalHt: Number.parseFloat(item.qty*item.prixUnitaire).toFixed(2), productType: this.selectedProductType, start: this.limitedService.start, finish: this.limitedService.finish})
      }
    }else{
      this.products.push({id: item.id, label: item.label, tva: Number.parseFloat(item.tva).toFixed(2), prixUnitaire: item.prixUnitaire, qty: item.qty, reduction: item.reduction, totalHt: Number.parseFloat(item.qty*item.prixUnitaire).toFixed(2), productType: this.selectedProductType})
    }
  }
  this.deleteProduct()
}

//Fonction de modification des infos perso du client
function changeInfoPerso(infoClients, infoPerso){
  var key = Object.keys(infoPerso);
  var changeInfo = [];
  var j = 0;
  for(var i=0;i<key.length;i++){
    if((infoClients[key[i]] != infoPerso[key[i]])&&(key[i]!="array_options")){
      changeInfo[j] = new Array(2);
      changeInfo[j][0] = key[i];
      changeInfo[j][1] = infoPerso[key[i]];
      j++;
    }
  }
  
  if(changeInfo){
    this.axios.post(conf.server+"/api/changeInfoPerso",changeInfo,{withCredentials:true})
    .then((response) => {
      alert(response.data);
    })
    .catch((error) => {
      if(error.response != undefined){
        this.$bvToast.toast(`Changement d'information impossible`, {
          title: 'Changement d\'informations',
          toaster: 'b-toaster-bottom-right',
          variant: 'danger',
          autoHideDelay: 2500,
          appendToast: true,
          solid: true
        })
        if(error.response.data.route!=null){
          this.$router.push(error.response.data.route);
        }
      }else{
        toasterError(`Le service est momentanément indisponible`,'Service indisponible')
      }
    })
  }
}

//Fonction envoi mail changement de mot de passe
function changePassOublie(){
  this.axios.post(conf.server+"/api/createToken",{email:this.loginOublie},{withCredentials:true})
  .then(() => {
    this.$bvToast.toast(`Un lien vient de vous être envoyé dans votre boîte mail`, {
      title: 'Changement de mot de passe',
      toaster: 'b-toaster-bottom-right',
      variant: 'success',
      autoHideDelay: 2500,
      appendToast: true,
      solid: true
    })
    this.$router.push('login');
  })
  .catch((error)=>{
    if(error.response != undefined){
      toasterError(`Changement de mot de passe impossible`,'Changement de mot de passe')
    }else{
      toasterError(`Le service est momentanément indisponible`,'Service indisponible')
      this.$router.push('login');
    }
  })
}

//Fonction de changement de mot de passe 
function changePassword(form){
  if(form.newPwd == form.newPwd2){
    this.axios.post(conf.server+"/api/changePassword",form ,{withCredentials:true})
    .then(() => {
      alert("Votre mot de passe à été changé vous allez être redirigé vers la page de login")
      this.axios.post(conf.server+"/api/disconnect",{},{withCredentials:true})
      .then((response) => {
        this.$router.push(response.data.route);
      })
      .catch(() => {
        this.$router.push('login');
      })
    })
    .catch((error) => {
    if(error.response != undefined){
      toasterError(error.response,'Changement de mot de passe')
    }else{
      toasterError(`Le service est momentanément indisponible`,'Service indisponible')
    }
    })
  }else{
    toasterError(`Les mots de passe ne correspondent pas`,'Changement de mot de passe')
  }
}

//Changement de la vitesse du carrousel
function changeSpeed(){
  this.axios.post(conf.server+"/api/changeConfCarrousel",{vitesse: this.confCarrousel.vitesse},{withCredentials:true})
  .then(() => {
    alert("Vitesse du carrousel changée");
  })
  .catch((error) => {
    if(error.response != undefined){
      toasterError(`Changement de vitesse du carrousel impossible`,'Changement de vitesse')
      if(error.response.data.route!=null){
        this.$router.push(error.response.data.route);
      }
    }else{
      toasterError(`Le service est momentanément indisponible`,'Service indisponible')
    }
  })
}

//Fonction de choix de produit
function chooseProduct (item) {
  this.axios.get(conf.server+"/api/getProduct/"+item.value,{withCredentials:true})
  .then((response) => {
    var resProduct = response.data
    this.product[0].id = resProduct.id
    this.product[0].ref = resProduct.ref
    this.product[0].description = resProduct.description
    this.product[0].label = resProduct.label
    this.product[0].tva = resProduct.tva
    this.product[0].prixUnitaire = Number.parseFloat(resProduct.price).toFixed(2)
    this.product[0].qty = '1'
    this.product[0].reduction = ""
    this.product[0].type = resProduct.type
  })
  .catch((error) => {
    if(error.response != undefined){
      toasterError(`Le produit est inexistant`,'Recherche de produit')
    }else{
      toasterError(`Le service est momentanément indisponible`,'Service indisponible')
    }
  })
}

//Fonction de commentaire sur le document client
function commentDoc(){
  //console.log(doc);
}

//Fonction de connexion sur un compte client un compte admin
function connectOnClient(clientId,socId){
  var connected = confirm("Voulez-vous vous connecter au compte de ce client?");
  if(connected){
    this.axios.post(conf.server+"/api/connectAdminClient",{clientId: clientId, socid: socId},{withCredentials:true})
    .then((response) => {
      this.$router.push(response.data.route);
    })
    .catch((error) => {
      if(error.response != undefined){
        toasterError(`Connexion au compte client impossible`,'Connexion compte client')
      }else{
        toasterError(`Le service est momentanément indisponible`,'Service indisponible')
        this.$router.push('login');
      }  
    })
  }
}

//Fonction de création d'accès client
function createAccess(email,contenuMail,pwd,tokenReinit){
  this.axios.post(conf.server+"/api/createAccess",{email: email, contenuMail: contenuMail, pwd: pwd, tokenReinit:tokenReinit},{withCredentials:true})
  .then((response) => {
    alert("Accès créée avec succès!");
    this.loadingClient();
    this.expandClientInfo(response.data.socid);
  })
  .catch((error) => {
    if(error.response != undefined){
      toasterError(`Création d'accès impossible`,'Création d\'accès')
      if(error.response.data.route!=null){
        this.$router.push(error.response.data.route);
      }
    }else{
      toasterError(`Le service est momentanément indisponible`,'Service indisponible')
      this.$router.push('login');
    }
  })
}

//fonction de création de propale
function createPropal(){
  this.axios.post(conf.server+"/api/createPropal",{form: this.form, products: this.products},{withCredentials:true})
    .then(() => {
      this.$bvToast.toast(`Création de proposition commerciale réussie`, {
        title: 'Création de proposition commerciale',
        toaster: 'b-toaster-bottom-right',
        variant: 'success',
        autoHideDelay: 2500,
        appendToast: true,
        solid: true
      })
      this.$router.push('login')
    })
    .catch((error) => {
    if(error.response != undefined){
      toasterError(`Impossible de créer la proposition commerciale`,'Création de proposition commerciale')
    }else{
      toasterError(`Le service est momentanément indisponible`,'Service indisponible')
    }
    })
}

//Fonction de suppression d'un contact
function delContact (id) {
  this.axios.post(conf.server+'/api/delContact',{id:id}, { withCredentials: true })
    .then(() => {
      this.$bvToast.toast(`Le contact a été supprimé`, {
        title: 'Suppression d\'un contact',
        toaster: 'b-toaster-bottom-right',
        variant: 'success',
        autoHideDelay: 2500,
        appendToast: true,
        solid: true
      })
    })
    .catch((error) => {
    if(error.response != undefined){
      toasterError(`Suppression de contact impossible`,'Suppression de contact')
    }else{
      toasterError(`Le service est momentanément indisponible`,'Service indisponible')
    }
    })
}

//Fonction de suppression d'accès client
function deleteAccess(contactid,socid){
  var deleteExtranet = confirm("Etes-vous sûr de vouloir supprimer l'accès à l'extranet de ce client?");
  if(deleteExtranet == true){
    this.axios.post(conf.server+"/api/deleteAccess",{id: contactid},{withCredentials:true})
    .then(() => {
      this.expandClientInfo(socid);
    })
    .catch((error) => {
      if(error.response != undefined){
        toasterError(`Suppression d'accès impossible`,'Suppression d\'accès')
        if(error.response.data.route!=null){
          this.$router.push(error.response.data.route);
        }
      }else{
        toasterError(`Le service est momentanément indisponible`,'Service indisponible')
        this.$router.push('login');
      }
    })
  }
}

//Suppression d'une image du carrousel
function deleteImg(chemin){
  this.axios.post(conf.server+"/api/deleteImg",{chemin: chemin},{withCredentials:true})
  .then(() => {
    window.location.reload();
  })
  .catch((error) => {
    if(error.response != undefined){
      toasterError(`Impossible de supprimer l'image`,'CSuppression d\'image')
      if(error.response.data.route!=null){
        this.$router.push(error.response.data.route);
      }
    }else{
      toasterError(`Le service est momentanément indisponible`,'Service indisponible')
    }
  })
}

//Fonction de suppression de produit à ajouter
function deleteProduct () {
  this.product[0].id = ''
  this.product[0].ref = ''
  this.product[0].description =  ''
  this.product[0].label =  ''
  this.product[0].tva = '20.000'
  this.product[0].prixUnitaire = ''
  this.product[0].qty = '1'
  this.product[0].reduction = ''
  this.productSearch = ''
  this.limitedService.start = ''
  this.limitedService.finish = ''
}

//Fonction de suppression de produit devis
function deleteProducts (item) {
  this.products.splice(item.index,1)
}

//Fonction de suppression de propale
function deletePropale(id){
  var deleteDevis = confirm("Etes-vous sûr de vouloir supprimer ce devis?");
  if(deleteDevis == true){
    this.axios.post(conf.server+"/api/deletePropale",{id: id}, {withCredentials: true})
      .then(() => {
        this.$bvToast.toast(`Suppression de devis réussie`, {
          title: 'Suppression de devis',
          toaster: 'b-toaster-bottom-right',
          variant: 'success',
          autoHideDelay: 2500,
          appendToast: true,
          solid: true
        })
        this.proposals = []
        this.loadDocuments('proposals')
      })
      .catch(() => {
        toasterError(`Suppression de devis échouée`,'Suppression de devis')
      })
  }
}

//Fonction de suppression de tiers
function deleteTiers(id){
  var deleteClient = confirm("Voulez-vous supprimer le tiers?");
  if(deleteClient == true){
    this.axios.post(conf.server+"/api/deleteTiers",{id:id},{withCredentials:true})
    .then((response) => {
      if(response.data!=0){
        this.$bvToast.toast(`Le tiers à été supprimé avec succès`, {
          title: 'Suppression de tiers',
          toaster: 'b-toaster-bottom-right',
          variant: 'success',
          autoHideDelay: 2500,
          appendToast: true,
          solid: true
        })
        this.loadingClient();
      }else{
        this.$bvToast.toast(`Le tiers ne peut pas être supprimé car il y a un document en cours d'édition`, {
          title: 'Suppression de tiers',
          toaster: 'b-toaster-bottom-right',
          variant: 'danger',
          autoHideDelay: 2500,
          appendToast: true,
          solid: true
        })
        this.loadingClient();
      }
    })
    .catch(()=>{
      this.$bvToast.toast(`Suppression impossible`, {
        title: 'Suppression de tiers',
        toaster: 'b-toaster-bottom-right',
        variant: 'danger',
        autoHideDelay: 2500,
        appendToast: true,
        solid: true
      })
    })
  }
}

//Fonction de chargement des infos des clients
async function detailsTiers (id, that) {
  if(!that){
    that = this
  }
  try{
    let response = await requestApi({method: 'GET', url: '/api/detailsTiers', params: {id: id}, form: {}, headers: {'Content-Type': 'application/json'}})
    vuexStorage.commit('updateInfoSoc', response)
    var arrayOptions = response.array_options
    if(Object.keys(arrayOptions).length){
      if(arrayOptions.options_cotation){
        var cotation = JSON.parse(arrayOptions.options_cotation)
        vuexStorage.commit('updateCotation', cotation)
      }
      else {
        vuexStorage.commit('updateCotation', Object.assign({}, vuexStorage.state.cotationDefault))
      }

      if(arrayOptions.options_familles_risques){
        var famillesRisques = JSON.parse(arrayOptions.options_familles_risques)
        vuexStorage.commit('initializeFamillesRisques', famillesRisques)
      }
      else {
        vuexStorage.commit('initializeFamillesRisques', Object.assign({}, vuexStorage.state.famillesRisquesDefault))
      }

      if(arrayOptions.options_unite){
        var unite = JSON.parse(arrayOptions.options_unite)
        vuexStorage.commit('initializeUnites', unite)
      }
      else {
        vuexStorage.commit('initializeUnites', [])
      }
      
      if(arrayOptions.options_situations){
        var situations = JSON.parse(arrayOptions.options_situations)
        vuexStorage.commit('initializeSituations', situations)
      }
      else {
        vuexStorage.commit('initializeSituations', [])
      }
      
      if(arrayOptions.options_donnees){
        var donnees = JSON.parse(arrayOptions.options_donnees)
        vuexStorage.commit('initializeDonnees', donnees)
      }
      else {
        vuexStorage.commit('initializeDonnees', {unites:[]})
      }

      if(arrayOptions.options_auto_increment_unite){
        vuexStorage.commit('updateAutoIncrementUnite', arrayOptions.options_auto_increment_unite)
      }
      else {
        vuexStorage.commit('updateAutoIncrementUnite', 0)
      }
      
      if(arrayOptions.options_auto_increment_situation){
        vuexStorage.commit('updateAutoIncrementSituation', arrayOptions.options_auto_increment_situation)
      }
      else {
        vuexStorage.commit('updateAutoIncrementSituation', 0)
      }
      
      if(arrayOptions.options_auto_increment_penibilite){
        vuexStorage.commit('updateAutoIncrementPenibilite', arrayOptions.options_auto_increment_penibilite)
      }
      else {
        vuexStorage.commit('updateAutoIncrementPenibilite', 0)
      }
      
      if(arrayOptions.options_auto_increment_famille){
        vuexStorage.commit('updateAutoIncrementFamille', arrayOptions.options_auto_increment_famille)
      }
      else {
        vuexStorage.commit('updateAutoIncrementFamille', vuexStorage.state.autoIncrementFamilleDefault)
      }

      if(arrayOptions.options_auto_increment_risque){
        vuexStorage.commit('updateAutoIncrementRisque', arrayOptions.options_auto_increment_risque)
      }
      else {
        vuexStorage.commit('updateAutoIncrementRisque', vuexStorage.state.autoIncrementRisqueDefault)
      }
      
      if(arrayOptions.options_date_maj){
        vuexStorage.commit('updateDateMaj', arrayOptions.options_date_maj)
      }
      else {
        vuexStorage.commit('updateDateMaj', null)
      }
      
      if(arrayOptions.options_date_creation){
        vuexStorage.commit('updateDateCreation', arrayOptions.options_date_creation)
      }
      else {
        vuexStorage.commit('updateDateCreation', null)
      }
      
      if(arrayOptions.options_infos_generales){
        var infosGenerales = JSON.parse(arrayOptions.options_infos_generales)
        vuexStorage.commit('initializeInfosGenerales', infosGenerales)
      }
      else {
        let infosGenerales = {
          'activite':'Saisir l\'activité de l\'entreprise',
          'effectifETP':'Non défini',
          'effectifHomme':'Non défini',
          'effectifFemme':'Non défini',
          'cddIntérimaires':'Non défini',
          'horrairesDeTravail':'Non défini',
          'tempsDeTravail':'Non défini',
          'conventionCollective':'Non défini',
          'cse':'Non défini',
          'cssct':'Non défini',
          'protectionIndividuel':'Non défini',
          'caractLocaux':'Non défini',
          'hygiene':'Non défini',
          'docConsulte':'Non défini',
          'accidentDeTravail':'Non défini',
          'maladiePro':'Non défini'
        }
        vuexStorage.commit('initializeInfosGenerales', infosGenerales)
      }

      if(arrayOptions.options_auto_increment_checklist){
        vuexStorage.commit('updateAutoIncrementChecklist', arrayOptions.options_auto_increment_checklist)
      }
      else {
        vuexStorage.commit('updateAutoIncrementChecklist', 0)
      }

      if(arrayOptions.options_auto_increment_prog_action){
        vuexStorage.commit('updateAutoIncrementProgAction', arrayOptions.options_auto_increment_prog_action)
      }
      else {
        vuexStorage.commit('updateAutoIncrementProgAction', 0)
      }

      if(arrayOptions.options_checklist_generale){
        vuexStorage.commit('updateChecklistGenerale', JSON.parse(arrayOptions.options_checklist_generale))
      }
      else {
        let checklistGenerale = {
          affichageObligatoire:[],
          dispositionApplicable:[],
          locauxEntreprise:[],
          organisationGestionSecurite:[],
          organisationSecours:[],
          accidentTravail:[]
        }
        vuexStorage.commit('updateChecklistGenerale', checklistGenerale)
      }

      if(arrayOptions.options_annexe_covid){
        vuexStorage.commit('updateAnnexeCovid', JSON.parse(arrayOptions.options_annexe_covid))
      }
      else {
        let annexeCovid = {
          travailRisque:{
            salarie:{
              bref:{
                unite:"0",
                salarie:"0"
              },
              rapproche:{
                unite:"0",
                salarie:"0"
              }
            },
            public:{
              bref:{
                unite:"0",
                salarie:"0"
              },
              rapproche:{
                unite:"0",
                salarie:"0"
              }
            }
          },
          preventionOrga:{
            mesuresGenerales:"-",
            modification:"-"
          },
          preventionTechnique:{
            protectionCollective:"-",
            protectionIndividuelle:"-",
            modification:"-"
          },
          casContamination:{
            procedure:"-"
          },
          informationInterne:{
            modalite:"-"
          },
          identificationPersonne:{
            presencePersonneRisque:[],
            autrePersonneRisque:[]
          }
        }
        vuexStorage.commit('updateAnnexeCovid', annexeCovid)
      }

      if(arrayOptions.options_prog_action){
        vuexStorage.commit('initializeProgAction', JSON.parse(arrayOptions.options_prog_action))
      }
      else {
        vuexStorage.commit('initializeProgAction', [])
      }

    } else {
      vuexStorage.commit('updateCotation', Object.assign({}, vuexStorage.state.cotationDefault))
      vuexStorage.commit('initializeFamillesRisques', Object.assign({}, vuexStorage.state.famillesRisquesDefault))
      vuexStorage.commit('updateAutoIncrementRisque', vuexStorage.state.autoIncrementRisqueDefault)
      vuexStorage.commit('updateAutoIncrementFamille', vuexStorage.state.autoIncrementFamilleDefault)
      vuexStorage.commit('initializeDonnees', {unites:[]})
      vuexStorage.commit('updateUnite', [])
      let infosGenerales = {
        'activite':'Saisir l\'activité de l\'entreprise',
        'effectifETP':'Non défini',
        'effectifHomme':'Non défini',
        'effectifFemme':'Non défini',
        'cddIntérimaires':'Non défini',
        'horrairesDeTravail':'Non défini',
        'tempsDeTravail':'Non défini',
        'conventionCollective':'Non défini',
        'cse':'Non défini',
        'cssct':'Non défini',
        'protectionIndividuel':'Non défini',
        'caractLocaux':'Non défini',
        'hygiene':'Non défini',
        'docConsulte':'Non défini',
        'accidentDeTravail':'Non défini',
        'maladiePro':'Non défini'
      }
      vuexStorage.commit('initializeInfosGenerales', infosGenerales)
      vuexStorage.commit('updateDateMaj', null)
      vuexStorage.commit('updateDateCreation', null)
      vuexStorage.commit('updateAutoIncrementPenibilite', 0)
      vuexStorage.commit('updateAutoIncrementSituation', 0)
      vuexStorage.commit('updateAutoIncrementChecklist', 0)
      vuexStorage.commit('updateAutoIncrementProgAction', 0)
      let checklistGenerale = {
        affichageObligatoire:[],
        dispositionApplicable:[],
        locauxEntreprise:[],
        organisationGestionSecurite:[],
        organisationSecours:[],
        accidentTravail:[]
      }
      vuexStorage.commit('updateChecklistGenerale', checklistGenerale)
      let annexeCovid = {
        travailRisque:{
          salarie:{
            bref:{
              unite:"0",
              salarie:"0"
            },
            rapproche:{
              unite:"0",
              salarie:"0"
            }
          },
          public:{
            bref:{
              unite:"0",
              salarie:"0"
            },
            rapproche:{
              unite:"0",
              salarie:"0"
            }
          }
        },
        preventionOrga:{
          mesuresGenerales:"-",
          modification:"-"
        },
        preventionTechnique:{
          protectionCollective:"-",
          protectionIndividuelle:"-",
          modification:"-"
        },
        casContamination:{
          procedure:"-"
        },
        informationInterne:{
          modalite:"-"
        },
        identificationPersonne:{
          presencePersonneRisque:[],
          autrePersonneRisque:[]
        }
      }
      vuexStorage.commit('initializeAnnexeCovid', annexeCovid)
      vuexStorage.commit('initializeProgAction', [])
      vuexStorage.commit('initializeSituations', [])
    }
  }catch(error){
    if(error.response != undefined){
      toasterError(`Impossible de charger les détails du tiers`,'Détails du tiers')
      if(error.response.status!=404){
        that.$router.push('/');
      }else{
		    that.$router.push('/');
      }
    }else{
      toasterError(`Le service est momentanément indisponible`,'Service indisponible')
      that.$router.push('../tiers');
    }
  }
}

//Fonction de déconnexion
function disconnect() {
  var disconnected = confirm("Voulez-vous vous déconnecter?");
  if(disconnected == true){
    this.axios.post(conf.server+"/api/disconnect",{},{withCredentials:true})
    .then(() => {
      this.$router.push('/');
    })
    .catch(() => {
      this.$router.push('/');
    })
  }
}

//Fonction de chargement des infos d'accès du client
function expandClientInfo(id){
  this.axios.get(conf.server+"/api/verifyAccess/" + id,{withCredentials:true})
  .then((response) => {
    this.clientsExtranet = response.data;
    this.axios.post(conf.server+"/api/loadingContacts",{id: id},{withCredentials:true})
    .then((response) => {
      this.infosContacts = response.data;
      for(var j=0; j<this.infosContacts.length; j++){
        this.infosContacts[j]["acces"]=false;
      }
      for(var i=0; i<this.clientsExtranet.length; i++){
        for(j=0; j<this.infosContacts.length; j++){
          if(this.infosContacts[j]["id"]==this.clientsExtranet[i]["contactid"]){
            this.infosContacts[j]["acces"]=true;
            var dateC = new Date(this.clientsExtranet[i]["datec"]*1000);
            var jour = dateC.getDate();
            if(jour < 10){
              jour = '0' + jour;
            }
            var mois = dateC.getMonth()+1;
            if (mois < 10) { 
              mois = '0' + mois; 
            }
            var annee = dateC.getFullYear();
            this.infosContacts[j]["datec"]=annee+"-"+mois+"-"+jour;
          }
        }
      }
    })
    .catch((error) => {
      if(error.response != undefined){
        toasterError(`Chargement des contacts impossible`,'Chargement des contacts')
        if(error.response.data.route!=null){
          this.$router.push(error.response.data.route);
        }
      }else{
        toasterError(`Le service est momentanément indisponible`,'Service indisponible')
      }
    })
  })
  .catch((error) => {
    this.axios.post(conf.server+"/api/loadingContacts",{id: id},{withCredentials:true})
    .then((response) => {
      this.infosContacts = response.data;
    })
    .catch((error2) => {
      if(error2.response != undefined){
        toasterError(`Chargement des contacts impossible`,'Chargement des contacts')
      }else{
        toasterError(`Le service est momentanément indisponible`,'Service indisponible')
      }
    })
    if(error.response != undefined){
      toasterError(`Impossible d'effectuer la vérification`,'Verification')
      if(error.response.data.route!=null){
        this.$router.push(error.response.data.route);
      }
    }else{
      toasterError(`Le service est momentanément indisponible`,'Service indisponible')
    }
  })
}

//Fonction de chargement de la discussion du ticket client
function expandTicketInfo(row){
  this.currentTrack=row.track_id;
  this.titleCurrentTicket=row.ref;
  this.axios.get(conf.server+"/api/loadMessage/"+row.track_id,{withCredentials:true})
  .then((response) => {
    document.getElementById("infoTicket").style.display="initial";
    this.messageInitial=htmlDecode(row.message);
    this.ticketMessages=response.data;
    for(var i=0;i<response.data.length;i++){
      this.ticketMessages[i].message=htmlDecode(this.ticketMessages[i].message);
    }
  })
  .catch((error) => {
    if(error.response != undefined){
      toasterError(`Impossible de charger les messages du ticket`,'Chargement des messages')
      if(error.response.data.route!=null){
        this.$router.push(error.response.data.route);
      }
    }else{
      toasterError(`Le service est momentanément indisponible`,'Service indisponible')
    }
  })
}

//Fonction de chargement de la discussion du ticket admin
function expandTicketInfoAdmin(row){
  this.currentTrack=row.track_id;
  this.titleCurrentTicket=row.ref;
  this.axios.get(conf.server+"/api/loadMessageAdmin/"+row.track_id,{withCredentials:true})
  .then((response) => {
    document.getElementById("infoTicket").style.display="initial";
    this.messageInitial=htmlDecode(row.message);
    this.ticketMessages=response.data;
    for(var i=0;i<response.data.length;i++){
      this.ticketMessages[i].message=htmlDecode(this.ticketMessages[i].message);
    }
  })
  .catch((error) => {
    if(error.response != undefined){
      toasterError(`Impossible de charger les messages du ticket`,'Chargement des messages')
      if(error.response.data.route!=null){
        this.$router.push(error.response.data.route);
      }
    }else{
      toasterError(`Le service est momentanément indisponible`,'Service indisponible')
    }
  })
}

//Filtrer les tickets
function filterTickets(){
  var statut = this.selectedStatut;
  var severite = this.selectedSeverite;
  var type = this.selectedType;

  this.axios.get(conf.server+"/api/filterTickets",{params:{type: type,severite: severite,statut: statut}},{withCredentials:true})
  .then((response) => {
    this.tickets=response.data;
    var statut = { "0": "Non lu","1": "Lu","3": "Répondu","4": "Assigné","5": "En cours","6": "En attente","8": "Cloturé","9": "Supprimé"};
    for(var i=0;i<response.data.length;i++){
      this.tickets[i].datec = moment(this.tickets[i].datec*1000).format("DD-MM-YYYY");
      this.tickets[i].fk_statut = statut[this.tickets[i].fk_statut];
    }
    this.totalRows=response.data.length;
  })
  .catch((error) => {
    if(error.response != undefined){
      toasterError(`Aucun ticket disponible avec ces filtres`,'Filtre ticket')
      if(error.response.data.route!=null){
        this.$router.push(error.response.data.route);
      }
    }else{
      toasterError(`Le service est momentanément indisponible`,'Service indisponible')
    }
  })
}

//Filtrer les tickets client
function filterTicketsClient(){
  var statut = this.selectedStatut;
  var severite = this.selectedSeverite;
  var type = this.selectedType;

  this.axios.get(conf.server+"/api/filterTicketsClient",{params:{type: type,severite: severite,statut: statut}},{withCredentials:true})
  .then((response) => {
    this.tickets=response.data;
    var statut = { "0": "Non lu","1": "Lu","3": "Répondu","4": "Assigné","5": "En cours","6": "En attente","8": "Cloturé","9": "Supprimé"};
    for(var i=0;i<response.data.length;i++){
      this.tickets[i].datec = moment(this.tickets[i].datec*1000).format("DD-MM-YYYY");
      this.tickets[i].fk_statut = statut[this.tickets[i].fk_statut];
    }
    this.totalRows=response.data.length;
  })
  .catch((error) => {
    if(error.response != undefined){
      toasterError(`Aucun ticket disponible avec ces filtres`,'Filtre ticket')
      if(error.response.data.route!=null){
        this.$router.push(error.response.data.route);
      }
    }else{
      toasterError(`Le service est momentanément indisponible`,'Service indisponible')
    }
  })
}

//Fonction de récupération des infos perso du client
function getInfoClient(){
  this.axios.get(conf.server+"/api/getInfoPersoClient",{withCredentials:true})
    .then((response) => {
      this.infoClients = response.data;
      this.infoPerso = JSON.parse(JSON.stringify(this.infoClients));
    })
    .catch((error) => {
      if(error.response != undefined){
        toasterError(`Impossible de charger les informations client`,'Chargement des infos')
        if(error.response.data.route!=null){
          this.$router.push(error.response.data.route);
        }
      }else{
        toasterError(`Le service est momentanément indisponible`,'Service indisponible')
      }
    })
}

//Fonction de récupération des infos de la société du client
function getInfoSocClient(){
  this.axios.get(conf.server+"/api/getInfoSocClient",{withCredentials:true})
  .then((response) => {
    this.infoSocClient = response.data;
  })
  .catch((error) => {
    if(error.response != undefined){
      toasterError(`Impossible de charger les informations de la société`,'chargement des infos de société')
      if(error.response.data.route!=null){
        this.$router.push(error.response.data.route);
      }
    }else{
      toasterError(`Le service est momentanément indisponible`,'Service indisponible')
    }
  })
}

//Fonction de génération des infos à envoyer au client  
function info(row,target) {
  this.form.email=row.email;
  this.modalInfo.title = 'Contact: '+row.firstname+' '+row.lastname;
  this.axios.post(conf.server+"/api/passwordGenerator",{email: row.email},{withCredentials:true})
  .then((response) => {
    this.pwd = response.data.pwd;
    this.tokenReinit = response.data.tokenReinit;
    this.loadingTemplateMail(this.templateSelected,this.form.email,this.pwd,this.tokenReinit);
  })
  .catch((error) => {
    if(error.response != undefined){
      if(error.response.data.route!=null){
        this.$router.push(error.response.data.route);
      }
    }else{
      toasterError(`Le service est momentanément indisponible`,'Service indisponible')
    }
  })
  this.$root.$emit('bv::show::modal', 'modalInfo',target);
}

//Fonction de vérification de connexion pour la page login
//Si l'utilisateur est connecté il est redirigé vers la page index
function isConnect(){
  this.axios.post(conf.server+"/api/isConnect",{},{withCredentials:true})
  .then((response) => {
    if(response.status==200){
      this.$router.push(response.data.route);
    }else{
      this.$router.push('login');
    }
  })
  .catch(() => {
    this.$router.push('login');
  })
}

//Fonction pour itérer massive contact
function iterateMassiveContact(user,template){
  var email = user.email;
  var nom = user.lastname;
  var prenom = user.firstname;
  this.axios.post(conf.server+"/api/passwordGenerator",{email:email},{withCredentials:true})
  .then((response) => {
    var pwd = response.data.pwd;
    var tokenReinit = response.data.tokenReinit;
    this.axios.post(conf.server+"/api/getInfoClient",{email:email},{withCredentials:true})
    .then((response2) => {
      var ref = response2.data.ref_entreprise;
      var nomEntreprise = response2.data.nom_entreprise;
      this.axios.post(conf.server+"/api/template",{template:template, email:email, pwd: pwd, tokenReinit:tokenReinit, prenom:prenom, nom:nom, ref:ref, nomEntreprise:nomEntreprise},{withCredentials:true})
      .then((response3) => {
        this.axios.post(conf.server+"/api/createAccess",{email:email, pwd:pwd, tokenReinit:tokenReinit, contenuMail:response3.data},{withCredentials:true})
        .then(() => {
          this.$refs.modalAjoutExtranet.hide();
        })
        .catch((error4)=>{
          if(error4.response != undefined){
            toasterError(`Impossible de créer l'accès à l'extranet`,'Création d\'accès')
          }else{
            toasterError(`Le service est momentanément indisponible`,'Service indisponible')
          }      
        })
      })
      .catch((error3)=>{
      if(error3.response != undefined){
        toasterError(`Impossible de charger le contenu du mail`,'Chargement du contenu du mail')
      }else{
        toasterError(`Le service est momentanément indisponible`,'Service indisponible')
      }
      })
    })
    .catch((error2)=>{
      if(error2.response != undefined){
        toasterError(`Impossible de charger les infos du client`,'Chargement des infos')
      }else{
        toasterError(`Le service est momentanément indisponible`,'Service indisponible')
      }
    })
  })
  .catch((error)=>{
    if(error.response != undefined){
      toasterError(`Impossible de générer les mot de passe aléatoire`,'Génération de mot de passe')
    }else{
      toasterError(`Le service est momentanément indisponible`,'Service indisponible')
    }
  })
}

//Fonction de chargement de tous les clients
function loadingAllClient(){
  this.axios.get(conf.server+"/api/verifyAccess/" + this.$route.params.id,{withCredentials:true})
  .then((response) => {
    this.clientsExtranet = response.data;
    this.axios.post(conf.server+"/api/loadingAllClient",{sortfield: 't.nom'},{withCredentials:true})
    .then((response) => {
      this.tiers = response.data;
      this.numberOfPages = this.tiers.length / this.perPage;
      for(var j=0; j<this.tiers.length; j++){
          this.tiers[j]["acces"]=false;
          this.tiers[j]["_showDetails"]=false;
          this.optionsTiers.push({value: this.tiers[j].id, text: this.tiers[j].name})
      }
      for(var i=0; i<this.clientsExtranet.length; i++){
        for(j=0; j<this.tiers.length; j++){
          if(this.tiers[j]["id"]==this.clientsExtranet[i]["socid"]){
            this.tiers[j]["acces"]=true;
          }
        }
      }
    })
    .catch(()=>{
    })
  })
  .catch((error) => {
    this.axios.post(conf.server+"/api/loadingAllClient",{sortfield: 't.nom'},{withCredentials:true})
    .then((response) => {
      this.tiers = response.data;
      this.numberOfPages = this.tiers.length / this.perPage;
      for(var j=0; j<this.tiers.length; j++){
          this.tiers[j]["acces"]=false;
          this.optionsTiers.push({value: this.tiers[j].id, text: this.tiers[j].name})
      }
      for(var i=0; i<this.clientsExtranet.length; i++){
        for(j=0; j<this.tiers.length; j++){
          if(this.tiers[j]["id"]==this.clientsExtranet[i]["socid"]){
            this.tiers[j]["acces"]=true;
          }
        }
      }
    })
    .catch((error)=>{
      if(error.response != undefined){
        toasterError(`Impossible de charger les clients`,'Chargement des clients')
      }else{
        toasterError(`Le service est momentanément indisponible`,'Service indisponible')
      }
    })
    if(error.response != undefined){
      if(error.response.data.route!=null){
        this.$router.push(error.response.data.route);
      }
    }else{
      toasterError(`Le service est momentanément indisponible`,'Service indisponible')
    }
  })
}

//Ajouter un ordre pour les images!!!!!!!!!!!!!!!!
//Chargement des images du carrousel
function loadCarrousel(){
  this.axios.get(conf.server+"/api/imagesCarrousel",{withCredentials:true})
  .then((response) => {
    for(var i=0; i<response.data.length;i++){
      var tmp = {chemin_img:response.data[i].chemin_img}
      this.imgCarrousel.push(tmp);
    }
    this.totalRows=response.data.length;
  })
  .catch((error) => {
    if(error.response != undefined){
      if(error.response.data.route!=null){
        this.$router.push(error.response.data.route);
      }
    }else{
      toasterError(`Le service est momentanément indisponible`,'Service indisponible')
    }
  })
}

//Fonction de chargement des clients
function loadingClient(){
  if(this.$route.params.id){
    this.axios.get(conf.server+"/api/verifyAccess/" + this.$route.params.id,{withCredentials:true})
    .then((response) => {
      this.clientsExtranet = response.data;
      this.axios.post(conf.server+"/api/loadingClient",{},{withCredentials:true})
      .then((response) => {
        this.tiers = response.data;
        this.numberOfPages = this.tiers.length / this.perPage;
        for(var j=0; j<this.clients.length; j++){
            this.tiers[j]["acces"]=false;
            this.tiers[j]["_showDetails"]=false;
        }
        for(var i=0; i<this.clientsExtranet.length; i++){
          for(j=0; j<this.tiers.length; j++){
            if(this.tiers[j]["id"]==this.clientsExtranet[i]["socid"]){
              this.tiers[j]["acces"]=true;
            }
          }
        }
      })
      .catch((error)=>{
        if(error.response != undefined){
          toasterError(`Impossible de charger les clients`,'Chargement des clients')
        }else{
          toasterError(`Le service est momentanément indisponible`,'Service indisponible')
        }
      })
    })
    .catch((error) => {
      this.axios.post(conf.server+"/api/loadingClient",{},{withCredentials:true})
      .then((response) => {
        this.tiers = response.data;
        this.numberOfPages = this.tiers.length / this.perPage;
        for(var j=0; j<this.tiers.length; j++){
            this.tiers[j]["acces"]=false;
        }
        for(var i=0; i<this.clientsExtranet.length; i++){
          for(j=0; j<this.tiers.length; j++){
            if(this.tiers[j]["id"]==this.clientsExtranet[i]["socid"]){
              this.tiers[j]["acces"]=true;
            }
          }
        }
      })
      .catch((error)=>{
        if(error.response != undefined){
          toasterError(`Impossible de charger les clients`,'Chargement des clients')
        }else{
          toasterError(`Le service est momentanément indisponible`,'Service indisponible')
        }
      })
      if(error.response != undefined){
        if(error.response.data.route!=null){
          this.$router.push(error.response.data.route);
        }
      }else{
        toasterError(`Le service est momentanément indisponible`,'Service indisponible')
      }
    })
  }else{
    this.axios.get(conf.server+"/api/verifyAccess",{withCredentials:true})
    .then((response) => {
      this.clientsExtranet = response.data;
      this.axios.post(conf.server+"/api/loadingClient",{},{withCredentials:true})
      .then((response) => {
        this.tiers = response.data;
        this.numberOfPages = this.tiers.length / this.perPage;
        for(var j=0; j<this.clients.length; j++){
            this.tiers[j]["acces"]=false;
            this.tiers[j]["_showDetails"]=false;
        }
        for(var i=0; i<this.clientsExtranet.length; i++){
          for(j=0; j<this.tiers.length; j++){
            if(this.tiers[j]["id"]==this.clientsExtranet[i]["socid"]){
              this.tiers[j]["acces"]=true;
            }
          }
        }
      })
      .catch((error)=>{
        if(error.response != undefined){
          toasterError(`Impossible de charger les clients`,'Chargement des clients')
        }else{
          //toasterError(`Le service est momentanément indisponible`,'Service indisponible')
        }
      })
    })
    .catch((error) => {
      this.axios.post(conf.server+"/api/loadingClient",{},{withCredentials:true})
      .then((response) => {
        this.tiers = response.data;
        this.numberOfPages = this.tiers.length / this.perPage;
        for(var j=0; j<this.tiers.length; j++){
            this.tiers[j]["acces"]=false;
        }
        for(var i=0; i<this.clientsExtranet.length; i++){
          for(j=0; j<this.tiers.length; j++){
            if(this.tiers[j]["id"]==this.clientsExtranet[i]["socid"]){
              this.tiers[j]["acces"]=true;
            }
          }
        }
      })
      .catch((error)=>{
        if(error.response != undefined){
          toasterError(`Impossible de charger les clients`,'Chargement des clients')
        }else{
          toasterError(`Le service est momentanément indisponible`,'Service indisponible')
        }
      })
      if(error.response != undefined){
        if(error.response.data.route!=null){
          this.$router.push(error.response.data.route);
        }
      }else{
        toasterError(`Le service est momentanément indisponible`,'Service indisponible')
      }
    })
  }
}

//Fonction de chargement des dictionnaires
function loadingDictionary(){
  this.optionsPayment.push({value: "0",text: ""})
  this.optionsDeliveryDelay.push({value: "0",text: ""})
  this.axios.get(conf.server+"/api/getDictionary",{withCredentials:true})
    .then((response) => {
      var conditionPayment = response.data.conditionPayment
      var typePayment = response.data.typePayment
      var deliveryDelay = response.data.deliveryDelay
      var i
      for(i=0;i<conditionPayment.length;i++){
        this.optionsConditionsPayment.push({value: conditionPayment[i].id, text: conditionPayment[i].label})
      }
      for(i=0;i<typePayment.length;i++){
        this.optionsPayment.push({value:typePayment[i].code, text: typePayment[i].label})
      }
      for(i=0;i<deliveryDelay.length;i++){
        this.optionsDeliveryDelay.push({value: deliveryDelay[i].code, text: deliveryDelay[i].label})
      }
    })
    .catch((error) => {
      if(error.response != undefined){
        toasterError(`Impossible de charger les dictionnaires`,'Chargement des dictionnaires')
      }else{
        toasterError(`Le service est momentanément indisponible`,'Service indisponible')
      }
    })
}

//Fonction de chargement des documents du client
function loadDocuments(type){
  var param
  if(this.$route.params.id !== undefined){
    param = {
      type:type,
      id:this.$route.params.id
    }
  }else{
    param = {
      type:type,
      id:0
    }
  }
  this.axios.get(conf.server+"/api/getDocuments",{params:param},{withCredentials:true})
  .then((response) => {
    this.isDocuments = true;
    //var tmpLie = new Array;
    if(response.data!="0"){
      this.totalRows[type] = response.data.length;
      var i
      var length = response.data.length
      switch(type){
        case 'contracts':
        for(i=0;i<length;i++){
          this[type].push(response.data[i]);
          this[type][i].date_creation = moment(this[type][i].date_creation*1000).format("DD-MM-YYYY");
          this[type][i].total_ht = new Intl.NumberFormat(0, { style: 'currency', currency: 'EUR' }).format(this[type][i].total_ht);
          this[type][i].total_ttc = new Intl.NumberFormat(0, { style: 'currency', currency: 'EUR' }).format(this[type][i].total_ttc);
        }

        /*for(var i=0,length = response.data.length;i<length;i++){
            for(var j=0;j<response.data[i].lines.length;j++){
              console.log(this[type][i].lines[j]);
              this[type].push(response.data[i].lines[j]);
            }
          }
          for(var k=0;k<this[type].length;k++){
            this[type][i].date_start = moment(this[type][i].date_start*1000).format("DD-MM-YYYY");
            this[type][i].total_ht = new Intl.NumberFormat(0, { style: 'currency', currency: this[type][i].multicurrency_code }).format(this[type][i].total_ht);
            this[type][i].total_ttc = new Intl.NumberFormat(0, { style: 'currency', currency: this[type][i].multicurrency_code }).format(this[type][i].total_ttc);
          }*/
        break;
        case 'proposals':
          for(i=0;i<length;i++){
            this[type].push(response.data[i]);
            this[type][i].date_creation = moment(this[type][i].date_creation*1000).format("DD-MM-YYYY");
            this[type][i].total_ht = new Intl.NumberFormat(0, { style: 'currency', currency: this[type][i].multicurrency_code }).format(this[type][i].total_ht);
            this[type][i].total_ttc = new Intl.NumberFormat(0, { style: 'currency', currency: this[type][i].multicurrency_code }).format(this[type][i].total_ttc);
          }
          break;
        case 'orders':
          for(i=0;i<length;i++){
            this[type].push(response.data[i]);
            this[type][i].date_commande = moment(this[type][i].date_commande*1000).format("DD-MM-YYYY");
            if(this[type][i].date_livraison!=""){
              this[type][i].date_livraison = moment(this[type][i].date_livraison*1000).format("DD-MM-YYYY");
            }else{
              this[type][i].date_livraison = false;
            }
      
            this[type][i].total_ht = new Intl.NumberFormat(0, { style: 'currency', currency: this[type][i].multicurrency_code }).format(this[type][i].total_ht);
            this[type][i].total_ttc = new Intl.NumberFormat(0, { style: 'currency', currency: this[type][i].multicurrency_code }).format(this[type][i].total_ttc);
            /*this.axios.get(conf.server+"/api/getDocLie/"+type+"/"+response.data[i].id,{withCredentials:true})
            .then((response2) => {
              this.axios.get(conf.server+"/api/getDocumentLie/"+response2.data.sourcetype+"/"+response2.data.fk_source,{withCredentials:true})
              .then((response3) => {
                var tmp = {docLie:response.data[i].id,ref:response3.data.ref,last_main_doc:response3.data.last_main_doc};
                tmpLie.push(tmp);
              })
              .catch(() => {

              })
            })
            .catch(() => {

            })*/
          }
          //this.ordersLink=tmpLie;
          break;
        case 'invoices':
          for(i=0;i<length;i++){
            this[type].push(response.data[i]);
            this[type][i].date_creation = moment(this[type][i].date_creation*1000).format("DD-MM-YYYY");
            this[type][i].date_lim_reglement = moment(this[type][i].date_lim_reglement*1000).format("DD-MM-YYYY");
            var now = Date.now();
            if(now>(this[type][i].date_lim_reglement*1000)){
              this[type][i].date_lim_sup = 1;
            }else{
              this[type][i].date_lim_sup = 0;
            }
            this[type][i].total_ht = new Intl.NumberFormat(0, { style: 'currency', currency: this[type][i].multicurrency_code }).format(this[type][i].total_ht);
            this[type][i].total_ttc = new Intl.NumberFormat(0, { style: 'currency', currency: this[type][i].multicurrency_code }).format(this[type][i].total_ttc);
            /*this.axios.get(conf.server+"/api/getDocLie/"+type+"/"+response.data[i].id,{withCredentials:true})
            .then((response2) => {
              this.axios.get(conf.server+"/api/getDocumentLie/"+response2.data.sourcetype+"/"+response2.data.fk_source,{withCredentials:true})
              .then((response3) => {
                var tmp = {docLie:response.data[i].id,ref:response3.data.ref,last_main_doc:response3.data.last_main_doc};
                tmpLie.push(tmp);
              })
              .catch(() => {

              })
            })
            .catch(() => {

            })*/
          }
          //this.invoicesLink=tmpLie;
          break;
      }
      //console.log(this.invoicesLink);
    }
  })
  .catch((error) => {
    if(error.response != undefined){
      if(error.response.data.route!=null){
        this.$router.push(error.response.data.route);
      }
    }else{
      toasterError(`Le service est momentanément indisponible`,'Service indisponible')
    }
  })
}

//Fonction de chargement des infos du compte
function loadingInfo(){
  this.axios.post(conf.server+"/api/loadingInfo",{},{withCredentials:true})
  .then((response) => {
    if(response.status==200){
      vuexStorage.commit('updateInfo', response.data)
    }else{
      this.$router.push('login');
    }
  })
  .catch((error) => {
    console.log(error)
    this.axios.post(conf.server+"/api/disconnect",{},{withCredentials:true})
    .then((response) => {
      this.$router.push(response.data.route);
    })
    .catch(() => {
      this.$router.push('login');
    })
  })
}

//Fonction de chargement des infos du compte
function loadingInfoClient(){
  this.axios.post(conf.server+"/api/loadingInfo",{},{withCredentials:true})
  .then((response) => {
    if(response.status==200){
      vuexStorage.commit('updateInfo', response.data)
      detailsTiers(response.data.socId, this)
    }else{
      this.$router.push('login');
    }
  })
  .catch((error) => {
    console.log(error)
    this.axios.post(conf.server+"/api/disconnect",{},{withCredentials:true})
    .then((response) => {
      this.$router.push(response.data.route);
    })
    .catch(() => {
      this.$router.push('login');
    })
  })
}

//Fonction de choix du template mail d'envoie de code d'accès
function loadingTemplateMail(template,email,pwd,tokenReinit){
  this.axios.post(conf.server+"/api/getInfoClient",{email: email},{withCredentials:true})
  .then((response) => {
    this.axios.post(conf.server+"/api/template",{template : template,email: email,pwd : pwd,tokenReinit: tokenReinit, prenom : response.data.firstname, nom : response.data.lastname, ref : response.data.ref_entreprise, nomEntreprise : response.data.nom_entreprise},{withCredentials:true})
    .then((response2) => {
      this.form.contenuMail=response2.data.content;
    })
    .catch((error) => {
      if(error.response != undefined){
        if(error.response.data.route!=null){
          this.$router.push(error.response.data.route);
        }else{
          this.$router.push('login');
        }
      }else{
        toasterError(`Le service est momentanément indisponible`,'Service indisponible')
      }
    })
  })
  .catch((error) => {
    if(error.response != undefined){
      if(error.response.data.route!=null){
        this.$router.push(error.response.data.route);
      }else{
        this.$router.push('login');
      }
    }else{
      toasterError(`Le service est momentanément indisponible`,'Service indisponible')
    }
  })
}

//Fonction de chargement des templates mail
function loadingTemplatesMail(){
  this.axios.post(conf.server+"/api/templates",{},{withCredentials:true})
  .then((response) => {
    var templateBase = {value:null, text: 'Template de base',active:1}
    this.templates.push(templateBase);
    for(var i=0; i<response.data.length;i++){
      var tmp = {value:response.data[i].value, text:response.data[i].text, active:response.data[i].active}
      this.templates.push(tmp);
    }
  })
  .catch((error) => {
    if(error.response != undefined){
      if(error.response.data.route!=null){
        this.$router.push(error.response.data.route);
      }else{
        this.$router.push('login');
      }
    }else{
      toasterError(`Le service est momentanément indisponible`,'Service indisponible')
    }
  })
}

//Fonction de chargement des templates mail actif
function loadingTemplatesMailActive(){
  this.axios.get(conf.server+"/api/templatesActive",{},{withCredentials:true})
  .then((response) => {
    var templateBase = {value:null, text: 'Template de base'}
    this.templates.push(templateBase);
    for(var i=0; i<response.data.length;i++){
      var tmp = {value:response.data[i].value, text:response.data[i].text}
      this.templates.push(tmp);
    }
  })
  .catch((error) => {
    if(error.response != undefined){
      if(error.response.data.route!=null){
        this.$router.push(error.response.data.route);
      }else{
        this.$router.push('login');
      }
    }else{
      toasterError(`Le service est momentanément indisponible`,'Service indisponible')
    }
  })
}

//Fonction chargement des sites de création de ticket admin
function loadSites(){
  this.axios.post(conf.server+"/api/loadingClient",{sortfield: 't.nom'},{withCredentials:true})
    .then((response) => {
      this.clients = response.data;
      for(var j=0; j<this.clients.length; j++){
          var tmp = {value:this.clients[j]["id"],text:this.clients[j]["name"]};
          this.optionsSites.push(tmp);
      }
    })
    .catch((error)=>{
      if(error.response!= undefined){

      }else{
        toasterError(`Le service est momentanément indisponible`,'Service indisponible')
      }
    })
}

//Chargement de la vitesse du carrousel
function loadSpeed(){
  this.axios.get(conf.server+"/api/loadConfCarrousel",{withCredentials:true})
  .then((response) => {
    for(var i=0; i<response.data.length;i++){
      if(response.data[i].name=="CARROUSEL_TIMEOUT"){
        this.confCarrousel.vitesse = response.data[i].value;
      }
    }
  })
  .catch((error) => {
    if(error.response != undefined){
      if(error.response.data.route!=null){
        this.$router.push(error.response.data.route);
      }
    }else{
      toasterError(`Le service est momentanément indisponible`,'Service indisponible')
    }
  })
}

//Chargement des tickets
function loadTickets(){
  this.axios.get(conf.server+"/api/loadTickets",{withCredentials:true})
  .then((response) => {
    this.tickets=response.data;
    var statut = { "0": "Non lu","1": "Lu","3": "Répondu","4": "Assigné","5": "En cours","6": "En attente","8": "Cloturé","9": "Supprimé"};
    for(var i=0;i<response.data.length;i++){
      this.tickets[i].datec = moment(this.tickets[i].datec*1000).format("DD-MM-YYYY");
      this.tickets[i].fk_statut = statut[this.tickets[i].fk_statut];
    }
    this.totalRows=response.data.length;
  })
  .catch((error) => {
    if(error.response != undefined){
      toasterError(`Impossible de charger les tickets support`,'Chargement des tickets')
      if(error.response.data.route!=null){
        this.$router.push(error.response.data.route);
      }
    }else{
      toasterError(`Le service est momentanément indisponible`,'Service indisponible')
    }
  })
}

//Chargement des tickets client
function loadTicketsClient(){
  this.axios.get(conf.server+"/api/loadTicketsClient",{withCredentials:true})
  .then((response) => {
    this.tickets=response.data;
    var statut = { "0": "Non lu","1": "Lu","3": "Répondu","4": "Assigné","5": "En cours","6": "En attente","8": "Cloturé","9": "Supprimé"};
    for(var i=0;i<response.data.length;i++){
      this.tickets[i].datec = moment(this.tickets[i].datec*1000).format("DD-MM-YYYY");
      this.tickets[i].fk_statut = statut[this.tickets[i].fk_statut];
    }
    this.totalRows=response.data.length;
  })
  .catch((error) => {
    if(error.response != undefined){
      toasterError(`Impossible de charger les tickets support`,'Chargement des tickets')
      if(error.response.data.route!=null){
        this.$router.push(error.response.data.route);
      }
    }else{
      toasterError(`Le service est momentanément indisponible`,'Service indisponible')
    }
  })
}

//Fonction pour masquer les infos d'accès du client
function masquerInfo(){
  document.getElementById("infoContact").style.display="none";
}

//Fonction pour masquer la discussion ticket
function masquerInfoTicket(){
  document.getElementById("infoTicket").style.display="none";
}

//Fonction d'affichage du modal de modification des infos perso du client
function modifInfoPerso(target){
  this.$root.$emit('bv::show::modal', 'modalInfoPerso',target);
}

//Fonction pour la selection pour l'ajout de masse à l'extranet
function openAjoutExtranet(target){
  this.axios.get(conf.server+"/api/verifyAccess",{withCredentials:true})
  .then((response) => {
    this.clientsExtranet = response.data;
    this.axios.get(conf.server+"/api/loadingAllContacts",{withCredentials:true})
    .then((response) => {
      var res = response.data;
      for(var j=0; j<res.length; j++){
        res[j]["acces"]=false;
      }
      for(var i=0; i<this.clientsExtranet.length; i++){
        for(j=0; j<res.length; j++){
          if(res[j]["id"]==this.clientsExtranet[i]["contact_id"]){
            res[j]["acces"]=true;
          }
        }
      }
      for(j=0; j<res.length; j++){
        if(res[j]["acces"]==false){
          this.contacts.push(res[j]);
        }
      }
      this.$root.$emit('bv::show::modal', 'modalAjoutExtranet',target);
    })
    .catch(()=>{
    })
  })
  .catch((error) => {
    this.axios.post(conf.server+"/api/loadingAllContacts",{},{withCredentials:true})
    .then((response) => {
      this.contacts = response.data;
      for(var j=0; j<this.contacts.length; j++){
          this.contacts[j]["acces"]=false;
      }
      this.$root.$emit('bv::show::modal', 'modalAjoutExtranet',target);
    })
    .catch((error)=>{
    if(error.response != undefined){
      toasterError(`Chargement des contacts impossible`,'Chargement des contacts')

    }else{
      toasterError(`Le service est momentanément indisponible`,'Service indisponible')
    }
    })
    if(error.response != undefined){
      if(error.response.data.route!=null){
        this.$router.push(error.response.data.route);
      }
    }else{
      toasterError(`Le service est momentanément indisponible`,'Service indisponible')
    }
  })
}

//Fonction réinitialisation du modal
function resetModal() {
  this.modalInfo.title = ''
  this.modalInfo.content = ''
}

//Fonction de chargement de produit
async function searchProducts(){
  this.optionsProducts = []
  this.axios.post(conf.server+"/api/searchproduct",{search: this.productSearch}, {withCredentials: true})
  .then((response) => {
    for(var i=0; i< response.data.length; i++){
      this.optionsProducts.push({value: response.data[i].id, text: response.data[i].ref+" - "+response.data[i].label+" - "+Number.parseFloat(response.data[i].price).toFixed(2)+"HT"})
    }
  })
  .catch((error) => {
    if(error.response != undefined){
      if(error.response.data.route == "login"){
        this.$router.push('login')
      }
    }else{
      toasterError(`Le service est momentanément indisponible`,'Service indisponible')
    }
  })
}

//Fonction d'envoi de message ticket admin
function sendMessageTicket(){
  /*var myfile = document.getElementById("myfile").files;

  var formData = new FormData();

  var files = new Array();

  for(var i=0; i < myfile.length; i++){
    formData.append('myfile', myfile[i]);
    var tmp = {name:myfile[i].name};
    files.push(tmp);
  }*/
  this.axios.post(conf.server+"/api/sendMessage",{trackId:this.currentTrack,message:this.formMessage.contenuMessage},{withCredentials:true})
  .then((response) => {
    this.ticketMessages=response.data;
    for(var i=0;i<response.data.length;i++){
      this.ticketMessages[i].message=htmlDecode(this.ticketMessages[i].message);
    }
    this.formMessage.contenuMessage="";
    /*this.axios.post(conf.server+"/api/uploadFilesTicket",formData,{headers:{"content-type" : "multipart/form-data"},withCredentials:true})
    .then(() => {
      
    })
    .catch((error) => {
      if(error.response.data.route!=null){
        this.$router.push(error.response.data.route);
      }
    })*/
  })
  .catch((error) => {
    if(error.response != undefined){
      toasterError(`Impossible d'envoyer le message sur le ticket`,'Envoi de message')
    }else{
      toasterError(`Le service est momentanément indisponible`,'Service indisponible')
    }
  })
}

//Fonction d'envoi de message ticket client
function sendMessageTicketClient(){
  /*var myfile = document.getElementById("myfile").files;

  var formData = new FormData();

  var files = new Array();

  for(var i=0; i < myfile.length; i++){
    formData.append('myfile', myfile[i]);
    var tmp = {name:myfile[i].name};
    files.push(tmp);
  }*/
  this.axios.post(conf.server+"/api/sendMessageClient",{trackId:this.currentTrack,message:this.formMessage.contenuMessage},{withCredentials:true})
  .then((response) => {
    this.ticketMessages=response.data;
    for(var i=0;i<response.data.length;i++){
      this.ticketMessages[i].message=htmlDecode(this.ticketMessages[i].message);
    }
    this.formMessage.contenuMessage="";
    /*this.axios.post(conf.server+"/api/uploadFilesTicket",formData,{headers:{"content-type" : "multipart/form-data"},withCredentials:true})
    .then(() => {
      
    })
    .catch((error) => {
      if(error.response.data.route!=null){
        this.$router.push(error.response.data.route);
      }
    })*/
  })
  .catch((error) => {
    if(error.response != undefined){
      toasterError(`Impossible d'envoyer le message sur le ticket`,'Envoi de message')
    }else{
      toasterError(`Le service est momentanément indisponible`,'Service indisponible')
    }
  })
}

//Fonction de création de ticket client
function sendTicket(){
  this.axios.post(conf.server+"/api/sendTicket",this.form,{withCredentials:true})
  .then((response) => {
    this.$router.psuh(response.data.route);
  })
  .catch((error) => {
    if(error.response != undefined){
      toasterError(`Impossible d'envoyer le ticket de support`,'Envoi de ticket')
    }else{
      toasterError(`Le service est momentanément indisponible`,'Service indisponible')
    }
  })
}

//Fonction de création de ticket admin
function sendTicketAdmin(){
  this.axios.post(conf.server+"/api/sendTicketAdmin",this.form,{withCredentials:true})
  .then((response) => {
    this.$router.psuh(response.data.route);
  })
  .catch((error) => {
    if(error.response != undefined){
      toasterError(`Impossible d'envoyer le ticket de support`,'Envoi de ticket')
    }else{
      toasterError(`Le service est momentanément indisponible`,'Service indisponible')
    }
  })
}

//Fonction change password
function setPass(){
  if(this.post.pwd==this.post.pwd2){
    this.axios.post(conf.server+"/api/setPass", {pwd:this.post.pwd,token:this.$route.params.token},{withCredentials:true})
    .then((response) => {
      if(response.status==200){
        this.$bvToast.toast(`Changement mot de passe réussi`, {
          title: 'Changement de mot de passe',
          toaster: 'b-toaster-bottom-right',
          variant: 'success',
          autoHideDelay: 2500,
          appendToast: true,
          solid: true
        })
        this.$router.push('/');
      }else{
        this.$router.push('/');
      }
    })
    .catch((error) => {
      if(error.response != undefined){
        toasterError(`Changement de mot de passe impossible`,'Changement de mot de passe')
      }else{
        toasterError(`Le service est momentanément indisponible`,'Service indisponible')
      }
    })
  }else{
    toasterError(`Les deux mots de passes ne correspondent pas`,'Changement de mot de passe')
  }
}

//fonction de synchro des catalogues
function syncCatalogues () {
  
}

//Fonction de mise à jours des champs
function updateFields (type, id, field, value) {
  this.axios.post(conf.server+'/api/updateFields',{type:type,id:id,field:field,value:value}, { withCredentials: true })
    .then(() => {
      this.$bvToast.toast(`Les modifications ont été enregistrées`, {
        title: 'Modification rapide',
        toaster: 'b-toaster-bottom-right',
        variant: 'success',
        autoHideDelay: 2500,
        appendToast: true,
        solid: true
      })
    })
    .catch(() => {
		toasterError(`Le service est momentanément indisponible`,'Service indisponible')
		this.$router.push('/');
    })
}

//Upload des images du carrousel
function uploadImg(){
  var myfile = document.getElementById("myfile").files;

  var formData = new FormData();

  var files = new Array();

  for(var i=0; i < myfile.length; i++){
    formData.append('myfile', myfile[i]);
    var tmp = {name:myfile[i].name};
    files.push(tmp);
  }
  this.axios.post(conf.server+"/api/uploadFilesCarrousel",formData,{headers:{"content-type" : "multipart/form-data"},withCredentials:true})
  .then(() => {
    this.axios.post(conf.server+"/api/addImg",files,{withCredentials:true})
    .then(() => {
      window.location.reload();
    })
    .catch((error) => {
      if(error.response != undefined){
        if(error.response.data.route!=null){
          this.$router.push(error.response.data.route);
        }
      }else{
        toasterError(`Le service est momentanément indisponible`,'Service indisponible')
      }
    })
  })
  .catch((error) => {
    if(error.response != undefined){
      if(error.response.data.route!=null){
        this.$router.push(error.response.data.route);
      }
    }else{
      toasterError(`Le service est momentanément indisponible`,'Service indisponible')
    }
  })
}

//Fonction de validation de propale
function validateObject(type, id){
  this.axios.post(conf.server+'/api/validateObject',{type: type, id: id},{withCredentials:true})
  .then(() => {
    this.proposals = []
    this.loadDocuments("proposals");
  })
  .catch(() => {
    toasterError(`Une erreur interne est survenue`,'Erreur interne')
  })
}

//Fonction de vérification des droits de l'utilisateur
function verifyRight(){
  this.axios.post(conf.server+"/api/verifyRight",{route: this.$route.name},{withCredentials:true})
  .then(() => {
    
  })
  .catch((error) => {
    if(error.response != undefined){
      if(error.response.status==403){
        this.$router.push('error403');
      }else{
        this.axios.post(conf.server+"/api/disconnect",{},{withCredentials:true})
        .then(() => {
          window.location = "https://as2piq.ideal-dev.fr";
        })
        .catch(() => {
          window.location = "https://as2piq.ideal-dev.fr";
        })
      }
    }else{
      toasterError(`Le service est momentanément indisponible`,'Service indisponible')
    }
  })
}

//Fonction de vérification de token
function verifyToken(){
  this.axios.get(conf.server+"/api/verifyToken/"+this.$route.params.token,{withCredentials:true})
  .then((response) => {
    if(!response.data){
      toasterError(`Temps pour changer de mot de passe écoulé`,'Vérification')
      this.$router.push('login');
    }
  })
  .catch(()=>{
    toasterError(`Temps pour changer de mot de passe écoulé`,'Vérification')
    this.$router.push('login');
  })
}

//Fonction de visualisation des document client
function visualDoc(item,target){
  if(item.last_main_doc!=null){
    this.axios.get(conf.server+"/api/visualDoc/"+item.last_main_doc,{withCredentials:true})
    .then(() => {
      this.visualSrc = conf.server+"/api/visualDoc/"+item.last_main_doc;
      this.modalVisual.title="Document "+item.ref+" : "+item.ref_client+" du "+item.date_creation;
      this.$root.$emit('bv::show::modal', 'modalVisual',target);
    })
    .catch(() => {
      toasterError(`Visualisation du document impossible`,'Visualisation du document')
    }) 
  }else{
    this.axios.get(conf.server+"/api/visualDoc/contracts/"+item.ref+"/"+item.ref+'.pdf',{withCredentials:true})
    .then(() => {
      this.visualSrc = conf.server+"/api/visualDoc/"+item.last_main_doc;
      this.modalVisual.title="Document "+item.ref+" : "+item.ref_customer+" du "+item.date_creation;
      this.$root.$emit('bv::show::modal', 'modalVisual',target);
    })
    .catch(() => {
      toasterError(`Visualisation du document impossible`,'Visualisation du document')
    }) 
  }
}

//Fonction de visualisation des devis client admin
function visualDevisTiers(item,target){
  this.visualSrcDevisTiers = conf.server+"/api/visualDoc/"+item.last_main_doc;
  this.modalVisualDevisTiers.title="Document "+item.ref+" : "+item.ref_client+" du "+item.date_creation;
  this.$root.$emit('bv::show::modal', 'modalVisualDevisTiers',target);
}

//Fonction de visualisation des factures client admin
function visualFacTiers(item,target){
  this.visualSrcFacTiers = conf.server+"/api/visualDoc/"+item.last_main_doc;
  this.modalVisualFacTiers.title="Document "+item.ref+" : "+item.ref_client+" du "+item.date_creation;
  this.$root.$emit('bv::show::modal', 'modalVisualFacTiers',target);
}

//Fonction de visualisation des templates mail
function visualiserTemplate(title,template,target){
  this.axios.post(conf.server+"/api/templateVisual",{template : template},{withCredentials:true})
    .then((response) => {
      this.modalVisualTemplate.title=title;
      this.modalVisualTemplate.content=response.data.content;
      this.$root.$emit('bv::show::modal', 'modalVisualTemplate',target);
    })
    .catch((error) => {
      if(error.response != undefined){
        toasterError(`Impossible de charger la visualisation du template`,'Visualisation impossible')
        if(error.response.data.route!=null){
          this.$router.push(error.response.data.route);
        }
      }else{
        toasterError(`Le service est momentanément indisponible`,'Service indisponible')
      }
    })
}

// Fonction appel api
/*
  args: options : method -- required
                  url -- required
                  params -- optional -- url parameters
                  form -- optional -- request body applicable only with PUT, POST and Patch methods
                  headers -- optional
*/
function requestApi (options) {
  return new Promise((resolve, reject) => {
    new Vue().axios({
      method: options.method,
      url: conf.server + options.url,
      params: options.params,
      data: options.form,
      headers: options.headers,
      withCredentials: true
    }).then((response) => {
      resolve(response.data)
    }).catch((error) => {
      reject(error)
    })
  })
}

export default {
  disconnect,
  loadingInfo,
  isConnect,
  verifyRight,
  loadingClient,
  loadingAllClient,
  expandClientInfo,
  createAccess,
  deleteAccess,
  masquerInfo,
  info,
  resetModal,
  loadingTemplatesMailActive,
  loadingTemplatesMail,
  loadingTemplateMail,
  connectOnClient,
  loadDocuments,
  visualDoc,
  commentDoc,
  getInfoClient,
  getInfoSocClient,
  changePassword,
  modifInfoPerso,
  changeInfoPerso,
  activeClassMenuProfil,
  visualiserTemplate,
  activerTemplate,
  loadCarrousel,
  uploadImg,
  changeSpeed,
  loadSpeed,
  deleteImg,
  loadTickets,
  filterTickets,
  expandTicketInfo,
  masquerInfoTicket,
  loadTicketsClient,
  filterTicketsClient,
  expandTicketInfoAdmin,
  sendMessageTicket,
  sendMessageTicketClient,
  sendTicketAdmin,
  sendTicket,
  loadSites,
  changePassOublie,
  verifyToken,
  detailsTiers,
  updateFields,
  addContact,
  delContact,
  visualDevisTiers,
  visualFacTiers,
  deleteTiers,
  setPass,
  openAjoutExtranet,
  addMassiveContact,
  loadingDictionary,
  createPropal,
  deletePropale,
  searchProducts,
  addProduct,
  chooseProduct,
  deleteProduct,
  deleteProducts,
  toasterError,
  validateObject,
  syncCatalogues,
  loadingInfoClient
};
