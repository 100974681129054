<template>
  <div id="contenu">
    <Menu id="menuTop" class="menuTopClient"/>
	<sidebar-menu @toggle-collapse="onCollapse" :collapsed="myCollapse" class="v-sidebar-menu-client" :menu="verticalMenu"/>
    <div id='centre'>
      <component :is="layout">
      </component>
    </div>
  </div>
</template>
<script>
import script from '../../script/script.js';
import Menu from './Menu.vue';
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css';
export default {
	props: {
		menu: {
			type: Array,
			required: true
		},
		collapsed: {
			type: Boolean,
			default: true
		},
		widthCollapsed: {
			type: String,
			default: '2.5vw'
		},
		showChild: {
			type: Boolean,
			default: false
		},
		theme: { // available themes: 'white-theme'
			type: String,
			default: ''
		},
		showOneChild: {
			type: Boolean,
			default: false
		},
		rtl: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			active: true,
			myCollapse:true,
			verticalMenu:[{
					href:'/indexClient',
					title:'Accueil',
					icon:'fas fa-home'
				},
				{
					href:'/documentUnique',
					title:'Document unique',
					icon:'fas fa-archive'
				},
				// {
				// 	href:'/visio',
				// 	title:'Visio',
				// 	icon:'fas fa-video'
				// },
				// {
				// 	href:'/assistance',
				// 	title:'Assistance',
				// 	icon:'fas fa-wrench'
				// },
				// {
				// 	href:'/aideClient',
				// 	title:'Aide',
				// 	icon:'fas fa-info-circle'
				// },
			],
		}
	},
	created:function(){
		this.verifyRight();
		this.loadingInfoClient();
	},
	methods: {
		disconnect: script.disconnect,
		detailsTiers: script.detailsTiers,
		loadingInfoClient: script.loadingInfoClient,
		verifyRight: script.verifyRight,
		onCollapse(collapsed){
			if(collapsed){
				document.getElementById('centre').style.left ='3%';
			}else{
				document.getElementById('centre').style.left ='12%';
			}
		}
	},
	components: {
		Menu
	},
	computed:{
		layout(){
			return(this.$route.meta.layout)+'-layout';
		},
	}
  
}
</script>
<style>
#centre{
	flex: 1 0 95%;
	transition: all .3s;
	position: absolute;
	top: 6%;
	left: 3%;
	right: 0;
	bottom: 0;
	max-height: 94%;
	overflow: auto;
	padding: 1em;
}

	.v-sidebar-menu-client .vsm--toggle-btn{
		background-color:transparent;
		outline:none;
	}

	.v-sidebar-menu-client .vsm--item>.vsm--link>.vsm--icon{
		background-color:transparent;
	}

	.v-sidebar-menu-client.vsm_expanded{
		max-width: 12vw !important;
		background-color:#808285;
		position: absolute;
		height: 94%;
		top: 6%;
	}
	
	.v-sidebar-menu-client.vsm_collapsed{
		max-width: 2.5vw !important;
		background-color:#808285;
		position: absolute;
		height: 94%;
		top: 6%;
	}

	.v-sidebar-menu-client div:nth-child(2){
		max-width: 12vw;
	}

	.v-sidebar-menu-client .vsm--link{
		font-size:13px;
	}

	#contenu{
		display: flex;
		height: 100%;
		flex-wrap: wrap;
	}

	#menuTop{
		flex: 1 0 100%;
		height: 6%;
	}

	#menuTop.menuTopClient .navbar{
		justify-content: flex-end;
	}
</style>

