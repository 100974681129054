<template>
	<div style="width:100%;">
		<b-tabs content-class="mt-3" active-nav-item-class="font-weight-bold">
			<b-tab class="outlineLess">
				<template slot="title">
					<i class="fas fa-square-root-alt" style="padding-right:5px;"></i> Cotation
				</template>
				<p><tabCotation /></p>
			</b-tab>
			<b-tab class="outlineLess">
				<template slot="title">
					<i class="fas fa-radiation" style="padding-right:5px;"></i> Risques
				</template>
				<p><tabRisques /></p>
			</b-tab>
			<b-tab class="outlineLess">
				<template slot="title">
					<i class="fas fa-tasks" style="padding-right:5px;"></i> Checklist Générale
				</template>
				<p><tabChecklist /></p>
			</b-tab>
			<b-tab class="outlineLess">
				<template slot="title">
					<i class="fas fa-users" style="padding-right:5px;"></i> Personnes à risque
				</template>
				<p><tabPersonneRisque /></p>
			</b-tab>
			<b-tab class="outlineLess">
				<template slot="title">
					<i class="fas fa-user-injured" style="padding-right:5px;"></i> Pénibilité
				</template>
				<p><tabPenibilite /></p>
			</b-tab>
		</b-tabs>
	</div>
</template>

<script>
import scriptQse from '../../../script/script-qse.js';
import tabCotation from './TabCotationConfig'
import tabRisques from './TabRisquesConfig'
import tabChecklist from './TabChecklistGeneraleConfig'
import tabPersonneRisque from './TabPersonneRisqueConfig'
import tabPenibilite from './TabPenibiliteConfig'
export default {
	components: {
		tabCotation,
		tabRisques,
		tabChecklist,
		tabPersonneRisque,
		tabPenibilite
	},

	data () {
		return {
		
		}
	},

	mounted: function () {
		this.getExtrafieldsDefault()
	},

	methods: {
		getExtrafieldsDefault: scriptQse.getExtrafieldsDefault
	}
}
</script>
<style>
.outlineLess {
  	outline: 0;
}
</style>
