<template>
	<div>
		<h4>Exportation des fichiers</h4>
		<el-button @click.native.prevent="createXlsx()" type="success" icon="el-icon-download" round>Fichier au format .xlsx</el-button>
		<!-- <el-button @click.native.prevent="" type="success" icon="el-icon-download" round> Télécharger le fichier au format .pdf</el-button> -->
	</div>
</template>

<script>
import scriptQse from '../../../script/script-qse.js';
export default {
	data () {
		return {

		}
	},
	methods: {
		createXlsx: scriptQse.createXlsx,
	}
}
</script>

<style>

</style>