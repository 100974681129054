<template>
	<div>
		<div>
			<el-button @click.native.prevent="reloadDefault()" type="primary" size="small" round> Charger la configuration par défaut</el-button>
		</div>
		<h4>Identification des situations de travail à risque</h4>
		<b-table-simple ref="tableSituationRisqueSalarie" class="tableSituationRisqueSalarie" small responsive striped>
			<colgroup><col></colgroup>
			<colgroup><col></colgroup>
			<colgroup><col></colgroup>
			<colgroup><col></colgroup>
			<b-thead>
				<b-tr>
					<b-th class="" colspan="2"></b-th>
					<b-th class="">Unités de travail concernées</b-th>
					<b-th class="">Nombre de salariés concernés</b-th>
				</b-tr>
				<b-tr>
					<b-th class="" colspan="4">Contacts entre salariés</b-th>
				</b-tr>
			</b-thead>
			<b-tbody>
				<b-tr>
					<b-td class="" colspan="2">- à moins d'1 m et moins de 15 minutes</b-td>
					<b-td class=""><div contenteditable="true" class="" @blur="onEditSituation" @keydown.enter="endEditSituation" editTable="travailRisque" editType="bref" editPublic="salarie" editField='unite' :dataInit="annexeCovid.travailRisque.salarie.bref.unite">{{ annexeCovid.travailRisque.salarie.bref.unite }}</div></b-td>
					<b-td class=""><div contenteditable="true" class="" @blur="onEditSituation" @keydown.enter="endEditSituation" editTable="travailRisque" editType="bref" editPublic="salarie" editField='salarie' :dataInit="annexeCovid.travailRisque.salarie.bref.salarie">{{ annexeCovid.travailRisque.salarie.bref.salarie }}</div></b-td>
				</b-tr>
				<b-tr>
					<b-td class="" colspan="2">- à plus d'1 m ou plus de 15 minutes</b-td>
					<b-td class=""><div contenteditable="true" class="" @blur="onEditSituation" @keydown.enter="endEditSituation" editTable="travailRisque" editType="rapproche" editPublic="salarie" editField='unite' :dataInit="annexeCovid.travailRisque.salarie.rapproche.unite">{{ annexeCovid.travailRisque.salarie.rapproche.unite }}</div></b-td>
					<b-td class=""><div contenteditable="true" class="" @blur="onEditSituation" @keydown.enter="endEditSituation" editTable="travailRisque" editType="rapproche" editPublic="salarie" editField='salarie' :dataInit="annexeCovid.travailRisque.salarie.rapproche.salarie">{{ annexeCovid.travailRisque.salarie.rapproche.salarie }}</div></b-td>
				</b-tr>
			</b-tbody>
		</b-table-simple>

		<b-table-simple ref="tableSituationRisquePublic" class="tableSituationRisquePublic" small responsive striped>
			<colgroup><col></colgroup>
			<colgroup><col></colgroup>
			<colgroup><col></colgroup>
			<colgroup><col></colgroup>
			<b-thead>
				<b-tr>
					<b-th class="" colspan="2"></b-th>
					<b-th class="">Unités de travail concernées</b-th>
					<b-th class="">Nombre de salariés concernés</b-th>
				</b-tr>
				<b-tr>
					<b-th class="" colspan="4">Contacts avec le public</b-th>
				</b-tr>
			</b-thead>
			<b-tbody>
				<b-tr>
					<b-td class="" colspan="2">- contact brefs (moins de 15 min)</b-td>
					<b-td class=""><div contenteditable="true" class="" @blur="onEditSituation" @keydown.enter="endEditSituation" editTable="travailRisque" editType="bref" editPublic="public" editField='unite' :dataInit="annexeCovid.travailRisque.public.bref.unite">{{ annexeCovid.travailRisque.public.bref.unite }}</div></b-td>
					<b-td class=""><div contenteditable="true" class="" @blur="onEditSituation" @keydown.enter="endEditSituation" editTable="travailRisque" editType="bref" editPublic="public" editField='salarie' :dataInit="annexeCovid.travailRisque.public.bref.salarie">{{ annexeCovid.travailRisque.public.bref.salarie }}</div></b-td>
				</b-tr>
				<b-tr>
					<b-td class="" colspan="2">- contact rapprochés (moins d'1 m)</b-td>
					<b-td class=""><div contenteditable="true" class="" @blur="onEditSituation" @keydown.enter="endEditSituation" editTable="travailRisque" editType="rapproche" editPublic="public" editField='unite' :dataInit="annexeCovid.travailRisque.public.rapproche.unite">{{ annexeCovid.travailRisque.public.rapproche.unite }}</div></b-td>
					<b-td class=""><div contenteditable="true" class="" @blur="onEditSituation" @keydown.enter="endEditSituation" editTable="travailRisque" editType="rapproche" editPublic="public" editField='salarie' :dataInit="annexeCovid.travailRisque.public.rapproche.salarie">{{ annexeCovid.travailRisque.public.rapproche.salarie }}</div></b-td>
				</b-tr>
			</b-tbody>
		</b-table-simple>
		
		<br/>
		<h4>Mesures de prévention organisationnelles</h4>
		<div class="paragraphe">
			<h5>Mesures organisationnelles générales :</h5>
			<el-input
				type="textarea"
				id="textarea"
				v-model="annexeCovid.preventionOrga.mesuresGenerales"
				:rows="3"
				:max-rows="3"
				resize="none"
			></el-input>
			<h5>Modification de l'organisation pour les unités de travail suivantes (à compléter le cas échéant) :</h5>
			<el-input
				type="textarea"
				id="textarea"
				v-model="annexeCovid.preventionOrga.modification"
				:rows="3"
				:max-rows="3"
				resize="none"
			></el-input>
			<br/>
		</div>
		
		
		<h4>Mesures de prévention technique</h4>
		<div class="paragraphe">
			<h5>Protection collective :</h5>
			<el-input
				type="textarea"
				id="textarea"
				v-model="annexeCovid.preventionTechnique.protectionCollective"
				:rows="3"
				:max-rows="3"
				resize="none"
			></el-input>
			<h5>Protection individuelles et gestes barrière :</h5>
			<el-input
				type="textarea"
				id="textarea"
				v-model="annexeCovid.preventionTechnique.protectionIndividuelle"
				:rows="3"
				:max-rows="3"
				resize="none"
			></el-input>
			<h5>Modification de l'organisation pour les unités de travail suivantes (à compléter le cas échéant) :</h5>
			<el-input
				type="textarea"
				id="textarea"
				v-model="annexeCovid.preventionTechnique.modification"
				:rows="3"
				:max-rows="3"
				resize="none"
			></el-input>
			<br/>
		</div>

		<h4>Conduite à tenir en cas de contamination d'un salarié</h4>
		<div class="paragraphe">
			<h5>Procédure à suivre si un salarié a des symptômes faisant suspecter une contamination :</h5>
			<el-input
				type="textarea"
				id="textarea"
				v-model="annexeCovid.casContamination.procedure"
				:rows="3"
				:max-rows="3"
				resize="none"
			></el-input>
			<br/>
		</div>

		<h4>Information interne</h4>
		<div class="paragraphe">
			<h5>Modalités de communication :</h5>
			<el-input
				type="textarea"
				id="textarea"
				v-model="annexeCovid.informationInterne.modalite"
				:rows="3"
				:max-rows="3"
				resize="none"
			></el-input>
			<br/>
		</div>

		<h4>Identification des personnes présentant des risques de développer une forme grave d'infection à COVID-19</h4>
		<h5>Présence de personne à risques :</h5>
		<b-table ref="presencePersonneRisque" small responsive striped :items="annexeCovid.identificationPersonne.presencePersonneRisque" :fields="fieldsPresencePersonneRisque" show-empty>
			<template slot="empty" slot-scope="">
				<div style="text-align:center;">Ce tiers ne possède aucune personne à risque</div>
			</template>
			<template v-slot:cell(label)="data">
				<div contenteditable="true" class="" @blur="onEdit" @keydown.enter="endEdit" :editId="data.index" editTable="identificationPersonne" editType="presencePersonneRisque" editField='label' :dataInit="data.item.label">{{ data.item.label }}</div>
			</template>
			<template v-slot:cell(presence)="data">
				<el-checkbox v-model="data.item.presence" @change="changePresence()"></el-checkbox>
			</template>
			<template v-slot:cell(actions)="data">
				<i class="far fa-trash-alt" @click="deleteRow(data.index, annexeCovid.identificationPersonne.presencePersonneRisque)" style="color:red; cursor:pointer;"></i>
			</template>
		</b-table>
		<div class="btnAdd">
			<el-button @click.native.prevent="addRow(annexeCovid.identificationPersonne.presencePersonneRisque)" type="text" size="small"> Ajouter une ligne</el-button>
			<br/>
		</div>

		<h5>Sont également considérés comme à risques les personnes :</h5>
		<b-table ref="autrePersonneRisque" small responsive striped :items="annexeCovid.identificationPersonne.autrePersonneRisque" :fields="fieldsAutrePersonneRisque" show-empty>
			<template slot="empty" slot-scope="">
				<div style="text-align:center;">Ce tiers ne possède aucune autre personne à risque</div>
			</template>
			<template v-slot:cell(label)="data">
				<div contenteditable="true" class="" @blur="onEdit" @keydown.enter="endEdit" :editId="data.index" editTable="identificationPersonne" editType="autrePersonneRisque" editField='label' :dataInit="data.item.label">{{ data.item.label }}</div>
			</template>
			<template v-slot:cell(presence)="data">
				<el-checkbox v-model="data.item.presence" @change="changePresence()"></el-checkbox>
			</template>
			<template v-slot:cell(actions)="data">
				<i class="far fa-trash-alt" @click="deleteRow(data.index, annexeCovid.identificationPersonne.autrePersonneRisque)" style="color:red; cursor:pointer;"></i>
			</template>
		</b-table>
		<div class="btnAdd">
			<el-button @click.native.prevent="addRow(annexeCovid.identificationPersonne.autrePersonneRisque)" type="text" size="small"> Ajouter une ligne</el-button>
		</div>
	</div>
</template>

<script>
import scriptQse from '../../../script/script-qse.js';
export default {
	created: function () {
		this.debouncedChangeTextArea = _.debounce(this.changeTextArea, 500)
	},
	watch: {
		modalite(){
			this.debouncedChangeTextArea()
		},
		procedure(){
			this.debouncedChangeTextArea()
		},
		modification(){
			this.debouncedChangeTextArea()
		},
		protectionIndividuelle(){
			this.debouncedChangeTextArea()
		},
		protectionCollective(){
			this.debouncedChangeTextArea()
		},
		modificationOrga(){
			this.debouncedChangeTextArea()
		},
		mesuresGenerales(){
			this.debouncedChangeTextArea()
		},
  },
	data(){
		return {
			fieldsPresencePersonneRisque:[
				{key:"label", label:"Titre"},
				{key:"presence", label:"Présence"},
				{key:"actions", label:""},
			],
			fieldsAutrePersonneRisque:[
				{key:"label", label:"Titre"},
				{key:"presence", label:"Présence"},
				{key:"actions", label:""},
			]
		}
	},
	computed:{
		annexeCovid(){
			return this.$store.state.annexeCovid
		},
		modalite(){
			return this.$store.state.annexeCovid.informationInterne.modalite
		},
		procedure(){
			return this.$store.state.annexeCovid.casContamination.procedure
		},
		modification(){
			return this.$store.state.annexeCovid.preventionTechnique.modification
		},
		protectionIndividuelle(){
			return this.$store.state.annexeCovid.preventionTechnique.protectionIndividuelle
		},
		protectionCollective(){
			return this.$store.state.annexeCovid.preventionTechnique.protectionCollective
		},
		modificationOrga(){
			return this.$store.state.annexeCovid.preventionOrga.modification
		},
		mesuresGenerales(){
			return this.$store.state.annexeCovid.preventionOrga.mesuresGenerales
		},
	},
	methods:{
		getExtrafieldsDefault: scriptQse.getExtrafieldsDefault,
		reloadDefault(){
			if(confirm("Voulez-vous recharger les personnes à risques par défaut ?")){
				this.getExtrafieldsDefault()
				this.annexeCovid.identificationPersonne = this.$store.state.identificationPersonneDefault
				this.$store.commit('updateAnnexeCovid', this.annexeCovid)
				this.updateExtrafieldsQse(this.$store.state.infoSoc.id)
			}
		},
		updateExtrafieldsQse: scriptQse.updateExtrafieldsQse,
		deleteRow(index, rows) {
			if(confirm("Voulez-vous supprimer cette présence ?")){
				rows.splice(index, 1);
				this.$store.commit("updateAnnexeCovid", this.annexeCovid)
				this.updateExtrafieldsQse(this.$store.state.infoSoc.id)
			}
		},
		addRow(rows) {
			rows.push({
				'id': Date.now(),
				'label':'Saisir un titre', 
				'presence': false,
			})
			this.$store.commit("updateAnnexeCovid", this.annexeCovid)
			this.updateExtrafieldsQse(this.$store.state.infoSoc.id)
		},
		onEdit (evt) {
			var initValue = evt.target.getAttribute('dataInit')
			var newValue = evt.target.innerText
			var editId = evt.target.getAttribute('editId')
			var editField = evt.target.getAttribute('editField')
			var editType = evt.target.getAttribute('editType')
			var editTable = evt.target.getAttribute('editTable')

			if (newValue !== initValue) {
				this.annexeCovid[editTable][editType][editId][editField] = newValue 
				this.$store.commit("updateAnnexeCovid", this.annexeCovid)
				evt.target.setAttribute('dataInit', newValue)
				this.updateExtrafieldsQse(this.$store.state.infoSoc.id)
			}
		},
		endEdit (evt) {
			evt.target.blur(evt)	
		},

		onEditSituation (evt) {
			var initValue = evt.target.getAttribute('dataInit')
			var newValue = evt.target.innerText
			var editField = evt.target.getAttribute('editField')
			var editTable = evt.target.getAttribute('editTable')
			var editType = evt.target.getAttribute('editType')
			var editPublic = evt.target.getAttribute('editPublic')

			if (newValue !== initValue) {
				this.annexeCovid[editTable][editPublic][editType][editField] = newValue 
				this.$store.commit("updateAnnexeCovid", this.annexeCovid)
				evt.target.setAttribute('dataInit', newValue)
				this.updateExtrafieldsQse(this.$store.state.infoSoc.id)
			}
		},
		endEditSituation (evt) {
			evt.target.blur(evt)	
		},

		onEditAnnexe (evt) {
			var initValue = evt.target.getAttribute('dataInit')
			var newValue = evt.target.innerText
			var editField = evt.target.getAttribute('editField')
			var editTable = evt.target.getAttribute('editTable')

			if (newValue !== initValue) {
				this.annexeCovid[editTable][editField] = newValue 
				this.$store.commit("updateAnnexeCovid", this.annexeCovid)
				evt.target.setAttribute('dataInit', newValue)
				this.updateExtrafieldsQse(this.$store.state.infoSoc.id)
			}
		},
		endEditAnnexe (evt) {
			evt.target.blur(evt)	
		},
		changePresence () {
			this.updateExtrafieldsQse(this.$store.state.infoSoc.id)
		},
		changeTextArea () {
			this.updateExtrafieldsQse(this.$store.state.infoSoc.id)
		}
	}
}
</script>

<style scoped>
	.editme{
		border: dashed;
		border-width: thin;
	}

	.paragraphe{
		padding-left: 2em;
	}

	.btnAdd{
		text-align: center;
	}
</style>