<template>
	<div>
		<div class="centerCotation">
			<h3>Méthode de cotation des risques professionnels</h3>
			<h4>Cotation = Ps * G * D</h4>
		</div>
		<br>
		<div class="centerCotation">
			<h5>PS : Probabilité de survenance</h5>
			<b-table ref="tablePS" small responsive striped :items="cotationDefault.ps" :fields="fieldsCotation"  show-empty>
                <template slot="empty" slot-scope="">
                  	<div style="text-align:center;">Ce tiers ne possède aucune cotation</div>
                </template>
                <template v-slot:cell(note)="data">
                  	<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" editTable="ps" :editId="data.index" editField='note' :dataInit="data.item.note">{{ data.item.note }}</div>
                </template>
                <template v-slot:cell(desc)="data">
                  	<div contenteditable="true" class="editme" @blur="onEditClass" @keydown.enter="endEditClass" editTable="ps" :editId="data.index" editField='desc' :dataInit="data.item.desc">{{ data.item.desc }}</div>
                </template>
				<template v-slot:cell(actions)="data">
                  	<i class="far fa-trash-alt" @click="deleteRow(data.index, cotationDefault.ps)" style="color:red; cursor:pointer;"></i>
                </template>
			</b-table>
			<el-button @click.native.prevent="addRow(cotationDefault.ps)" type="text" size="small"> Ajouter une ligne</el-button>
		</div>
		<br>
		<div class="centerCotation">
			<h5>G : Gravité</h5>
			<b-table ref="tableG" small responsive striped :items="cotationDefault.g" :fields="fieldsCotation"  show-empty>
                <template slot="empty" slot-scope="">
                  	<div style="text-align:center;">Ce tiers ne possède aucune cotation</div>
                </template>
                <template v-slot:cell(note)="data">
                  	<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" editTable="g" :editId="data.index" editField='note' :dataInit="data.item.note">{{ data.item.note }}</div>
                </template>
                <template v-slot:cell(desc)="data">
                  	<div contenteditable="true" class="editme" @blur="onEditClass" @keydown.enter="endEditClass" editTable="g" :editId="data.index" editField='desc' :dataInit="data.item.desc">{{ data.item.desc }}</div>
                </template>
				<template v-slot:cell(actions)="data">
					<i class="far fa-trash-alt" @click="deleteRow(data.index, cotationDefault.g)" style="color:red; cursor:pointer;"></i>
                </template>
			</b-table>
			<el-button @click.native.prevent="addRow(cotationDefault.g)" type="text" size="small"> Ajouter une ligne</el-button>
		</div>
		<br>
		<div class="centerCotation">
			<h5>D : Détection</h5>
			<b-table ref="tableD" small responsive striped :items="cotationDefault.d" :fields="fieldsCotation"  show-empty>
                <template slot="empty" slot-scope="">
                  	<div style="text-align:center;">Ce tiers ne possède aucune cotation</div>
                </template>
                <template v-slot:cell(note)="data">
                  	<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" editTable="d" :editId="data.index" editField='note' :dataInit="data.item.note">{{ data.item.note }}</div>
                </template>
                <template v-slot:cell(desc)="data">
                  	<div contenteditable="true" class="editme" @blur="onEditClass" @keydown.enter="endEditClass" editTable="d" :editId="data.index" editField='desc' :dataInit="data.item.desc">{{ data.item.desc }}</div>
                </template>
				<template v-slot:cell(actions)="data">
                  	<i class="far fa-trash-alt" @click="deleteRow(data.index, cotationDefault.d)" style="color:red; cursor:pointer;"></i>
                </template>
			</b-table>
			<el-button @click.native.prevent="addRow(cotationDefault.d)" type="text" size="small"> Ajouter une ligne</el-button>
		</div>
		<br>
		<div class="centerCotation">
			<h4>Classification du risque Brut</h4>
			<b-table ref="tableBrut" small responsive striped :items="cotationDefault.brut" :fields="fieldsCotationRisque"  show-empty>
                <template slot="empty" slot-scope="">
                  	<div style="text-align:center;">Ce tiers ne possède aucune cotation</div>
                </template>
                <template v-slot:cell(note)="data">
                  	<div contenteditable="true" class="editme" @blur="onEditClass" @keydown.enter="endEditClass" editTable="brut" :editId="data.index" editField='note' :dataInit="data.item.note">{{ data.item.note }}</div>
                </template>
                <template v-slot:cell(desc)="data">
                  	<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" editTable="brut" :editId="data.index" editField='desc' :dataInit="data.item.desc">{{ data.item.desc }}</div>
                </template>
				<template v-slot:cell(cotationMin)="data">
                  	<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" editTable="brut" :editId="data.index" editField='cotationMin' :dataInit="data.item.cotationMin">{{ data.item.cotationMin }}</div>
                </template>
				<template v-slot:cell(cotationMax)="data">
                  	<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" editTable="brut" :editId="data.index" editField='cotationMax' :dataInit="data.item.cotationMax">{{ data.item.cotationMax }}</div>
                </template>
				<template v-slot:cell(actions)="data">
                  	<i class="far fa-trash-alt" @click="deleteRow(data.index, cotationDefault.brut)" style="color:red; cursor:pointer;"></i>
                </template>
			</b-table>
			<el-button @click.native.prevent="addRowClassification(cotationDefault.brut)" type="text" size="small"> Ajouter une ligne</el-button>
		</div>
		<br>
		<div class="centerCotation">
			<h4>Classification du risque Résiduel</h4>
			<b-table ref="tableResiduel" small responsive striped :items="cotationDefault.residuel" :fields="fieldsCotationRisque"  show-empty>
                <template slot="empty" slot-scope="">
                  	<div style="text-align:center;">Ce tiers ne possède aucune cotation</div>
                </template>
                <template v-slot:cell(note)="data">
                  	<div contenteditable="true" class="editme" @blur="onEditClass" @keydown.enter="endEditClass" editTable="residuel" :editId="data.index" editField='note' :dataInit="data.item.note">{{ data.item.note }}</div>
                </template>
                <template v-slot:cell(desc)="data">
                  	<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" editTable="residuel" :editId="data.index" editField='desc' :dataInit="data.item.desc">{{ data.item.desc }}</div>
                </template>
				<template v-slot:cell(cotationMin)="data">
                  	<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" editTable="residuel" :editId="data.index" editField='cotationMin' :dataInit="data.item.cotationMin">{{ data.item.cotationMin }}</div>
                </template>
				<template v-slot:cell(cotationMax)="data">
                  	<div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" editTable="residuel" :editId="data.index" editField='cotationMax' :dataInit="data.item.cotationMax">{{ data.item.cotationMax }}</div>
                </template>
				<template v-slot:cell(actions)="data">
                  	<i class="far fa-trash-alt" @click="deleteRow(data.index, cotationDefault.residuel)" style="color:red; cursor:pointer;"></i>
                </template>
			</b-table>
			<el-button @click.native.prevent="addRowClassification(cotationDefault.residuel)" type="text" size="small"> Ajouter une ligne</el-button>
		</div>
		<br>
	</div>
</template>

<script>
import scriptQse from '../../../script/script-qse.js';
export default {
	data () {
		return {
			fieldsCotation: [
				{ key: 'note', label: 'Note' },
				{ key: 'desc', label: 'Description' },
				{ key: 'actions', label: '' }
			],
			fieldsCotationRisque: [
				{ key: 'note', label: 'Note' },
				{ key: 'desc', label: 'Description' },
				{ key: 'cotationMin', label: 'Cotation Min' },
				{ key: 'cotationMax', label: 'Cotation Max' },
				{ key: 'actions', label: '' }
			],
		}
	},
	methods: {
		updateExtrafieldsUserQse: scriptQse.updateExtrafieldsUserQse,
		remplacerVirgulePoint(decimal) {
			return parseFloat((decimal+"").replace(",","."))
		},
		deleteRow(index, rows) {
			if(confirm("Voulez-vous supprimer cette cotation ?")){
				rows.splice(index, 1);
				this.$store.commit("updateCotationDefault", this.cotationDefault)
				this.updateExtrafieldsUserQse()
			}
		},
		addRow(rows) {
			rows.push({
				'note':'Saisir une note',
				'desc':'Saisir une description'
			})
			this.$store.commit("updateCotationDefault", this.cotationDefault)
			this.updateExtrafieldsUserQse()
		},
		addRowClassification(rows) {
			rows.push({
				'note':'Saisir une note',
				'desc':'Saisir une description',
				'cotationMin' : 'Saisir une cotation min',
				'cotationMax' : 'Saisir une cotation max'
			})
			this.$store.commit("updateCotationDefault", this.cotationDefault)
			this.updateExtrafieldsUserQse()
		},
		onEdit (evt) {
			var initValue = evt.target.getAttribute('dataInit')
			var newValue = this.remplacerVirgulePoint(evt.target.innerText)
			var editId = evt.target.getAttribute('editId')
			var editField = evt.target.getAttribute('editField')
			var editTable = evt.target.getAttribute('editTable')
			if (newValue !== initValue) {
				this.cotationDefault[editTable][editId][editField] = newValue
				this.$store.commit("updateCotationDefault", this.cotationDefault)
				evt.target.setAttribute('dataInit', newValue)
				this.updateExtrafieldsUserQse()
			}
		},

		endEdit (evt) {
			evt.target.blur(evt)
		},
		onEditClass (evt) {
			var initValue = evt.target.getAttribute('dataInit')
			var newValue = evt.target.innerText
			var editId = evt.target.getAttribute('editId')
			var editField = evt.target.getAttribute('editField')
			var editTable = evt.target.getAttribute('editTable')

			if (newValue !== initValue) {
				this.cotationDefault[editTable][editId][editField] = newValue
				this.$store.commit("updateCotationDefault", this.cotationDefault)
				evt.target.setAttribute('dataInit', newValue)
				this.updateExtrafieldsUserQse()
			}
		},

		endEditClass (evt) {
			evt.target.blur(evt)
		}
	},
	computed: {
		cotationDefault() {
			return this.$store.state.cotationDefault
		}
	},
	mounted () {
	}
}
</script>

<style>
.centerCotation{
	text-align: center;
}
</style>
