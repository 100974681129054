<template>
<div class="blocInfo">
  <table style="margin-bottom:25px;">
  <tr>
      <td rowspan="5" class="tiersTitle faTiers"><i class="fas fa-user-circle fa-2x" style="color:mediumslateblue;"></i></td>
      <td colspan="2" class="tiersTitle"><strong><div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="infosTiers.id" editType="TIERS" editField='name' :dataInit="infosTiers.name">{{ infosTiers.name }}</div></strong></td>
  </tr>
  <tr v-if="this.infosTiers.typent_id == 8">
    <td class="tdTiers"><i class="fas fa-portrait tiersTitle"></i></td>
    <td>{{ this.infosTiers.code_client }} - Client Particulier</td>
  </tr>
  <tr v-else-if="this.infosTiers.fournisseur == 1">
    <td class="tdTiers"><i class="fas fa-portrait tiersTitle"></i></td>
    <td>{{ this.infosTiers.code_fournisseur }} - Fournisseur</td>
  </tr>
  <tr v-else>
    <td class="tdTiers"><i class="fas fa-portrait tiersTitle"></i></td>
    <td>{{ this.infosTiers.code_client }} - Client professionnel</td>
  </tr>
  <tr>
    <td class="tdTiers"><i class="fas fa-map-marked-alt"></i></td>
    <td>
      <div style="display: inline-block; minWidth:25px; margin-right: 5px;"><div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="infosTiers.id" editType="TIERS" editField='address' :dataInit="infosTiers.address">{{ infosTiers.address }}</div></div>
      <div style="display: inline-block; minWidth:25px; margin-right: 5px;"><div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="infosTiers.id" editType="TIERS" editField='zip' :dataInit="infosTiers.zip">{{ infosTiers.zip }}</div></div>
      <div style="display: inline-block; minWidth:25px; margin-right: 5px;"><div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="infosTiers.id" editType="TIERS" editField='town' :dataInit="infosTiers.town">{{ infosTiers.town }}</div></div>
    </td>
  </tr>
  <tr>
    <td class="tdTiers"><b-link :href="'tel:' + infosTiers.phone"><i class="fas fa-mobile-alt tiersTitle" v-b-popover.hover="'Appeler'"></i></b-link></td>
    <td><div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="infosTiers.id" editType="TIERS" editField='phone' :dataInit="infosTiers.phone">{{ infosTiers.phone }}</div></td>
  </tr>
  <tr>
    <td class="tdTiers"><b-link :href="'mailto:' + infosTiers.email"><i class="fas fa-at tiersTitle" v-b-popover.hover="'Envoyer un email'"></i></b-link></td>
    <td><div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="endEdit" :editId="infosTiers.id" editType="TIERS" editField='email' :dataInit="infosTiers.email">{{ infosTiers.email }}</div></td>
  </tr>
  </table>
</div>
</template>

<script>
import script from '../../script/script.js'

export default {
	props:[
		'infosTiers'
	],
  data () {
    return {
    }
  },

  mounted: function () {
  },

  methods: {
    updateFields: script.updateFields,

    onEdit (evt) {
      var initValue = evt.target.getAttribute('dataInit')
      var newValue = evt.target.innerText
      var editId = evt.target.getAttribute('editId')
      var editField = evt.target.getAttribute('editField')
      var editType = evt.target.getAttribute('editType')

      if (newValue !== initValue) {
        this.updateFields(editType, editId, editField, newValue)
        evt.target.setAttribute('dataInit', newValue)
      }
    },

    endEdit (evt) {
      evt.target.blur()
    }
  }
}

</script>
<style>
.tiersTitle {
 font-size:1.20em;
}

.faTiers {
  width:50px;
  text-align:center;
  vertical-align:top;
}

.tdTiers {
  text-align:center;
  padding-right:10px;
}
</style>
