import conf from '../config/api.js';
import Vue from 'vue';
import vuexStorage from '../store/index.js'

//Fonction génération de toaster erreur
function toasterError(content, title){
  new Vue().$bvToast.toast(content, {
    title: title,
    toaster: 'b-toaster-bottom-right',
    variant: 'danger',
    autoHideDelay: 2500,
    appendToast: true,
    solid: true
  })
}

//Fonction génération de toaster erreur
function toasterSuccess(content, title){
	new Vue().$bvToast.toast(content, {
		title: title,
		toaster: 'b-toaster-bottom-right',
		variant: 'success',
		autoHideDelay: 2500,
		appendToast: true,
		solid: true
	})
}

//Fonction d'update des extrafields QSE Tiers
function updateExtrafieldsQse(idTiers, toaster) {
	if( typeof(toaster) == 'undefined' ){
		toaster = true
	}
	vuexStorage.commit('updateDateMaj', Date.now())
	if(!vuexStorage.state.dateCreation){
		vuexStorage.commit('updateDateCreation', vuexStorage.state.dateMaj)
	}
	var payload = {
		"options_cotation": JSON.stringify(vuexStorage.state.cotation),
		"options_unite": JSON.stringify(vuexStorage.state.unites),
		"options_donnees": JSON.stringify(vuexStorage.state.donnees),
		"options_familles_risques": JSON.stringify(vuexStorage.state.famillesRisques),
		"options_auto_increment_unite": vuexStorage.state.autoIncrementUnite,
		"options_auto_increment_famille": vuexStorage.state.autoIncrementFamille,
		"options_auto_increment_risque": vuexStorage.state.autoIncrementRisque,
		"options_auto_increment_situation": vuexStorage.state.autoIncrementSituation,
		"options_auto_increment_penibilite": vuexStorage.state.autoIncrementPenibilite,
		"options_auto_increment_prog_action": vuexStorage.state.autoIncrementProgAction,
		"options_auto_increment_checklist_generale": vuexStorage.state.autoIncrementChecklist,
		"options_infos_generales": JSON.stringify(vuexStorage.state.infosGenerales),
		"options_situations": JSON.stringify(vuexStorage.state.situations),
		"options_prog_action": JSON.stringify(vuexStorage.state.progAction),
		"options_checklist_generale": JSON.stringify(vuexStorage.state.checklistGenerale),
		"options_annexe_covid": JSON.stringify(vuexStorage.state.annexeCovid),
		"options_date_maj": vuexStorage.state.dateMaj,
		"options_date_creation": vuexStorage.state.dateCreation
	}
	this.axios.post(conf.server+"/api/updateExtrafieldsQse/"+idTiers,payload,{withCredentials:true})
	.then(() => {
		if(toaster){
			toasterSuccess(`Les modifications ont été enregistrées`,'Modification rapide')
		}
	})
	.catch((error) => {
		if(error.response != undefined){
			if(toaster){
				console.log(error.response)
				if(error.response.status == 401 || error.response.status == 400){
					this.$router.push('/');
				}
				toasterError(`Impossible de mettre à jour les informations`,'Mise à jour')
			}
		}else{
			if(toaster){
				toasterError(`Le service est momentanément indisponible`,'Service indisponible')
			}
		}
	})
}

//Fonction d'update des extrafields Default QSE
function updateExtrafieldsUserQse() {
	var payload = {
		"options_cotation_default": JSON.stringify(vuexStorage.state.cotationDefault),
		"options_familles_risques_default": JSON.stringify(vuexStorage.state.famillesRisquesDefault),
		"options_auto_increment_famille_default": vuexStorage.state.autoIncrementFamilleDefault,
		"options_auto_increment_risque_default": vuexStorage.state.autoIncrementRisqueDefault,
		"options_auto_increment_situations_default": vuexStorage.state.autoIncrementSituationDefault,
		"options_auto_increment_penibilite_default": vuexStorage.state.autoIncrementPenibiliteDefault,
		"options_checklist_generale_default": JSON.stringify(vuexStorage.state.checklistGeneraleDefault),
		"options_personne_risque_default": JSON.stringify(vuexStorage.state.identificationPersonneDefault),
		"options_situations_default": JSON.stringify(vuexStorage.state.situationsDefault),
	}
	this.axios.post(conf.server+"/api/updateExtrafieldsUserQse",payload,{withCredentials:true})
	.then(() => {
		toasterSuccess(`Les modifications ont été enregistrées`,'Modification rapide')
	})
	.catch((error) => {
		if(error.response != undefined){
			if(error.response.status == 401 || error.response.status == 400){
				this.$router.push('/');
			}
			toasterError(`Impossible de mettre à jour les informations`,'Mise à jour')
		}else{
			toasterError(`Le service est momentanément indisponible`,'Service indisponible')
		}
	})
}

//Fonction de récupération des extrafields Default QSE
function getExtrafieldsDefault() {
	this.axios.get(conf.server+"/api/getExtrafieldsDefault",{withCredentials:true})
	.then((response) => {
		var cotationDefault = response.data.options_cotation_default
		var famillesRisquesDefault = response.data.options_familles_risques_default
		var autoIncrementFamilleDefault = response.data.options_auto_increment_famille_default
		var autoIncrementRisqueDefault = response.data.options_auto_increment_risque_default
		var checklistGeneraleDefault = response.data.options_checklist_generale_default
		var identificationPersonneDefault = response.data.options_personne_risque_default
		var situationsDefault = response.data.options_situations_default
		var autoIncrementPenibiliteDefault = response.data.options_auto_increment_penibilite_default
		var autoIncrementSituationDefault = response.data.options_auto_increment_situations_default

		if(cotationDefault){
			this.$store.commit("updateCotationDefault", JSON.parse(cotationDefault))
		}
		if(famillesRisquesDefault){
			this.$store.commit("initializeFamillesRisquesDefault", JSON.parse(famillesRisquesDefault))
		}

		if(autoIncrementFamilleDefault){
			this.$store.commit('updateAutoIncrementFamilleDefault', autoIncrementFamilleDefault)
		}

		if(autoIncrementRisqueDefault){
			this.$store.commit('updateAutoIncrementRisqueDefault', autoIncrementRisqueDefault)
		}

		if(autoIncrementPenibiliteDefault){
			this.$store.commit('updateAutoIncrementPenibiliteDefault', autoIncrementPenibiliteDefault)
		}

		if(autoIncrementSituationDefault){
			this.$store.commit('updateAutoIncrementSituationDefault', autoIncrementSituationDefault)
		}

		if(identificationPersonneDefault){
			this.$store.commit('updateIdentificationPersonneRisqueDefault', JSON.parse(identificationPersonneDefault))
		}

		if(situationsDefault){
			this.$store.commit('initializeSituationsDefault', JSON.parse(situationsDefault))
		}

		if(checklistGeneraleDefault){
			this.$store.commit('updateChecklistGeneraleDefault', JSON.parse(checklistGeneraleDefault))
		}
		else {
			let checklistGenerale = {
				affichageObligatoire:[],
				dispositionApplicable:[],
				locauxEntreprise:[],
				organisationGestionSecurite:[],
				organisationSecours:[],
				accidentTravail:[]
			}
			this.$store.commit('updateChecklistGeneraleDefault', checklistGenerale)
		}
	})
	.catch((error) => {
		if(error.response != undefined){
			if(error.response.status == 401 || error.response.status == 400){
				this.$router.push('/');
			}
			toasterError(`Impossible de charger les informations`,'Chargement des paramètres par défaut')
		}else{
			toasterError(`Le service est momentanément indisponible`,'Service indisponible')
		}
	})
}

//Fonction création xlsx
function createXlsx() {
	let payload = {
		infosGenerales : vuexStorage.state.infosGenerales,
		cotation : vuexStorage.state.cotation,
		famillesRisques : vuexStorage.state.famillesRisques,
		unites : vuexStorage.state.unites,
		donnees : vuexStorage.state.donnees,
		situations : vuexStorage.state.situations,
		infoSoc : vuexStorage.state.infoSoc,
		dateMaj : parseInt(vuexStorage.state.dateMaj,10),
		dateCreation : parseInt(vuexStorage.state.dateCreation,10),
		annexeCovid: vuexStorage.state.annexeCovid,
		checklistGenerale: vuexStorage.state.checklistGenerale,
		progAction: vuexStorage.state.progAction
	}

	this.axios.post(conf.server+"/api/getXlsx",payload,{withCredentials:true, responseType:'blob'})
	.then((response) => {
		var link = document.createElement('a');
        link.download = 'DUERP '+ payload.infoSoc.name+'.xlsx';
        link.href = URL.createObjectURL(response.data);
        document.documentElement.appendChild(link).click();
        document.documentElement.removeChild(link);
	})
	.catch((error) => {
		console.dir(error)
		if(error.response != undefined){
			if(error.response.status == 401 || error.response.status == 400){
				this.$router.push('/');
			}
			toasterError(`Impossible de charger les informations`,'Chargement des paramètres par défaut')
		}else{
			toasterError(`Le service est momentanément indisponible`,'Service indisponible')
		}
	})
}

export default{
	updateExtrafieldsQse,
	updateExtrafieldsUserQse,
	getExtrafieldsDefault,
	createXlsx
}
