<template>
<div class="overflow-auto" style="margin:15px;">
  <b-form-group>
    <b-input-group class="input-recherche" style="float:left;">
      <b-form-input v-model="filter" placeholder="Rechercher dans tous les champs..."></b-form-input>
      <b-input-group-append>
        <b-button :disabled="!filter" @click="filter = ''" size="sm">Effacer</b-button>
      </b-input-group-append>
    </b-input-group>
    <b-button v-b-modal.modalAjoutTiersMasse class="btnAjoutTiers" style="float:right;"><span class="fas fa-plus"></span> NOUVEAU TIERS</b-button>
  </b-form-group>
  <b-form-group>
    <b-input-group class="filters">
      <b-form-input placeholder="Nom" v-model="filterNameTiers" class="filterNameTiers"></b-form-input>
      <b-form-input placeholder="Code" v-model="filterCodeTiers" class="filterCodeTiers"></b-form-input>
      <b-form-input placeholder="Adresse" v-model="filterAddressTiers" class="filterAdressTiers"></b-form-input>
      <b-form-input placeholder="CP" v-model="filterZipTiers" class="filterZipTiers"></b-form-input>
      <b-form-input placeholder="Ville" v-model="filterTownTiers" class="filterTownTiers"></b-form-input>
      <b-form-input placeholder="Téléphone" v-model="filterPhoneTiers" class="filterPhoneTiers"></b-form-input>
      <b-form-input placeholder="Adresse email" v-model="filterMailTiers" class="filterMailTiers"></b-form-input>
      <b-form-select v-model="filterTypeTiers" :options="optionType" class="filterTypeTiers"></b-form-select>
      <b-form-select v-model="filterStatusTiers" :options="optionStatus" class="filterStatusTiers"></b-form-select>
      <b-button :disabled="!filterNameTiers&&!filterCodeTier&&!filterAdressTiers&&!filterZipTiers&&!filterTownTiers&&!filterPhoneTiers&&!filterMailTiers&&!filterTypeTier&&!filterStatusTiers" @click="filters" size="sm">Effacer</b-button>
    </b-input-group>
  </b-form-group>
  <b-table id="tblTiers" style="font-size:13px; text-align:left;" small responsive striped hover :items="filteredTiers" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" @filtered="onFiltered">
    <template v-slot:cell(name)="data">
      <b-link :to="'/detailsTiers/' + data.item.id">{{ data.item.name }}</b-link>
    </template>
    <template v-slot:cell(code)="data">
      <span v-if="data.item.fournisseur==1">{{ data.item.code_fournisseur }}</span>
      <span v-else>{{ data.item.code_client }}</span>
    </template>
    <template v-slot:cell(phone)="data">
      <b-link :href="'tel:' + data.item.phone">{{ data.item.phone }}</b-link>
    </template>
    <template v-slot:cell(email)="data">
      <div style="display: inline-block;"><b-link :href="'mailto:' + data.item.email">{{ data.item.email }}</b-link></div>
    </template>
    <template v-slot:cell(type)="data">
      <span v-if="data.item.typent_id==8">Particulier</span>
      <span v-else>Professionnel</span>
    </template>
    <!-- <template v-slot:cell(statut)="data">
      <span v-if="(data.item.fournisseur==0)&&(data.item.client==1)">Client</span>
      <span v-else-if="(data.item.fournisseur==0)&&(data.item.client==2)">Prospect</span>
      <span v-else-if="(data.item.fournisseur==1)&&(data.item.client==0)">Fournisseur</span>
      <span v-else-if="(data.item.fournisseur==1)&&(data.item.client==1)">Client/Fournisseur</span>
      <span v-else-if="(data.item.fournisseur==1)&&(data.item.client==2)">Prospect/Fournisseur</span>
      <span v-else>Autres</span>
    </template> -->
    <template v-slot:cell(delete)="data">
      <a v-on:click.stop="deleteTiers(data.item.id)" style='text-align:center;'><span style="cursor:pointer; color:red;" class='far fa-trash-alt' v-b-popover.hover="'Supprimer tiers'"></span></a>
    </template>
  </b-table>
  <b-pagination-nav v-model="currentPage" :per-page="perPage" aria-controls="tblTiers" size="sm" :link-gen="linkGen" :number-of-pages="numberOfPages" use-router></b-pagination-nav>
</div>
</template>

<script>
import script from '../../script/script.js';
// import conf from '../../config/api.js';
// import { ModelSelect } from 'vue-search-select'

export default {
  data () {
    return {
      fields: [
        {key: 'name', label: 'TIERS', sortable: true, class:"nameTiers"},
        {key: 'code', label: 'CODE', sortable: true, class:"codeTiers"},
        {key: 'address', label: 'ADRESSE', sortable: true, class:"addressTiers"},
        {key: 'zip', label: 'CP', sortable: true, class:"zipTiers"},
        {key: 'town', label: 'VILLE', sortable: true, class:"townTiers"},
        {key: 'phone', label: 'TELEPHONE', sortable: true, class:"phoneTiers"},
        {key: 'email', label: 'EMAIL', sortable: true, class:"emailTiers"},
        {key: 'type', label: 'TYPE', sortable: true, class:"typeTiers"},
        // {key: 'statut', label: 'STATUT', sortable: true, class:"statutTiers"},
        {key: 'delete', label: '', class:"deleteTiers"}
      ],
      tiers: [],
      numberOfPages: 100,
      currentPage: null,
      perPage: 22,
      totalRows: 1,
      filter: null,
      clientsExtranet:{},
      re: {
        phone_number: /^\+?(?:\d+(?:\s*\(\d+\))?)\s*\d[\s\d.-]*\d$/,
        email: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      },
      querysearch: "",
      query: [],
      error: [],
      queryflag: false,
      submit_enabled: true,
      optionsFormeJuri: [],
      form: {
        type: "Professionnel",
        siret: "",
        siren: "",
        forme_juridique: "",
        denomination: "",
        adresse_l3: "",
        adresse_l4: "",
        adresse_l5: "",
        zip: "",
        city: "",
        country: "",
        phone_number_entreprise: "",
        phone_number_1_contact: "",
        phone_number_2_contact: "",
        fax: "",
        naf: "",
        tva: "",
        mail: "",
        mail_contact: "",
        civility: "",
        firstname: "",
        lastname: "",
        function: "",
      },
      options_form: {
        civility: [
          {value: "MR", text: "M."},
          {value: "MME", text: "Mme"},
        ],
      },
      filterNameTiers:"",
      filterCodeTiers:"",
      filterAddressTiers:"",
      filterZipTiers:"",
      filterTownTiers:"",
      filterPhoneTiers:"",
      filterMailTiers:"",
      filterTypeTiers:"0",
      filterStatusTiers:"0",
      optionType:[
        {value: 0, text:"Type"},
        {value: 8, text:"Particulier"},
        {value: 1, text:"Professionnel"}
      ],
      optionStatus:[
        {value: 0, text:"Statut"},
        {value: 1, text:"Client"},
        {value: 2, text:"Prospect"},
        {value: 3, text:"Fournisseur"},
        {value: 4, text:"Client/Fournisseur"},
        {value: 5, text:"Prospect/Fournisseur"}
      ]
    }
  },
  watch: {
    "form.siret": function() {
      let siret = this.form.siret
      if ((siret.length==14) && (parseInt(siret) == siret) && (this.check_luhn(siret))) {
        this.axios.get("https://entreprise.data.gouv.fr/api/sirene/v1/siret/" + siret)
        .then(res => {
            let data = res.data.etablissement
            this.form.denomination = data.nom_raison_sociale.replace("*", " ").replace("/", "")// replace for fix an API error
            this.form.adresse_l3 = data.l3_normalisee
            this.form.adresse_l4 = data.l4_normalisee
            this.form.adresse_l5 = data.l5_normalisee
            this.form.zip = data.code_postal
            this.form.country = data.l7_normalisee
            this.form.city = data.libelle_commune
            this.form.naf = data.activite_principale
            this.form.siren = siret.slice(0, 9)
            this.form.tva = this.get_tva(this.form.siren)
          })
        .catch(() => {
            this.form.denomination = ""
            this.form.adresse_l3 =""
            this.form.adresse_l4 =""
            this.form.adresse_l5 =""
            this.form.zip = ""
            this.form.city = ""
            this.form.naf = ""
            this.form.tva = ""
        })
      }
      else {
        this.form.denomination = ""
        this.form.adresse_l3 =""
        this.form.adresse_l4 =""
        this.form.adresse_l5 =""
        this.form.zip = ""
        this.form.city = ""
        this.form.naf = ""
        this.form.tva = ""
      }
    },
    "form.lastname": function() {
        this.form.lastname = this.form.lastname.toUpperCase()
    },
    "form.firstname": function() {
        this.form.firstname = this.form.firstname[0].toUpperCase() + this.form.firstname.slice(1)
    },
    "form.adresse_l3": function() {
        this.form.adresse_l3 = this.form.adresse_l3.toUpperCase()
    },
    "form.adresse_l4": function() {
        this.form.adresse_l4 = this.form.adresse_l4.toUpperCase()
    },
    "form.adresse_l5": function() {
        this.form.adresse_l5 = this.form.adresse_l5.toUpperCase()
    },
    "form.city": function() {
        this.form.city = this.form.city.toUpperCase()
    }
  },
  computed: {
    is_professional() {
      return this.form.type === "Professionnel"
    },
    is_fournisseur() {
      return this.form.type === "Fournisseur"
    },
    filteredTiers(){
      var client = "";
      var fournisseur = "";
      var type = "";
      switch(this.filterStatusTiers){
        case 0:
          client = "";
          fournisseur = "";
          break;
        case 1:
          client = "1";
          fournisseur = "0";
          break;
        case 2:
          client = "2";
          fournisseur = "0";
          break;
        case 3:
          client="0"
          fournisseur = "1";
          break;
        case 4:
          client = "1";
          fournisseur = "1";
          break;
        case 5:
          client = "2";
          fournisseur = "1";
          break;
      }
      switch(this.filterTypeTiers){
        case 0:
          type = "";
          break;
        case 8:
          type = "8";
          break;
        case 1:
          type = "0";
          break;
      }
      var res = this.tiers.filter(tier => (tier.name.includes(this.filterNameTiers) || tier.name.includes(this.filterNameTiers.toUpperCase())) 
                                && (((tier.code_fournisseur||"").includes(this.filterCodeTiers) || (tier.code_fournisseur||"").includes(this.filterCodeTiers.toUpperCase())) || ((tier.code_client||"").includes(this.filterCodeTiers) || (tier.code_client||"").includes(this.filterCodeTiers.toUpperCase())))
                                && ((tier.address||"").includes(this.filterAddressTiers.toUpperCase()) || (tier.address||"").includes(this.filterAddressTiers)) 
                                && ((tier.zip||"").includes(this.filterZipTiers.toUpperCase()) || (tier.zip||"").includes(this.filterZipTiers))
                                && ((tier.town||"").includes(this.filterTownTiers.toUpperCase()) || (tier.town||"").includes(this.filterTownTiers))
                                && ((tier.phone||"").includes(this.filterPhoneTiers.toUpperCase()) || (tier.phone||"").includes(this.filterPhoneTiers))
                                && ((tier.email||"").includes(this.filterMailTiers.toUpperCase()) || (tier.email||"").includes(this.filterMailTiers))
                                && ((tier.client||"").includes(client) && (tier.fournisseur||"").includes(fournisseur)) 
                                && (tier.typent_id||"").includes(type));
      this.onFiltered(res);
      return res;
    }
  },
  mounted: function () {
    this.verifyRight();
    this.loadingClient();
  },

  methods: {
    resetForm() {
      this.form.siret = ""
      this.form.siren = ""
      this.form.forme_juridique = ""
      this.form.denomination = ""
      this.form.adresse_l3 = ""
      this.form.adresse_l4 = ""
      this.form.adresse_l5 = ""
      this.form.zip = ""
      this.form.city = ""
      this.form.country = ""
      this.form.phone_number_entreprise = ""
      this.form.phone_number_1_contact = ""
      this.form.phone_number_2_contact = ""
      this.form.fax = ""
      this.form.naf = ""
      this.form.tva = ""
      this.form.mail = ""
      this.form.mail_contact = ""
      this.form.civility = ""
      this.form.firstname = ""
      this.form.lastname = ""
      this.form.function = ""
      this.queryflag = false
      this.querysearch = ""
      this.query = []
    },
    selectQuery(element) {
        this.form.siret = element.siret
        this.queryflag = true
    },
    queryByFullText(query) {
        this.axios.get("https://entreprise.data.gouv.fr/api/sirene/v1/full_text/" + query + "?per_page=100")
        .then(res => {
            this.query = res.data.etablissement
        })
        .catch(() => {
            this.query = []
        })
    },

    verifyRight: script.verifyRight,
    loadingClient: script.loadingClient,
    onResetTiers () {
      this.form.type="Particulier"
      this.form.siret=""
      this.form.siren=""
      this.form.forme_juridique=""
      this.form.denomination=""
      this.form.adresse_l3=""
      this.form.adresse_l4=""
      this.form.adresse_l5=""
      this.form.zip=""
      this.form.city=""
      this.form.country = ""
      this.form.phone_number_entreprise=""
      this.form.phone_number_1_contact=""
      this.form.phone_number_2_contact=""
      this.form.fax=""
      this.form.naf=""
      this.form.tva=""
      this.form.mail=""
      this.form.mail_contact=""
      this.form.civility=""
      this.form.firstname=""
      this.form.lastname=""
      this.form.function=""
    },

    linkGen (pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },

    onFiltered (filteredItems) {
      this.numberOfPages = filteredItems.length / this.perPage
      this.currentPage = 1
    },
    get_tva(siren) {
      return "FR" + (12 + 3*(siren%97))%97 + siren
    },
    check_luhn(n) {
      let cks = 0
      let result
      n = String(n) // n must be a string
      for (var i = 0; i < n.length; i++) {
        result = parseInt(n[n.length-1-i])*(1+i%2)

        cks += result > 9 ? result-9 : result
        n[i]
      }
      return cks%10 == 0
    },
    deleteTiers : script.deleteTiers,
    // loadFormeJuri: script.loadFormeJuri
  }
}
</script>
<style>
.query_result_card {
  margin-left: 5em;
  margin-right: 5em;
  margin-bottom: 1em;
  border-bottom: solid 1px;
  cursor: pointer;
}

.query_result_card:last-child {
  border-bottom: none;
}

.input-recherche {
  width:50%;
}

#modalAjoutTiers label.col-form-label {
  font-size: 0.8em;
}

#modalAjoutTiers label.col-form-label.required:after{
  content: " *";
  color: red;
}

#modalAjoutTiers input.form-control {
  height: 30px;
  margin-bottom: 5px;
  font-size: 0.8em;
}

#modalAjoutTiers input.form-control-lg {
  height: 50px;
  font-size: 1.2em;
  margin-bottom: 0;
}


.nameTiers{
  width: 25em;
  max-width: 20em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.codeTiers{
  width: 8em;
}

.addressTiers{
  width: 25em;
  max-width: 20em;
  text-transform: uppercase;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.zipTiers{
  width: 5em;
}

.townTiers{
  width: 18em;
  max-width: 18em;
  text-transform: uppercase;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.phoneTiers{
  width: 10em;
}

.emailTiers{
  width: 20em;
}

.typeTiers{
  width: 8em;
}

.statutTiers{
  width: 8em;
}

.deleteTiers{
  width: 1em;
}

.btnAjoutTiers,.btnAjoutTiers:hover{
  background-color:#F8931C;
  border-color:#F8931C;
}

.filters{
  width: 100%;
}

.filters > .form-control{
  margin-right: 0.5em;
}

.filters > .custom-select{
  margin-right: 0.5em;
}

.filters > .filterNameTiers{
  width: 20em;
}

.filters > .filterCodeTiers{
  width: 5em;
}

.filters > .filterAdressTiers{
  width: 20em;
}

.filters > .filterZipTiers{
  width: 3em;
}

.filters > .filterTownTiers{
  width: 14em;
}

.filters > .filterPhoneTiers{
  width: 7em;
}

.filters > .filterMailTiers{
  width: 16em;
}

.filters > .filterTypeTiers{
  width: 6em;
  margin-right: 0.5em;
}

.filters > .filterStatusTiers{
  width: 6em;
}
</style>
