<template>
	<div>
		<h4>Bilan Ev RP</h4>
		<b-table-simple ref="tableBilan" small responsive striped show-empty>
			<colgroup><col></colgroup>
			<template v-for="unite in unites">
				<colgroup :key="unite.id"><col><col></colgroup>
			</template>
			<colgroup><col></colgroup>
			<b-thead>
				<b-tr>
					<b-th class="firstHead">Synthèse du Document Unique</b-th>
					<template v-for="unite in unites">
						<b-th class="bilanHeadUnit" :key="unite.id" colspan="2"><span>{{ unite.nomUnite }}</span></b-th>
					</template>
					<b-th class="lastFirstHead">Actions à mettre en place</b-th>
				</b-tr>
				<b-tr>
					<b-th class="secondHead"></b-th>
					<template v-for="unite in unites">
						<b-th class="risqueHeadBilan" :key="unite.id"><span>R. Brut</span></b-th>
						<b-th class="risqueHeadBilan" :key="unite.id"><span>R. Résiduel</span></b-th>
					</template>
					<b-th class="lastSecondHead"></b-th>
				</b-tr>
			</b-thead>
			<b-tbody>
				<template v-for="famille in familles">
					<b-tr :key="famille.id">
						<b-td class="nomFamille">{{ famille.nomFamille }}</b-td>
						<template v-for="unite in unites">
							<b-td class="eval" :key="unite.id">{{ getEvalBrut(unite.id, famille.id) }}</b-td>
							<b-td class="eval" :key="unite.id">{{ getEvalResiduel(unite.id, famille.id) }}</b-td>
						</template>
						<b-td class="numeroAction">
							<div contenteditable="true" class="editme" @blur="onEditFamille" @keydown.enter="endEditFamille" editField='numerosAction' :editId="famille.id" :dataInit="getNumeroAction(famille.id)">{{ getNumeroAction(famille.id) }}</div>
							<i class="fas fa-plus" @click="addProgAction(progAction, famille.nomFamille)" style="color:green; cursor:pointer;"></i>
						</b-td>
					</b-tr>
				</template>
			</b-tbody>
		</b-table-simple>
		<h5>Cotation Risque Brut</h5>
		<div v-for="(cotation, index) in cotation.brut" :key="index">
			<span>{{ cotation.note }} : {{ cotation.desc }}</span>
		</div>
		<br>
		<h5>Cotation Risque Résiduel</h5>
		<div v-for="(cotation, index) in cotation.residuel" :key="index">
			<span>{{ cotation.note }} : {{ cotation.desc }}</span>
		</div>
	</div>
</template>

<script>
import moment from "moment"
import scriptQse from '../../../script/script-qse.js';
export default {
	data () {
		return {

		}
	},
	methods:{
		updateExtrafieldsQse: scriptQse.updateExtrafieldsQse,
		getEvalBrut(idUnite, idFamilles) {
			let data = null
			var dataUnites = this.donnees.unites.filter(unite => (unite.id == idUnite))
			dataUnites.forEach(dataUnite => {
				let dataFamilles = dataUnite.familles.filter(famille => (famille.id == idFamilles))
				dataFamilles.forEach(dataFamille => {
					data = dataFamille.evalBrutFinale
				})
			})
			console.log(data)
			return data
		},
		getEvalResiduel(idUnite, idFamilles) {
			let data = null
			var dataUnites = this.donnees.unites.filter(unite => (unite.id == idUnite))
			dataUnites.forEach(dataUnite => {
				let dataFamilles = dataUnite.familles.filter(famille => (famille.id == idFamilles))
				dataFamilles.forEach(dataFamille => {
					data = dataFamille.evalResiduelFinale
				})
			})
			console.log(data)
			return data
		},
		getNumeroAction(idFamilles) {
			let data = null
			this.donnees.unites.forEach(unite => {
				let dataFamilles = unite.familles.filter(famille => (famille.id == idFamilles))
				data = dataFamilles[0].numerosAction
			})

			return data
		},
		onEditFamille (evt) {
			var initValue = evt.target.getAttribute('dataInit')
			var newValue = evt.target.innerText
			var editField = evt.target.getAttribute('editField')
			var editId = evt.target.getAttribute('editId')

			if (newValue !== initValue) {
				this.donnees.unites.forEach(unite => {
					let dataFamilles = unite.familles.filter(famille => (famille.id == editId))
					dataFamilles.forEach(dataFamille => {
						dataFamille[editField] = newValue
					})
				})
				evt.target.setAttribute('dataInit', newValue)
				this.updateExtrafieldsQse(this.$store.state.infoSoc.id)
			}
		},

		endEditFamille (evt) {
			evt.target.blur(evt)
		},
		loadNumeroAction (){
			let numeroAction = []
			this.donnees.unites.forEach(unite => {
				unite.familles.forEach(dataFamille => {
					var numAct = numeroAction.filter(act => (act.idFamille == dataFamille.id))
					if((dataFamille.numerosAction) && (dataFamille.numerosAction != 'Non défini') && (!numAct.length)){
						numeroAction.push({idFamille:dataFamille.id, action:dataFamille.numerosAction})
					}
				})
			})

			this.donnees.unites.forEach(unite => {
				unite.familles.forEach(dataFamille => {
					var numAct = numeroAction.filter(act => (act.idFamille == dataFamille.id))
					numAct.forEach(element => {
						dataFamille.numerosAction = element.action
					})
				})
			})
		},
		addProgAction(rows, risque) {
			let dateNow = moment().format("YYYY-MM-DD")
			rows.push({
				'numProgAction':this.autoIncrementProgAction,
				'numAction':'Saisir un numéro d\'action', 
				'risque':risque, 
				'unite':[], 
				'situation':'Saisir une situation', 
				'typeAction':'Saisir un type d\'action', 
				'effet':'Saisir l\'effet', 
				'pilote':'Saisir un pilote', 
				'delai': dateNow, 
				'avancement':'Saisir un avancement', 
				'budget':'Saisir un budget', 
				'commentaire':'Saisir un commentaire'
			})
			this.$store.commit("updateAutoIncrementProgAction", parseInt(this.autoIncrementProgAction)+1)
			this.$store.commit("updateProgAction", this.progAction)
			this.updateExtrafieldsQse(this.$store.state.infoSoc.id)
		}
	},
	computed: {
		cotation(){
			return this.$store.state.cotation
		},
		unites () {
			return this.$store.state.unites
		},
		donnees() {
			return this.$store.state.donnees
		},
		familles() {
			return this.$store.state.famillesRisques.familles
		},
		progAction(){
			return this.$store.state.progAction
		},
		autoIncrementProgAction(){
			return this.$store.state.autoIncrementProgAction
		}
	},
	mounted: function() {
		this.loadNumeroAction()
	}
}
</script>

<style>
.table thead th.firstHead, .table thead th.secondHead, .table thead th.lastSecondHead, .table thead th.lastFirstHead{
	border-bottom:none;
	text-align: center;
    vertical-align: middle;
}

.table thead th.firstHead{
	border-top:1px solid black;
	border-left:1px solid black;
}

.table thead th.secondHead{
	border-top:none;
	border-left:1px solid black;
}

.table thead th.lastSecondHead{
	border-top:none;
	border-right:1px solid black;
}

.table thead th.lastFirstHead{
	border-top:1px solid black;
	border-right:1px solid black;
}

.table thead th.bilanHeadUnit{
	border: 1px solid black;
	max-height: 15em;
	height: 15em;
	text-align: center;
    vertical-align: middle;
}

.table thead th.bilanHeadUnit:nth-child(2n){
	background: #F2F2F2;
}

.table tbody td.nomFamille{
	border: 1px solid black;
    vertical-align: middle;
	width: 400px;
    white-space: break-spaces;
}

.table tbody td.numeroAction{
	border: 1px solid black;
	text-align: center;
    vertical-align: middle;
	width: 200px;
    white-space: break-spaces;
}

.table tbody td.eval{
	text-align: center;
	vertical-align: middle;
	border: 1px solid black;
}

.table thead th.bilanHeadUnit span{
	display: inline-block;
	writing-mode: vertical-lr;
}

.table thead th.risqueHeadBilan{
	border: 1px solid black;
	max-height: 6em;
	height: 6em;
	text-align: center;
}

.table thead th.risqueHeadBilan span{
	display: inline-block;
	writing-mode: vertical-lr;
}
</style>