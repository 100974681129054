import Vue from 'vue';
import Vuex from 'vuex';
import App from './App.vue';
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import "vue-wysiwyg/dist/vueWysiwyg.css";
import VueRouter from 'vue-router';
import VueAxios from 'vue-axios';
import axios from 'axios';
import VueSidebarMenu from 'vue-sidebar-menu';
import wysiwyg from "vue-wysiwyg";
import VueBootstrapTypeahead from './components/typeahead/VueBootstrapTypeahead.vue'
import VuePapeParse from 'vue-papa-parse';
import { VueSpinners } from '@saeris/vue-spinners'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import underscore from 'vue-underscore';
import VueBootstrap4Table from 'vue-bootstrap4-table'
import VueMobileDetection from "vue-mobile-detection";
import InputTag from 'vue-input-tag'
import VCalendar from 'v-calendar';
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/fr'

import store from './store/index'



Vue.use(Vuex);
Vue.use(Element, {locale});
Vue.use(VueSidebarMenu);
Vue.use(BootstrapVue);
Vue.use(VueRouter);
Vue.use(VueAxios, axios);
Vue.use(wysiwyg, {hideModules: { "image": true, "unorderedList": true,"orderedList": true,"code": true,"link": true,"headings": true,"table": true,"removeFormat": true,"separator": true}});
Vue.use(VuePapeParse);
Vue.use(VueSpinners);
Vue.component('vue-phone-number-input', VuePhoneNumberInput);
Vue.use(underscore);
Vue.component('vue-bootstrap4-table', VueBootstrap4Table);
Vue.use(VueMobileDetection);
Vue.component('input-tag', InputTag)
Vue.use(VCalendar);
Vue.use(require('vue-moment'));



Vue.config.productionTip = false;
Vue.config.performance = true;


import Login from './components/Login.vue';
import Index from './components/Index.vue';
import ChangePassOublie from './components/ChangePassOublie.vue';
import PremiereConnexion from './components/PremiereConnexion.vue';
import CreateAccountForm from './components/CreateAccountForm.vue';

import IndexClient from './components/routes/IndexClient.vue';
import Contenu from './components/routes/Contenu.vue';
import ContenuProfil from './components/routes/ContenuProfil.vue';
import MesDocuments from './components/routes/MesDocuments.vue';
import DocumentUnique from './components/modules/moduleQse/client/DocumentUnique.vue';
import AideClient from './components/routes/AideClient.vue';
import ProfilClient from './components/routes/ProfilClient.vue';
import InfoSoc from './components/routes/InfoSoc.vue';
import ChangePass from './components/routes/ChangePass.vue';
import ConfigQse from './components/modules/moduleQse/ConfigModule.vue';

import IndexAdmin from './components/routes/IndexAdmin.vue';
import Tiers from './components/routes/Tiers.vue';
import DetailsTiers from './components/routes/DetailsTiers.vue';
import ContenuAdmin from './components/routes/ContenuAdmin.vue';
import AideAdmin from './components/routes/AideAdmin.vue';
import GestionTemplates from './components/routes/GestionTemplates.vue';
import GestionCarroussel from './components/routes/GestionCarroussel.vue';
 
import Error403 from './components/403.vue';
import Error404 from './components/404.vue';

//Vue admin
Vue.component('index-admin-layout', IndexAdmin);
Vue.component('tiers-layout', Tiers);
Vue.component('details-tiers-layout', DetailsTiers);
Vue.component('aide-admin-layout', AideAdmin);
Vue.component('gestion-templates-layout', GestionTemplates);
Vue.component('gestion-carroussel-layout', GestionCarroussel);
Vue.component('config-qse-layout', ConfigQse);

// Vue mixte
Vue.component('create-account-form', CreateAccountForm);

//Vue client
Vue.component('index-client-layout', IndexClient);
Vue.component('mes-documents-layout', MesDocuments);
Vue.component('aide-client-layout', AideClient);
Vue.component('profil-client-layout', ProfilClient);
Vue.component('info-soc-client-layout', InfoSoc);
Vue.component('change-pass-client-layout', ChangePass);
Vue.component('document-unique-layout', DocumentUnique);

//Composants perso.
Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeahead)


const routes = [
    {
        name: 'home',
        path: '/',
        component: Index
    },
    {
        name: 'login',
        path: '/login',
        component: Login
    },
    {
        name: 'changePassOublie',
        path: '/changePassOublie/:token',
        component: ChangePassOublie
    },
    {
        name: 'premiereConnexion',
        path: '/premiereConnexion/:token',
        component: PremiereConnexion
    },
    {
        name: 'indexClient',
        path: '/indexClient',
        component: Contenu,
        meta: {
            layout: "index-client"
        }
    },
    {
        name: 'mesDocuments',
        path: '/mesDocuments',
        component: Contenu,
        meta: {
            layout: "mes-documents"
        }
    },
    {
        name: 'documentUnique',
        path: '/documentUnique',
        component: Contenu,
        meta: {
            layout: "document-unique"
        }
    },
    {
        name: 'aideClient',
        path: '/aideClient',
        component: Contenu,
        meta: {
            layout: "aide-client"
        }
    },
    {
        name: 'profilClient',
        path: '/profilClient',
        component: ContenuProfil,
        meta: {
            layout: "profil-client"
        }
    },
    {
        name: 'infoSoc',
        path: '/infoSoc',
        component: ContenuProfil,
        meta: {
            layout: "info-soc-client"
        }
    },
    {
        name: 'changePass',
        path: '/changePass',
        component: ContenuProfil,
        meta: {
            layout: "change-pass-client"
        }
    },
    {
        name: 'indexAdmin',
        path: '/indexAdmin',
        component: ContenuAdmin,
        meta: {
            layout: "index-admin"
        }
    },
    {
        name: 'tiers',
        path: '/tiers',
        component: ContenuAdmin,
        meta: {
            layout: "tiers"
        }
    },
    {
        name: 'detailsTiers',
        path: '/detailsTiers/:id',
        component: ContenuAdmin,
        meta: {
            layout: "details-tiers"
        }
    },
    {
        name: 'gestionTemplates',
        path: '/gestionTemplates',
        component: ContenuAdmin,
        meta: {
            layout: "gestion-templates"
        }
    },
    {
        name: 'gestionCarroussel',
        path: '/gestionCarroussel',
        component: ContenuAdmin,
        meta: {
            layout: "gestion-carroussel"
        }
	},
	{
        name: 'configQse',
        path: '/configQse',
        component: ContenuAdmin,
        meta: {
            layout: "config-qse"
        }
    },
    // {
    //     name: 'aideAdmin',
    //     path: '/aideAdmin',
    //     component: ContenuAdmin,
    //     meta: {
    //         layout: "aide-admin"
    //     }
    // },
    {
        name: 'error403',
        path: '/error403',
        component: Error403
    },
    {
        path: '*',
        component: Error404
    }
];

const router = new VueRouter({ mode: 'history', routes: routes});

// new Vue(Vue.util.extend({ router }, App)).$mount('#app');
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')