<template>
	<div style="width:100%;">
		<b-tabs lazy content-class="mt-3" active-nav-item-class="font-weight-bold">
			<b-tab class="outlineLess">
				<template slot="title">
					<i class="fas fa-square-root-alt" style="padding-right:5px;"></i> Cotation
				</template>
				<p><tabCotation /></p>
			</b-tab>
			<b-tab class="outlineLess">
				<template slot="title">
					<i class="fas fa-radiation" style="padding-right:5px;"></i> Risques
				</template>
				<p><tabRisques /></p>
			</b-tab>
			<b-tab class="outlineLess">
				<template slot="title">
					<i class="fas fa-industry" style="padding-right:5px;"></i> Unités
				</template>
				<p><tabUnites /></p>
			</b-tab>
			<b-tab class="outlineLess">
				<template slot="title">
					<i class="fas fa-file-alt" style="padding-right:5px;"></i> Bilan EvRP
				</template>
				<p><tabBilanErp /></p>
			</b-tab>
			<b-tab class="outlineLess">
				<template slot="title">
					<i class="fas fa-user-injured" style="padding-right:5px;"></i> Pénibilité
				</template>
				<p><tabPenibilite /></p>
			</b-tab>
			<b-tab class="outlineLess">
				<template slot="title">
					<i class="fas fa-virus" style="padding-right:5px;"></i> COVID
				</template>
				<p><tabCovid /></p>
			</b-tab>
			<b-tab class="outlineLess">
				<template slot="title">
					<i class="fas fa-tasks" style="padding-right:5px;"></i> Checklist Générale
				</template>
				<p><tabChecklist /></p>
			</b-tab>
			<b-tab class="outlineLess">
				<template slot="title">
					<i class="fas fa-list-ol" style="padding-right:5px;"></i> Prog Action
				</template>
				<p><tabProgAction /></p>
			</b-tab>
			<b-tab class="outlineLess">
				<template slot="title">
					<i class="fas fa-file-export" style="padding-right:5px;"></i> Export
				</template>
				<p><tabExportQse /></p>
			</b-tab>
		</b-tabs>
	</div>
</template>

<script>
import tabCotation from '../TabCotation'
import tabRisques from '../TabRisques'
import tabUnites from '../TabUnites'
import tabExportQse from '../TabExportQse'
import tabBilanErp from '../TabBilanErp'
import tabPenibilite from '../TabPenibilite'
import tabCovid from '../TabAnnexeCovid'
import tabChecklist from '../TabChecklistGenerale'
import tabProgAction from '../TabProgAction'
export default {
	components: {
		tabCotation,
		tabRisques,
		tabUnites,
		tabExportQse,
		tabBilanErp,
		tabPenibilite,
		tabCovid,
		tabChecklist,
		tabProgAction
	},

	data () {
		return {
		
		}
	},

	mounted: function () {

	},

	methods: {

	},
	props:[
		'infosTiers'
	]
}
</script>
<style>
.outlineLess {
  	outline: 0;
}
</style>
