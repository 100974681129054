<template>
  <div>
    <b-form id="infoPerso">
      <b-form-group class="identity">
        <label>Identité</label>
        <b-form-group class="civility" label="Civilité :" label-for="inputCivility">
          <b-form-input id="inputCivility" v-model="infoClients.civility_id" :disabled="true"></b-form-input>
        </b-form-group>
        <b-form-group class="firstname" label="Prénom :" label-for="inputFirstname">
          <b-form-input id="inputFirstname" v-model="infoClients.firstname" :disabled="true"></b-form-input>
        </b-form-group>
        <b-form-group class="lastname" label="Nom :" label-for="inputLastname">
          <b-form-input id="inputLastname" v-model="infoClients.lastname" :disabled="true"></b-form-input>
        </b-form-group>
      </b-form-group>

      <b-form-group class="postalAddress">
        <label>Adresse postale</label>
        <b-form-group class="address" label="Adresse :" label-for="inputAddress">
          <b-form-input id="inputAddress" v-model="infoClients.address" :disabled="true"></b-form-input>
        </b-form-group>
        <b-form-group class="zip" label="Code postal :" label-for="inputZip">
          <b-form-input id="inputZip" v-model="infoClients.zip" :disabled="true"></b-form-input>
        </b-form-group>
        <b-form-group class="town" label="Ville :" label-for="inputTown">
          <b-form-input id="inputTown" v-model="infoClients.town" :disabled="true"></b-form-input>
        </b-form-group>
      </b-form-group>

      <b-form-group class="other">
        <label>Autres coordonnées</label>
        <b-form-group class="mail" label="Adresse Email :" label-for="inputMail">
          <b-form-input id="inputMail" v-model="infoClients.mail" :disabled="true"></b-form-input>
        </b-form-group>
        <b-form-group class="phone" label="Téléphone fixe :" label-for="inputPhone">
          <b-form-input id="inputPhone" v-model="infoClients.phone_pro" :disabled="true"></b-form-input>
        </b-form-group>
        <b-form-group class="phoneMobile" label="Téléphone mobile :" label-for="inputPhoneMobile">
          <b-form-input id="inputPhoneMobile" v-model="infoClients.phone_mobile" :disabled="true"></b-form-input>
        </b-form-group>
        <b-form-group class="fax" label="Fax :" label-for="inputFax">
          <b-form-input id="inputFax" v-model="infoClients.fax" :disabled="true"></b-form-input>
        </b-form-group>
      </b-form-group>

      <b-button @click.stop="modifInfoPerso($event.target)" variant="primary"><span class="fas fa-cogs"></span> Modifier mes informations</b-button>
    </b-form>

    <b-modal id="modalInfoPerso" @hide="resetModal" :title="modalInfoPerso.title" hide-footer>
      <template>
        <div>
          <b-form id="modifInfoPerso" @submit.prevent="onSubmit">
            <b-form-group class="identity">
              <label>Identité</label>
              <b-form-group class="civility" label="Civilité :" label-for="inputCivilityPerso">
                <b-form-select id="inputCivilityPerso" v-model="infoPerso.civility_id" :options="optionsCivility"></b-form-select>
              </b-form-group>
              <b-form-group class="firstname" label="Prénom :" label-for="inputFirstnamePerso">
                <b-form-input id="inputFirstnamePerso" v-model="infoPerso.firstname" ></b-form-input>
              </b-form-group>
              <b-form-group class="lastname" label="Nom :" label-for="inputLastnamePerso">
                <b-form-input id="inputLastnamePerso" v-model="infoPerso.lastname" ></b-form-input>
              </b-form-group>
            </b-form-group>

            <b-form-group class="postalAddress">
              <label>Adresse postale</label>
              <b-form-group class="address" label="Adresse :" label-for="inputAddressPerso">
                <b-form-input id="inputAddressPerso" v-model="infoPerso.address" ></b-form-input>
              </b-form-group>
              <b-form-group class="zip" label="Code postal :" label-for="inputZipPerso">
                <b-form-input id="inputZipPerso" v-model="infoPerso.zip" ></b-form-input>
              </b-form-group>
              <b-form-group class="town" label="Ville :" label-for="inputTownPerso">
                <b-form-input id="inputTownPerso" v-model="infoPerso.town" ></b-form-input>
              </b-form-group>
            </b-form-group>

            <b-form-group class="other">
              <label>Autres coordonnées</label>
              <b-form-group class="mail" label="Adresse Email :" label-for="inputMailPerso">
                <b-form-input id="inputMailPerso" v-model="infoPerso.mail" ></b-form-input>
              </b-form-group>
              <b-form-group class="phone" label="Téléphone fixe :" label-for="inputPhonePerso">
                <b-form-input id="inputPhonePerso" v-model="infoPerso.phone_pro" ></b-form-input>
              </b-form-group>
              <b-form-group class="phoneMobile" label="Téléphone mobile :" label-for="inputPhoneMobilePerso">
                <b-form-input id="inputPhoneMobilePerso" v-model="infoPerso.phone_mobile" ></b-form-input>
              </b-form-group>
              <b-form-group class="fax" label="Fax :" label-for="inputFaxPerso">
                <b-form-input id="inputFaxPerso" v-model="infoPerso.fax" ></b-form-input>
              </b-form-group>
            </b-form-group>

            <b-button type="submit" variant="primary">Envoyer</b-button>
          </b-form>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<style>
#infoPerso{
  width: 50%;
  margin: auto;
  margin-top: 3em;
}

.civility, .mail{
  width: 35%;
}

.firstname, .lastname, .town, .zip{
  width: 35%;
  float: left;
  margin-right: 10%;
}

.phone, .phoneMobile, .fax{
  width: 20%;
  float: left;
  margin-right: 10%;
}

.address{
  width: 80%;
}

#modifInfoPerso .civility{
  width: 30%;
} 

#modifInfoPerso .mail{
  width: 40%;
}

#modifInfoPerso .firstname, #modifInfoPerso .lastname, #modifInfoPerso .town, #modifInfoPerso .zip{
  width: 30%;
  float: left;
  margin-right: 20%;
}

#modifInfoPerso .phone, #modifInfoPerso .phoneMobile, #modifInfoPerso .fax{
  width: 20%;
  float: left;
  margin-right: 10%;
}

#modifInfoPerso .address{
  width: 80%;
}

.modal-dialog.modal-md{
  max-width: 40%;
  margin-top: 10vh;
}
</style>

<script>
import script from '../../script/script.js';

export default {
  data() {
    return {
      post: {},
      infoClients:[],
      infoPerso:[],
      modalInfoPerso:{
        title:"Demande de modification des informations"
      },
      optionsCivility:[
        {value : null, text:"Civilité"},
        {value : "MR", text:"MR"},
        {value : "MME", text:"MME"},
      ]
    }
  },
  mounted:function(){
    this.verifyRight();
    this.getInfoClient();
    this.activeClassMenuProfil();
  },
  methods: {
    verifyRight: script.verifyRight,
    getInfoClient: script.getInfoClient,
    modifInfoPerso: script.modifInfoPerso,
    changeInfoPerso: script.changeInfoPerso,
    onSubmit(){
      this.changeInfoPerso(this.infoClients, this.infoPerso);
    },
    activeClassMenuProfil: script.activeClassMenuProfil
  },
  components: {
    
  }
}
</script>
