<template>
	<div>
		<el-button @click.native.prevent="addFamily()" type="primary" size="small" round> Ajouter une famille de risque</el-button>
		<br>
		<div v-if="tableFamilles.length">
			<div class="centerCotation" v-for="(family, index) in tableFamilles" :key="family.id">
				<div class="family">
					<h4><div contenteditable="true" class="editme" @blur="onEdit" @keydown.enter="end" :editId="index" editField='nomFamille' :dataInit="family.nomFamille">{{ family.nomFamille }}</div></h4>
					<el-button class="btnDeleteFamily" @click.native.prevent="deleteFamily(family.id)" type="danger" icon="el-icon-delete" circle></el-button>
				</div>
				<b-table ref="tableFamily" small responsive striped :items="getRisquesByFamille(family)" :fields="fieldsFamily" show-empty>
					<template slot="empty" slot-scope="">
						<div style="text-align:center;">Cette famille de risque ne comporte aucun risque</div>
					</template>
					<template v-slot:cell(risque)="data">
						<div contenteditable="true" class="editme" style="text-align: initial;" @blur="onEditRisque" @keydown.enter="endEditRisque" :editFamille="family.id" :editId="data.item.id" editField='nomRisque' :dataInit="data.item.nomRisque">{{ data.item.nomRisque }}</div>
					</template>
					<template v-slot:cell(actions)="data">
						<i class="far fa-trash-alt" @click="deleteRow(data.item.id)" style="color:red; cursor:pointer;"></i>
					</template>
				</b-table>
				<el-button @click.native.prevent="addRow(family.id)" type="text" size="small"> Ajouter un risque famille</el-button>
			</div>
			<br>
		</div>
		<div v-else class="centerCotation">
			<h4>Aucune famille de risque</h4>
		</div>
	</div>
</template>

<script>
import scriptQse from '../../../script/script-qse.js';
export default {
	data () {
		return {
			fieldsFamily: [
				{ key: 'risque', label: 'Risque' },
				{ key: 'actions', label: '' }
			]
		}
	},
	methods: {
		updateExtrafieldsUserQse: scriptQse.updateExtrafieldsUserQse,
		deleteRow(idRisque) {
			if(confirm("Voulez-vous supprimer ce risque ?")){
				this.$store.commit("deleteRisqueDefault", idRisque)
				this.updateExtrafieldsUserQse(this.$route.params.id)
			}
		},
		addRow(idFamille) {
			this.$store.commit("addRisqueDefault", idFamille)
			this.updateExtrafieldsUserQse(this.$route.params.id)
		},
		deleteFamily(idFamille) {
			if(confirm("Voulez-vous supprimer cette famille de risques ?")){
				this.$store.commit("deleteFamilleDefault", idFamille)
				this.updateExtrafieldsUserQse(this.$route.params.id)
			}
		},
		addFamily() {
			this.$store.commit("addFamilleDefault")
			this.updateExtrafieldsUserQse(this.$route.params.id)
		},
		onEdit (evt) {
			var initValue = evt.target.getAttribute('dataInit')
			var newValue = evt.target.innerText
			var editId = evt.target.getAttribute('editId')
			var editField = evt.target.getAttribute('editField')
			var editTable = evt.target.getAttribute('editTable')

			if (newValue !== initValue) {
				this.$store.commit("updateFamillesDefault", {id: editId, field: editField, value: newValue})
				evt.target.setAttribute('dataInit', newValue)
				this.updateExtrafieldsUserQse(this.$route.params.id)
			}
		},

		endEdit (evt) {
			evt.target.blur(evt)	
		},
		onEditRisque (evt) {
			var initValue = evt.target.getAttribute('dataInit')
			var newValue = evt.target.innerText
			var editId = evt.target.getAttribute('editId')
			var editField = evt.target.getAttribute('editField')
			var editTable = evt.target.getAttribute('editTable')
			var editFamille = evt.target.getAttribute('editFamille')

			if (newValue !== initValue) {
				this.$store.commit("updateRisquesDefault", {idFamille: editFamille, id: editId, field: editField, value: newValue})
				evt.target.setAttribute('dataInit', newValue)
				this.updateExtrafieldsUserQse(this.$route.params.id)
			}
		},
		endEditRisque (evt) {
			evt.target.blur(evt)	
		},
		getRisquesByFamille(famille) {
			return this.$store.state.famillesRisquesDefault.risques.filter(a => (a.idFamille == famille.id))
		}
	},
	computed: {
		tableFamilles () {
			return this.$store.state.famillesRisquesDefault.familles
		}
	},
	mounted () {
	}
}
</script>

<style>
 	button:focus{
		outline:none;
	}

	.family{
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.btnDeleteFamily{
		margin: 1em;
	}
</style>