<template>
<div>
  <div>
    <div id="carrousel">
      <b-carousel v-if="imgCarrousel.length>1"
        id="carouselClient"
        v-bind:interval="confCarrousel.vitesse*1000"
        fade
        indicators
        img-width="1024"
        img-height="480"
      >
        <div v-for="item in imgCarrousel" :key="item.chemin_img">
          <b-carousel-slide
            v-bind:img-src="'/api/imgCarrousel/'+item.chemin_img"
          ></b-carousel-slide>
        </div>
      </b-carousel>
      <b-carousel v-else-if="imgCarrousel.length>0"
        id="carouselClient"
        img-width="1024"
        img-height="480"
      >
        <div v-for="item in imgCarrousel" :key="item.chemin_img">
          <b-carousel-slide
            v-bind:img-src="'/api/imgCarrousel/'+item.chemin_img"
          ></b-carousel-slide>
         </div>
      </b-carousel>
      <b-carousel v-else>
      </b-carousel>
    </div>
    <h1 style='text-align:center;margin-top: 2em;'>Bienvenue {{info.prenom||""}} {{info.nom||""}}</h1>
    <h4 style='text-align:center;'>Vous trouverez ici votre document unique.</h4>
    <div v-if="info.logo" class="logoEntreprise" style="margin-top:5em;">
      <img :src="'/api/logoClient?v='+info.id" alt=""/>
    </div>
  </div>
</div>
</template>

<script>
import script from '../../script/script.js';

export default {
  data() {
    return {
      imgCarrousel:[],
      confCarrousel:{
        vitesse:''
      }
    }
  },
  mounted:function(){
    this.verifyRight();
    this.loadingInfoClient();
    this.loadCarrousel();
    this.loadSpeed();
  },
  methods: {
    verifyRight: script.verifyRight,
    loadCarrousel: script.loadCarrousel,
    loadSpeed: script.loadSpeed,
    loadingInfoClient: script.loadingInfoClient,
  },
  computed:{
    info(){
      return this.$store.state.info
    }
  }
  
}
</script>

<style>
#carrousel{
  width:30%;
  margin:auto;
}

.logoEntreprise{
  text-align: center;
}

.logoEntreprise > img{
  width: 20%;
}
</style>
