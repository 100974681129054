<template>
  <div>
    <div class="body bodylogin">
      <div class="login_center">
        <!-- Formulaire de connexion -->
        <form class="form-horizontal" v-on:submit.prevent="connect()" method="post" >
          <div class="login_table">
            <div class="login-logo">
              <img src="img/logo-as2piq.png" alt="AS2PIQ" />
            </div>
            <br/>
            <div class="login-fields">
              <div id="login-status">
              </div>
              <div class="input-group">
                <span class="input-group-addon"><i class="fas fa-user"></i></span>
                <!-- Ajout d'un champ pour le login -->
                <input type="text" class="form-control" id="email" placeholder="Login" v-model="post.email" required/>
              </div>
              <div class="input-group">
                <span class="input-group-addon"><i class="fas fa-lock"></i></span>
                  <input type="password" class="form-control" id="pwd" placeholder="Mot de passe" v-model="post.pwd" required/>
              </div>

              <!-- Button Connection -->
              <br/>
              <input type="submit" class="btn btn-primary btn-block" value="Connexion" tabindex="5" />
              <div class="mt-3">
                <a v-on:click.prevent="openModal($event.target)" data-toggle="modal" href="#modalMdpOublie">Mot de passe oublié ?</a>
              </div>
            </div>
          </div> <!-- end login table -->
        </form>
      </div>
    </div>
    <!-- Modal d'oublie de mot de passe-->
    <b-modal id="modalOublieMDP" @hide="resetModal" :title="modalOublieMDP.title" hide-footer>
      <b-form>
        <b-form-group id="input-group-login" label="Veuillez entrer votre login :" label-for="input-login">
          <b-form-input type="email" id="input-login" v-model="loginOublie"></b-form-input>
        </b-form-group>
        <b-button id="sendChangePass" block v-on:click="changePassOublie" variant="primary">Envoyer</b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import conf from '../config/api.js';
import script from '../script/script.js';

export default {
    data() {
        return {
            post: {},
            modalOublieMDP: { title: "Formulaire d'oubli de mot de passe", content: '' },
            loginOublie:""
        }
    },
    created:function(){
      this.isConnect();
    },
    methods: {
        connect() {
            this.axios.post(conf.server+"/api/connect", this.post,{withCredentials:true})
            .then((response) => {
                if(response.status==200) {
                    if (this.$route.query.redirect) {
                        if(response.data.cotationDefault){
                          this.$store.commit("updateCotationDefault", JSON.parse(response.data.cotationDefault))
                        }
                        if(response.data.famillesRisquesDefault){
                          this.$store.commit("initializeFamillesRisquesDefault", JSON.parse(response.data.famillesRisquesDefault))
                        }
                        if(response.data.situationsDefault){
                          this.$store.commit('initializeSituationsDefault', JSON.parse(response.data.situationsDefault))
                        }
                        if(response.data.autoIncrementFamilleDefault){
                          this.$store.commit('updateAutoIncrementFamilleDefault', response.data.autoIncrementFamilleDefault)
                        }
                        if(response.data.autoIncrementPenibiliteDefault){
                          this.$store.commit('updateAutoIncrementPenibiliteDefault', response.data.autoIncrementPenibiliteDefault)
                        }
                        if(response.data.autoIncrementSituationDefault){
                          this.$store.commit('updateAutoIncrementSituationDefault', response.data.autoIncrementSituationDefault)
                        }
                        if(response.data.autoIncrementRisqueDefault){
                          this.$store.commit('updateAutoIncrementRisqueDefault', response.data.autoIncrementRisqueDefault)
                        }
                        if(response.data.checklistGeneraleDefault){
                          this.$store.commit('updateChecklistGeneraleDefault', response.data.checklistGeneraleDefault)
                        }
                        else {
                          let checklistGenerale = {
                            affichageObligatoire:[],
                            dispositionApplicable:[],
                            locauxEntreprise:[],
                            organisationGestionSecurite:[]
                          }
                          this.$store.commit('updateChecklistGeneraleDefault', checklistGenerale)
                        }
                        if(response.data.socId){
                            this.detailsTiers(response.data.socId)
                        }
                        this.$router.push(this.$route.query.redirect)
                    }
                    else {
                        if(response.data.cotationDefault){
                          this.$store.commit("updateCotationDefault", JSON.parse(response.data.cotationDefault))
                        }
                        if(response.data.famillesRisquesDefault){
                          this.$store.commit("initializeFamillesRisquesDefault", JSON.parse(response.data.famillesRisquesDefault))
                        }
                        if(response.data.situationsDefault){
                          this.$store.commit('initializeSituationsDefault', JSON.parse(response.data.situationsDefault))
                        }
                        if(response.data.autoIncrementFamilleDefault){
                          this.$store.commit('updateAutoIncrementFamilleDefault', response.data.autoIncrementFamilleDefault)
                        }
                        if(response.data.autoIncrementRisqueDefault){
                          this.$store.commit('updateAutoIncrementRisqueDefault', response.data.autoIncrementRisqueDefault)
                        }
                        if(response.data.autoIncrementPenibiliteDefault){
                          this.$store.commit('updateAutoIncrementPenibiliteDefault', response.data.autoIncrementPenibiliteDefault)
                        }
                        if(response.data.autoIncrementSituationDefault){
                          this.$store.commit('updateAutoIncrementSituationDefault', response.data.autoIncrementSituationDefault)
                        }
                        if(response.data.checklistGeneraleDefault){
                          this.$store.commit('updateChecklistGeneraleDefault', JSON.parse(response.data.checklistGeneraleDefault))
                        }
                        else {
                          let checklistGenerale = {
                            affichageObligatoire:[],
                            dispositionApplicable:[],
                            locauxEntreprise:[],
                            organisationGestionSecurite:[]
                          }
                          this.$store.commit('updateChecklistGeneraleDefault', checklistGenerale)
                        }
                        if(response.data.socId){
                            this.detailsTiers(response.data.socId)
                        }
                        this.$router.push(response.data.route);
                    }
                }
                else{
                    this.$router.push('login');
                }
            })
            .catch(() => {
                this.$bvToast.toast(`Identifiant ou mot de passe incorrect`, {
                    title: 'Identification',
                    toaster: 'b-toaster-bottom-right',
                    variant: 'danger',
                    autoHideDelay: 5000,
                    appendToast: true,
                    solid: true,
                })
            })
        },
        isConnect: script.isConnect,
        openModal(target){
          this.$root.$emit('bv::show::modal', 'modalOublieMDP',target);
        },
        changePassOublie: script.changePassOublie,
        detailsTiers: script.detailsTiers
    }
}
</script>

<style scoped>
    #createAccount {
      background-color: #F8931C;
      border-color: #FF8707;
    }
    #createAccount:hover {
      background-color: #F8641C;
    }
</style>


