<template>
  <div>
    <div>
      <div style='padding:10px;'>
        <div id='recherche'>
          <b-row>
            <b-col class="my-1">
              <b-form-group>
                <b-input-group>
                  <b-form-input v-model="filter" placeholder="Recherche" />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">Effacer</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>         
        </div>

        <div style="float:left;width:50%;">
          <b-table class="text-center" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered" responsive :striped="true" :fields="fieldsCarrousel" :items="imgCarrousel" :current-page="currentPage" :per-page="perPage">
            <template v-slot:cell(visual)="data">
              <img style="width:200px;" v-bind:src="'/api/imgCarrousel/'+data.item.chemin_img" alt=""/>
            </template>
            <template v-slot:cell(nom)="data">
              <span>{{ data.item.chemin_img }}</span>
            </template>
            <template v-slot:cell(delete)="data">
              <span v-on:click="deleteImg(data.item.chemin_img)" class='fas fa-times' style="cursor:pointer;color:red;"></span>
            </template>
          </b-table>
          <div v-if="totalRows>perPage">
            <b-pagination size="md" :total-rows="totalRows" v-model="currentPage" :per-page="perPage" />
          </div>
        </div>
        
        <div style="margin-top: 1em;width:45%;margin-left:55%;">
          <div class="upload">
              <input type="file" name="myfile" id="myfile" multiple/>
              <button v-on:click="uploadImg">Envoyer</button>

            <div style="width:50%;margin-top: 1em;">
              <b-form @submit="changeSpeed">
                <b-form-group id="input-group-1" label="Vitesse du carrousel (en secondes):" label-for="input-vitesse">
                  <b-form-input id="input-vitesse" v-model="confCarrousel.vitesse" required placeholder=""></b-form-input>
                </b-form-group>
                <b-button type="submit" variant="primary">Changer la vitesse</b-button>
              </b-form>
            </div>
          </div>
        </div>
      </div>
      <div style="width:30%;margin-left:65%;">
        <b-carousel
          id="carouselAdmin"
          v-bind:interval="confCarrousel.vitesse*1000"
          fade
          indicators
          img-width="1024"
          img-height="480"
        >
          <div v-for="item in imgCarrousel" :key="item.chemin_img">
            <b-carousel-slide
              v-bind:img-src="'/api/imgCarrousel/'+item.chemin_img"
            ></b-carousel-slide>
          </div>
        </b-carousel>
      </div>
    </div>
  </div>  
</template>

<script>
import script from '../../script/script.js';

export default {
  data() {
    return {
      post: {},
      imgCarrousel:[],
      currentPage: 1,
      perPage: 3,
      filter:null,
      sortBy: null,
      sortDesc: false,
      sortDirection: 'asc',
      totalRows: "",
      fieldsCarrousel:[{key:"visual", label:"Visuel"},{key:"nom",label:"Nom"},{key:"delete",label:""}],
      files:[],
      confCarrousel:{
        vitesse:''
      },
      selectedFile: null,
    }
  },
  mounted:function(){
    this.verifyRight();
    this.loadCarrousel();
    this.loadSpeed();
  },
  methods: {
    verifyRight: script.verifyRight,
    loadCarrousel: script.loadCarrousel,
    uploadImg: script.uploadImg,
    changeSpeed: script.changeSpeed,
    loadSpeed: script.loadSpeed,
    deleteImg: script.deleteImg,
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  },
  components: {

  }
}
</script>

<style>

</style>
