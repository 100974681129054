<template>
  <div>
    <b-form @submit.prevent="submit" @reset.prevent="resetForm">
      
      <!-- Type -->
      <b-form-row>
        <b-col cols=12 lg=2 class="text-left text-lg-right">
          <label for="input-1" class="required col-form-label">Type de tiers</label>
        </b-col>
        <b-col cols=12 lg=8>
          <b-radio-group id="input-1" v-model="form.type">
            <b-form-radio value="Particulier">Particulier</b-form-radio>
            <b-form-radio value="Professionnel">Professionnel</b-form-radio>
            <b-form-radio v-if="fromAdmin" value="Fournisseur">Fournisseur</b-form-radio>
          </b-radio-group>
        </b-col>
      </b-form-row>

      <br>
      <template v-if="!is_professional_or_supplier||queryflag">
        <template v-if="is_professional_or_supplier">

          <!-- SIRET -->
          <b-form-row>
          <b-col cols=12 lg=2 class="text-left text-lg-right">
            <label for="input-2" class="required col-form-label">SIRET</label>
          </b-col>
            <b-col cols=12 lg=8>
              <b-form-input id="input-2" type="text" v-model.trim="form.siret"></b-form-input>
            </b-col>
          </b-form-row>

          <!-- forme juridique -->
          <b-form-row>
            <b-col cols=12 lg=2 class="text-left text-lg-right">
              <label for="input-3" class="required col-form-label">Forme juridique</label>
            </b-col>
            <b-col cols=12 lg=8>
              <b-form-input id="input-3" type="text" v-model.trim="form.forme_juridique"></b-form-input>
            </b-col>
          </b-form-row>

          <!-- Dénomination -->
          <b-form-row>
            <b-col cols=12 lg=2 class="text-left text-lg-right">
              <label for="input-4" class="required col-form-label">Dénomination</label>
            </b-col>
            <b-col cols=12 lg=8>
              <b-form-input id="input-4" type="text" v-model.trim="form.denomination"></b-form-input>
            </b-col>
          </b-form-row>

          <!-- adresse 1 -->
          <!-- <b-form-row>
            <b-col cols=12 lg=2 class="text-left text-lg-right">
              <label for="input-5" class="col-form-label">Adresse L3</label>
            </b-col>
            <b-col cols=12 lg=8>
              <b-form-input id="input-5" type="text" v-model.trim="form.adresse_l3"></b-form-input>
            </b-col>
          </b-form-row> -->

          <!-- adresse 2 -->
          <b-form-row>
            <b-col cols=12 lg=2 class="text-left text-lg-right">
              <label for="input-6" class="col-form-label">Adresse</label>
            </b-col>
            <b-col cols=12 lg=8>
              <b-form-input id="input-6" type="text" v-model.trim="form.adresse_l4"></b-form-input>
            </b-col>
          </b-form-row>

          <!-- adresse 3 -->
          <!-- <b-form-row>
            <b-col cols=12 lg=2 class="text-left text-lg-right">
              <label for="input-7" class="col-form-label">Adresse L5</label>
            </b-col>  
            <b-col cols=12 lg=8>
              <b-form-input id="input-7" type="text" v-model.trim="form.adresse_l5"></b-form-input>
            </b-col>
          </b-form-row> -->

          <!-- code postal -->
          <b-form-row>
            <b-col cols=12 lg=2 class="text-left text-lg-right">
              <label for="input-8" class="required col-form-label">CP</label>
            </b-col>  
            <b-col cols=12 lg=2>
              <b-form-input id="input-8" type="number" v-model.trim="form.zip"></b-form-input>
            </b-col>

             <!-- ville -->
            <b-col cols=12 lg=1 class="text-left text-lg-right">
              <label for="input-9" class="required col-form-label">Ville</label>
            </b-col>  
            <b-col cols=12 lg=5>
              <b-form-input id="input-9" type="text" v-model.trim="form.city"></b-form-input>
            </b-col>
          </b-form-row>

          <!-- email -->
          <b-form-row>
            <b-col cols=12 lg=2 class="text-left text-lg-right">
              <label for="input-10" class="required col-form-label">Email</label>
            </b-col>  
            <b-col cols=12 lg=8>
              <b-form-input id="input-10" type="text" v-model.trim="form.mail"></b-form-input>
            </b-col>
          </b-form-row>

          <!-- Téléphone -->
          <b-form-row>
            <b-col cols=12 lg=2 class="text-left text-lg-right">
              <label for="input-11" class="required col-form-label">Téléphone</label>
            </b-col>  
            <b-col cols=12 lg=3>
              <b-form-input id="input-11" type="text" v-model.trim="form.phone_number_entreprise"></b-form-input>
            </b-col>

            <!-- fax -->
            <b-col cols=12 lg=1 class="text-left text-lg-right">
              <label for="input-12" class="col-form-label">Fax</label>
            </b-col>  
            <b-col cols=12 lg=4>
              <b-form-input id="input-12" type="text" v-model.trim="form.fax"></b-form-input>
            </b-col>
          </b-form-row>

          <!-- code NAF -->
          <b-form-row>
            <b-col cols=12 lg=2 class="text-left text-lg-right">
              <label for="input-13" class="required col-form-label">Code NAF</label>
            </b-col>  
            <b-col cols=12 lg=3>
              <b-form-input id="input-13" type="text" v-model.trim="form.naf"></b-form-input>
            </b-col>

            <!-- TVA -->
            <b-col cols=12 lg=1 class="text-left text-lg-right">
              <label for="input-14" class="col-form-label">TVA</label>
            </b-col>  
            <b-col cols=12 lg=4>
              <b-form-input id="input-14" type="text" v-model.trim="form.tva" disabled></b-form-input>
            </b-col>
          </b-form-row>
          <hr>
          <h6 class="text-center">CONTACT</h6>
        <!-- fin div v-if=is_professional_or_supplier -->

        </template>


        <!-- civilité -->
        <b-form-row>
          <b-col cols=12 lg=2 class="text-left text-lg-right">
            <label for="input-15" class="required col-form-label">Civilité</label>
          </b-col>  
          <b-col cols=12 lg=2>
            <b-select size="sm" id="input-15" v-model=form.civility :options="options_form.civility"></b-select>
          </b-col>

          <!-- function -->
          <b-col cols=12 lg=2 class="text-left text-lg-right" v-if="is_professional_or_supplier">
            <label for="input-16" class="required col-form-label">Fonction</label>
          </b-col>
          <b-col cols=12 lg=4 v-if="is_professional_or_supplier">
            <b-form-input id="input-16" type="text" v-model.trim="form.function"></b-form-input>
          </b-col>
        </b-form-row>

        <!-- nom -->
        <b-form-row>
          <b-col cols=12 lg=2 class="text-left text-lg-right">
            <label for="input-17" class="required col-form-label">Nom</label>
          </b-col>  
          <b-col cols=12 lg=3>
            <b-form-input id="input-17" type="text" v-model.trim="form.lastname"></b-form-input>
          </b-col>

          <!-- prénom -->
          <b-col cols=12 lg=2 class="text-left text-lg-right">
            <label for="input-18" class="required col-form-label">Prénom</label>
          </b-col>
          <b-col cols=12 lg=3>
            <b-form-input id="input-18" type="text" v-model.trim="form.firstname"></b-form-input>
          </b-col>
        </b-form-row>

        <div v-if="!is_professional_or_supplier">
          <!-- adresse 1 -->
          <!-- <b-form-row>
            <b-col cols=12 lg=2 class="text-left text-lg-right">
              <label for="input-19" class="col-form-label">Adresse L3</label>
            </b-col>
            <b-col cols=12 lg=8>
              <b-form-input id="input-19" type="text" v-model.trim="form.adresse_l3"></b-form-input>
            </b-col>
          </b-form-row> -->

          <!-- adresse 2 -->
          <b-form-row>
            <b-col cols=12 lg=2 class="text-left text-lg-right">
              <label for="input-20" class="col-form-label">Adresse</label>
            </b-col>
            <b-col cols=12 lg=8>
              <b-form-input id="input-20" type="text" v-model.trim="form.adresse_l4"></b-form-input>
            </b-col>
          </b-form-row>

          <!-- adresse 3 -->
          <!-- <b-form-row>
            <b-col cols=12 lg=2 class="text-left text-lg-right">
              <label for="input-21" class="col-form-label">Adresse L5</label>
            </b-col>  
            <b-col cols=12 lg=8>
              <b-form-input id="input-21" type="text" v-model.trim="form.adresse_l5"></b-form-input>
            </b-col>
          </b-form-row> -->

          <!-- code postal -->
          <b-form-row>
            <b-col cols=12 lg=2 class="text-left text-lg-right">
              <label for="input-22" class="required col-form-label">CP</label>
            </b-col>  
            <b-col cols=12 lg=2>
              <b-form-input id="input-22" type="number" v-model.trim="form.zip"></b-form-input>
            </b-col>

             <!-- ville -->
            <b-col cols=12 lg=1 class="text-left text-lg-right">
              <label for="input-23" class="required col-form-label">Ville</label>
            </b-col>  
            <b-col cols=12 lg=5>
              <b-form-input id="input-23" type="text" v-model.trim="form.city"></b-form-input>
            </b-col>
          </b-form-row>
        </div>

        <!-- email contact -->
        <b-form-row>
          <b-col cols=12 lg=2 class="text-left text-lg-right">
            <label for="input-24" class="required col-form-label">Email</label>
          </b-col>  
          <b-col cols=12 lg=8>
            <b-form-input id="input-24" type="text" v-model.trim="form.mail_contact"></b-form-input>
          </b-col>
        </b-form-row>

        <!-- Téléphone -->
        <b-form-row>
          <b-col cols=12 lg=2 class="text-left text-lg-right">
            <label for="input-25" class="col-form-label">Téléphone</label>
          </b-col>  
          <b-col cols=12 lg=3>
            <b-form-input id="input-25" type="text" v-model.trim="form.phone_number_1_contact"></b-form-input>
          </b-col>
          <b-col cols=12 lg=2 class="text-left text-lg-right">
            <label for="input-26" class="col-form-label">Mobile</label>
          </b-col>  
          <b-col cols=12 lg=3>
            <b-form-input id="input-26" type="text" v-model.trim="form.phone_number_2_contact"></b-form-input>
          </b-col>
        </b-form-row>

        <!-- submit -->
        <br>
        <b-form-row v-if="submit_enabled">
          <b-col cols=6 lg=3 offset-lg=3>
            <b-button block variant="danger" type="reset"><i class="fas fa-redo"></i></b-button>
          </b-col>
          <b-col cols=6 lg=3>
            <b-button block variant="success" type="submit">Créer le tiers</b-button>
          </b-col>
        </b-form-row>
        <b-form-row v-else>
          <b-col class="text-center">
            <b-spinner variant="danger"></b-spinner>
          </b-col>
        </b-form-row>
      </template>
    </b-form>
    <b-form v-if="is_professional_or_supplier && !queryflag" @submit.prevent="query(querysearch)">
      <b-form-row>
        <b-col cols=12 lg=8 offset-lg=2>
          <b-input-group label-for="input-27">
            <b-form-input id="input-27" type="text" v-model="querysearch" size="lg" placeholder="Nom, dénomination, SIRET, adresse ..."></b-form-input>
            <b-input-group-append>
              <b-button variant="outline-secondary" type="submit"><i class="fas fa-search"></i></b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-form-row>
      <b-form-row class="mt-3">
        <b-col cols=12 lg=8 offset-lg=2>
          <b-list-group>
            <b-list-group-item v-for="(result, index) in search_result" :key="index" button @click="selectQuery(result)">
              <div>{{result.uniteLegale.denominationUniteLegale.replace("*", " ").replace("/", "")}}</div> <!-- using replace du to a issue in datagouv API -->
              <div v-if="result.adresseEtablissement.complementAdresseEtablissement" class="small">{{result.adresseEtablissement.complementAdresseEtablissement}}</div>
              <div v-if="result.adresseEtablissement.libelleVoieEtablissement" class="small">{{result.adresseEtablissement.numeroVoieEtablissement+" "+result.adresseEtablissement.typeVoieEtablissement+" "+result.adresseEtablissement.libelleVoieEtablissement}}</div>
              <div v-if="result.adresseEtablissement.distributionSpecialeEtablissement" class="small">{{result.adresseEtablissement.distributionSpecialeEtablissement}}</div>
              <div class="small">{{result.adresseEtablissement.codePostalEtablissement}} {{result.adresseEtablissement.libelleCommuneEtablissement}}</div>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-form-row>
    </b-form>
    
  </div>
</template>


<script>
import conf from '../config/api.js';
    export default {
        props: {
            'with-access': Boolean,
            'from-admin': Boolean,
        },
        data() {
            return {
                re: {
                    phone_number: /^\+?(?:\d+(?:\s*\(\d+\))?)\s*\d[\s\d.-]*\d$/,
                    email: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                },
                querysearch: "",
                search_result: [],
                error: [],
                queryflag: false,
                submit_enabled: true,
                form: {
                    with_access: this.withAccess,
                    type: "Particulier",
                    siret: "",
                    siren: "",
                    forme_juridique: "",
                    denomination: "",
                    adresse_l3: "",
                    adresse_l4: "",
                    adresse_l5: "",
                    zip: "",
                    city: "",
                    country: "",
                    phone_number_entreprise: "",
                    phone_number_1_contact: "",
                    phone_number_2_contact: "",
                    fax: "",
                    naf: "",
                    tva: "",
                    mail: "",
                    mail_contact: "",
                    civility: "",
                    firstname: "",
                    lastname: "",
                    function: "",
                },
                options_form: {
                    civility: [
                    {value: "MR", text: "M."},
                    {value: "MME", text: "Mme"},
                  ],
                },
            }
        },
        watch: {
          "form.siret": function() {
              let siret = this.form.siret
              if ((siret.length==14) && (parseInt(siret) == siret) && (this.check_luhn(siret))) {
                this.axios({
                        method: 'get',
                        url: `https://api.insee.fr/entreprises/sirene/V3/siret/`,
                        headers:{Authorization: `Bearer 2676c5d2-9d90-3aa8-9dd7-d6d0f12226fd`},
                        params: {
                            q: 'siret:"'+siret+'"',
                        }
                    })
                  .then(res => {
                      let data = res.data.etablissements[0]
                      this.form.denomination = data.uniteLegale.denominationUniteLegale.replace("*", " ").replace("/", "") // using replace du to a issue with datagouv API
                      this.form.adresse_l3 = data.adresseEtablissement.complementAdresseEtablissement
                      this.form.adresse_l4 = data.adresseEtablissement.numeroVoieEtablissement+" "+data.adresseEtablissement.typeVoieEtablissement+" "+data.adresseEtablissement.libelleVoieEtablissement
                      this.form.adresse_l5 = data.adresseEtablissement.distributionSpecialeEtablissement
                      this.form.zip = data.adresseEtablissement.codePostalEtablissement
                      this.form.city = data.adresseEtablissement.libelleCommuneEtablissement
                      this.form.naf = data.uniteLegale.activitePrincipaleUniteLegale
                      this.form.siren = data.siren
                      this.form.tva = this.get_tva(this.form.siren)
                  })
                  .catch(error => {
                    console.error(error)
                      this.form.forme_juridique = ""
                      this.form.denomination = ""
                      this.form.adresse_l3 =""
                      this.form.adresse_l4 =""
                      this.form.adresse_l5 =""
                      this.form.zip = ""
                      this.form.city = ""
                      this.form.naf = ""
                      this.form.tva = ""
                  })
              }
              else {
                  this.form.forme_juridique = ""
                  this.form.denomination = ""
                  this.form.adresse_l3 =""
                  this.form.adresse_l4 =""
                  this.form.adresse_l5 =""
                  this.form.zip = ""
                  this.form.city = ""
                  this.form.naf = ""
                  this.form.tva = ""
              }
          },
          "form.lastname": function(value) {
              if (value)
                  this.form.lastname = value.toUpperCase()
          },
          "form.firstname": function(value) {
              if (value)
                  this.form.firstname = value[0].toUpperCase() + value.slice(1)
          },
          "form.adresse_l3": function(value) {
              if (value)
                  this.form.adresse_l3 = value.toUpperCase()
          },
          "form.adresse_l4": function(value) {
              if (value)
                  this.form.adresse_l4 = value.toUpperCase()
          },
          "form.adresse_l5": function(value) {
              if (value)
                  this.form.adresse_l5 = value.toUpperCase()
          },
          "form.city": function(value) {
              if (value)
                  this.form.city = value.toUpperCase()
          },
          "form.function": function(value) {
              if (value)
                  this.form.function = value.toUpperCase()
          },
        },

        computed: {
            is_professional_or_supplier() {
                return ["Professionnel", "Fournisseur"].includes(this.form.type)
            },
        },

        methods: {
            resetForm() {
                this.form.siret = ""
                this.form.siren = ""
                this.form.forme_juridique = ""
                this.form.denomination = ""
                this.form.adresse_l3 = ""
                this.form.adresse_l4 = ""
                this.form.adresse_l5 = ""
                this.form.zip = ""
                this.form.city = ""
                this.form.country = ""
                this.form.phone_number_entreprise = ""
                this.form.phone_number_1_contact = ""
                this.form.phone_number_2_contact = ""
                this.form.fax = ""
                this.form.naf = ""
                this.form.tva = ""
                this.form.mail = ""
                this.form.mail_contact = ""
                this.form.civility = ""
                this.form.firstname = ""
                this.form.lastname = ""
                this.form.function = ""
                this.queryflag = false
                this.querysearch = ""
                this.search_result = []
            },

            selectQuery(data) {
                this.form.siret = data.siret
                this.queryflag = true
            },

            query(query) {
                if ((query.length==14) && (parseInt(query)==query) && (this.check_luhn(query))) {
                    this.form.siret = query
                    this.queryflag = true
                }
                else {
                    this.axios({
                        method: 'get',
                        url: `https://api.insee.fr/entreprises/sirene/V3/siret/`,
                        headers:{Authorization: `Bearer 2676c5d2-9d90-3aa8-9dd7-d6d0f12226fd`},
                        params: {
                            q: 'siren:"'+query+'" OR denominationUniteLegale:"'+query+'" OR libelleCommuneEtablissement:"'+query+'" OR codeCommuneEtablissement:"'+query+'"',
                        }
                    })
                    .then(res => {
                        this.search_result = res.data.etablissements
                    })
                    .catch(error => {
                        this.search_result = []
                        if (error && error.response.status == 404) {
                            this.$bvToast.toast(`Aucun résultat pour "${query}".`, {
                                title: "Aucun résultat",
                                variant: "warning",
                                autoHideDelay : 2500,
                                solid: true,
                                toaster: "b-toaster-bottom-right",
                              })
                        }
                        else {
                            console.error(error)
                        }
                    })
                }
            },

            queryBySiret(siret) {
              this.axios({
                        method: 'get',
                        url: `https://api.insee.fr/entreprises/sirene/V3/siret/`,
                        headers:{Authorization: `Bearer 2676c5d2-9d90-3aa8-9dd7-d6d0f12226fd`},
                        params: {
                            q: 'siret:"'+siret+'"',
                        }
                    })
                .then(res => {
                    this.search_result = res.data.etablissements
                })
                .catch(error => {
                    this.search_result = []
                    console.error(error)
                })
            },

            get_tva(siren) {
                return "FR" + (12 + 3*(siren%97))%97 + siren
            },

            submit() {
                this.error = []

                if (this.is_professional_or_supplier) {

                    // check siret
                    let siret = this.form.siret
                    if (siret.length!=14) this.error.push("Le numéro de siret doit être composé de 14 charactères.")
                    else if ((parseInt(siret) != siret) || !(this.check_luhn(siret))) this.error.push("Le numéro de siret doit être valide.")

                    // check forme juridique
                    if (!this.form.forme_juridique) this.error.push("Veuillez saisir une forme juridique.")

                    // check dénomination
                    if (!this.form.denomination) this.error.push("Veuillez saisir une dénomination.")

                    // check email entreprise
                    if (!this.form.mail) this.error.push("Veuillez saisir une adresse Email.")
                    else if (!this.re.email.test(this.form.mail)) this.error.push("Veuillez saisir une adresse Email valide.")

                    // check Téléphone entreprise
                    if (!this.form.phone_number_entreprise) this.error.push("Veuillez saisir un numéro de téléphone pour l'entreprise.")
                    else if (!this.re.phone_number.test(this.form.phone_number_entreprise)) this.error.push("Veuillez saisir un numéro de téléphone valide pour l'entreprise.")

                    // check fax
                    if ((this.form.fax) && (!this.re.phone_number.test(this.form.fax))) this.error.push("Attention, le numéro de fax est invalide.")

                    // check NAF
                    if (!this.form.naf) this.error.push("Veuillez saisir un code NAF.")
                }

                // check civilité
                if (!this.form.civility) this.error.push("Veuillez selectionner votre civilité.")

                // check fonction
                if ((this.is_professional_or_supplier) && (!this.form.function)) this.error.push("Veuillez saisir votre fonction/poste.")

                // check nom
                if (!this.form.lastname) this.error.push("Veuillez saisir votre nom.")

                // check prénom
                if (!this.form.firstname) this.error.push("Veuillez saisir votre prénom.")

                // check adresse
                if ((!this.form.adresse_l3) && (!this.form.adresse_l4) && (!this.form.adresse_l5)) this.error.push("Veuillez replir au moins un champ d'adresse.")

                // check code postal
                if (!this.form.zip) this.error.push("Veuillez saisir un code postal.")
                else if (this.form.zip.length!=5) this.error.push("Veuillez saisir un code postal sur 5 chiffres.")

                // check ville
                if (!this.form.city) this.error.push("Veuillez saisir une ville.")

                //check email contact
                if (!this.form.mail_contact) this.error.push("Veuillez saisir votre Email.")
                else if (!this.re.email.test(this.form.mail_contact)) this.error.push("Veuillez saisir une adresse Email valide.")

                //check téléphone contact
                if ((!this.is_professional_or_supplier) && (!this.form.phone_number_1_contact) && (!this.form.phone_number_2_contact)) this.error.push("Veuillez saisir votre numéro de téléphone")
                if  ((this.form.phone_number_1_contact) && (!this.re.phone_number.test(this.form.phone_number_1_contact))) this.error.push("Veuillez saisir un numéro de téléphone valide.")
                if  ((this.form.phone_number_2_contact) && (!this.re.phone_number.test(this.form.phone_number_2_contact))) this.error.push("Veuillez saisir un numéro de mobile valide.")



                if (this.error.length) {
                    let content = this.$createElement("ul", this.error.map(x => this.$createElement("li", x))) // generates a VNode ul with li children
                    this.$bvToast.toast(content, {
                      title: this.$createElement('div', {class: "text-danger"}, "Le formulaire est mal renseigné"),
                      variant: "warning",
                      solid: true,
                      autoHideDelay: 5000,
                      toaster: "b-toaster-bottom-right",
                    })
                }
                else {
                    this.submit_enabled = false
                    this.axios.post(conf.server+"/api/create_account", {data: this.form})
                    .then(response => {
                        if (response.status == 200) {
                            this.submit_enabled = true
                            this.$emit('created', response)
                        }
                    })
                    .catch(error => {
                        if (error.response && error.response.status == 409) {
                            this.$bvToast.toast("Le numéro de SIRET est déjà enregistré chez nous.", {
                                title: "Erreur",
                                variant: "danger",
                                autoHideDelay : 2500,
                                solid: true,
                                toaster: "b-toaster-bottom-right",
                              })
                          this.submit_enabled = true
                        }
                        else {
                          console.error(error)
                        }
                    })
                }
            },

            check_luhn(n) {
                let cks = 0
                let result
                n = String(n) // n must be a string
                for (var i = 0; i < n.length; i++) {
                    result = parseInt(n[n.length-1-i])*(1+i%2)

                    cks += result > 9 ? result-9 : result
                    n[i]
                }
                return cks%10 == 0
            },
        },
    }
</script>


<style scoped>
    label.col-form-label {
        font-size: 0.8em;
    }

    label.col-form-label.required:after {
        content: " *";
        color: red;
    }
    select.custom-select {
        margin-bottom: 5px;
    }
    input.form-control {
        height: 30px;
        margin-bottom: 5px;
        font-size: 0.8em;
    }

    input.form-control-lg, div.input-group-append {
        height: 50px;
        font-size: 1.5em;
    }
</style>