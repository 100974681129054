<template>
  <div  style='padding:10px;'>
    <b-tabs pills vertical>
      <b-tab title="Accueil">
        <h3 style='margin-left:2%;text-decoration: underline;'>Accueil</h3>
        <div style=''>
            <img src='/img/help/accueil.png' style='width:70%;margin-left:15%;'/>
        </div>
        <br/>
        <h3 style='margin-left:2%;text-decoration: underline;'>Dérouler le menu</h3>
        <div style=''>
            <img src='/img/help/menu.png' style='width:30%;margin-left:35%;'/>
        </div>
      </b-tab>
      <b-tab title="Documents">
        <h3 style='margin-left:2%;text-decoration: underline;'>Icônes sur les documents</h3>
        <div style=''>
            <img src='/img/help/documents.png' style='width:70%;margin-left:15%;'/>
        </div>
        <br/>
        <h4>Icône de téléchargement</h4>
        <span class='fas fa-cloud-download-alt'></span> : Un clic sur cet icône permet de télécharger le document.
        <br/>
        <h4>Icône de visualisation</h4>
        <span class='far fa-eye'></span> : Un clic sur cet icône permet de visualiser le document directement.
        <!--<br/>
        <h4>Icône de commentaire</h4>
        <p>En cliquant sur cet icône vous pourrez éditer le commentaire.</p>
        <span class='fas fa-comment' style='color:#0084ff;'></span> : Cet icône signifie qu'un commentaire à été rédigé pour le document.
        <br/>
        <span class='fas fa-comment' style='color:black;'></span> : Cet icône signifie qu'aucun commentaire n'a été rédigé pour le document.-->
      </b-tab>
      <b-tab title="Tickets d'interventions">
        <h3 style='margin-left:2%;text-decoration: underline;'>Suivi des tickets d'interventions</h3>
        <div>
            <h4>Liste des tickets</h4>
            <p>Au chargement de la page tous les tickets sont chargés.</p>
            <div style=''>
                <img src='/img/help/suiviTicket.png' style='width:70%;margin-left:15%;'/>
            </div>
            <br/>
            <p>Vous pouvez les filtrer suivant la sévérité, le type et le statut du ticket.</p>
            <p>Au clic sur un ticket la discussion ouverte sur ce ticket s'affiche.</p>
            <div style=''>
                <img src='/img/help/suiviTicketConvers.png' style='width:30%;margin-left:35%;'/>
            </div>
            <br/>
            <p>Vous pourrez ainsi répondre au technicien afin de le tenir informer sur les différents changements de l'intervention et ajouter des pièces jointes d'une taille maximale de 6Mo si nécéssaire.</p>
            <div style=''>
                <img src='/img/help/suiviTicketReponse.png' style='width:30%;margin-left:35%;'/>
            </div> 
        </div>
        <div>
            <h4>Création d'un ticket d'intervention</h4>
            <p>Pour créer un ticket, il vous faut choisir le type de demande, remplir le sujet du message et enfin écrire votre message.</p>
            <!--<p>Il est possible d'ajouter des pièces jointes d'une taille maximale de 6Mo.</p>-->
            <div style=''>
                <img src='/img/help/creationTicket.png' style='width:70%;margin-left:15%;'/>
            </div>
        </div>
      </b-tab>
      <b-tab title="Profil">
        <h3 style='margin-left:2%;text-decoration: underline;'>Profil du client</h3>
        <div>
          <h4>Profil personnel du client</h4>
          <p>Pour accéder à votre profil client, cliquez sur votre nom en haut à gauche de la page, ensuite cliquez sur mon profil.</p> 
          <div style=''>
              <img src='/img/help/menuProfDeco.png' style='width:30%;margin-left:35%;'/>
          </div>
          <br/>
          <p>Ici vous retrouverez vos informations personnel et vous pourrez effectuer une demande de modifications.</p>
          <div style=''>
              <img src='/img/help/profilPerso.png' style='width:70%;margin-left:15%;'/>
          </div>
          <h4>Modification des informations personnel du client</h4>
          <p>Un mail avec les informations qui ont été modifiés sera envoyé au service client qui traitera votre demande.</p> 
          <div style=''>
              <img src='/img/help/modifInfoPerso.png' style='width:30%;margin-left:35%;'/>
          </div>
        </div>
        <div>
          <h4>Profil de l'entreprise</h4>
          <p>Dans cette page vous retrouverez les informations liées à votre entreprise.</p>
          <p>Pour toutes modifications concernant les informations de votre entreprise veuillez contacter le service client directement.</p>
          <div style=''>
              <img src='/img/help/profilEntreprise.png' style='width:30%;margin-left:35%;'/>
          </div>
        </div>
        <div>
          <h4>Changement de mot de passe</h4>
          <p>Vous pourrez modifier votre mot de passe en cliquant sur 'paramètres de connexion'.</p>
          <p>Votre mot de passe doit être d'une longueur minimale de 8 caractères et doit contenir au moins un majuscule, une minuscule et un chiffre.</p> 
          <div style=''>
              <img src='/img/help/changePass.png' style='width:30%;margin-left:35%;'/>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<style>

</style>

<script>
import script from '../../script/script.js';
export default {
  data() {
    return {
      post: {},
    }
  },
  mounted:function(){
    this.verifyRight();
  },
  methods: {
    verifyRight: script.verifyRight,
  },
  components:{
  }
}
</script>
